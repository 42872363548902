import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withStyles from 'isomorphic-style-loader/withStyles';
import { Modal } from 'react-bootstrap';
import cx from 'classnames';

import OrderAccept from '../OrderAccept/OrderAccept';
import { closeOrderModal } from '../../../actions/modalActions';
//image
import closeIcon from '../../../../public/Icons/closeIcon.svg'


class OrderModal extends Component {
    static propTypes = {
        closeOrderModal: PropTypes.func,
        orderModal: PropTypes.bool,
        formatMessage: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            orderModalStatus: false
        }
    }

    componentDidMount() {
        const { orderModal } = this.props;
        if (orderModal) {
            this.setState({ orderModalStatus: true })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { orderModal } = nextProps;
        if (orderModal) {
            this.setState({ orderModalStatus: true })
        } else {
            this.setState({ orderModalStatus: false })
        }
    }

    render() {
        const { closeOrderModal } = this.props;
        const { orderModalStatus } = this.state;
        return (
            <div >
                <Modal show={orderModalStatus} animation={false} onHide={closeOrderModal} className={'orderModal'}>
                    <div>
                        <Modal.Header><img src={closeIcon} onClick={closeOrderModal} className={cx('timerCloseModal', 'timerCloseModalRTL')}/></Modal.Header>
                        <Modal.Body>
                            <OrderAccept />
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapState = state => ({
    orderModal: state.modalStatus.isOrderModalOpen,
})

const mapDispatch = {
    closeOrderModal
};

export default connect(mapState, mapDispatch)(OrderModal)