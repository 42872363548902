import React from "react";
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { change } from 'redux-form';
import { Button } from "react-bootstrap";
import cx from 'classnames';

import Loader from '../Common/Loader/Loader';
import Dropzone from '../Common/Dropzone';

import TrashIcon from '../../../public/StoreIcon/dlty.png';
import defaultImage from '../../../public/defaultimage.svg';
import documentDefaultImage from '../../../public/StoreIcon/documentUpload.svg';

import s from './ImageUploadComponent.css';
import ToolTipContent from "../Common/ToolTipContent/ToolTipContent";

class ImageUploadComponent extends React.Component {

    handleDropzone = (fileName, fieldName, formName) => {
        const { change } = this.props;
        change(formName, fieldName, fileName)
    };

    render() {

        const { imageSrc, label, className, defaultMessage, loader, inputContainer, inputContainerClass, loaderName, toolTip } = this.props;
        const { postUrl, handleDropzone, fieldName, formName, isRemove, handleDelete, subTextClass, subText, isExistDefault, documentDefault } = this.props;
        return (
            <div>
                {label && <label className={cx('tooltipLabelContainer svgImg')}>{label} {toolTip && <ToolTipContent label={toolTip} />}</label>}

                {imageSrc && <Loader show={loader} type={"page"}>
                    <div className={s.backgroundImg} style={{ backgroundImage: `url(${imageSrc})` }} />
                </Loader>}

                {!imageSrc && !isExistDefault && <Loader show={loader} type={"page"}>
                    <div className={cx(s.backgroundImg, s.defaultBgImage)} style={{ backgroundImage: `url(${defaultImage})` }} />
                </Loader>}

                {
                    isRemove && imageSrc &&
                    <Button onClick={handleDelete} className={s.btnTrash}><img src={TrashIcon} alt='Delete' /> </Button>
                }

                {!imageSrc && documentDefault && <Loader show={loader} type={"page"}>
                    <div className={s.backgroundImg} style={{ backgroundImage: `url(${documentDefaultImage})` }} />
                </Loader>}

                <div>
                    <Dropzone
                        className={className}
                        subTextClass={subTextClass}
                        subText={subText}
                        defaultMessage={defaultMessage}
                        componentConfig={{
                            iconFiletypes: ['.jpg', '.png', '.svg'],
                            multiple: false,
                            showFiletypeIcon: false,
                            postUrl
                        }}
                        loaderName={loaderName}
                        fieldName={fieldName}
                        formName={formName}
                        handleDropzone={handleDropzone ? handleDropzone : this.handleDropzone}
                        inputContainer={inputContainer}
                        inputContainerClass={inputContainerClass}
                        isLoader
                    />
                </div>
            </div>
        )
    }
}


const mapState = state => ({});

const mapDispatch = {
    change
};

export default withStyles(s)(connect(mapState, mapDispatch)(ImageUploadComponent));  