import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout'
import EditUser from './EditUser';
import messages from '../../../locale/messages';
import { checkUserAvailability } from '../../../actions/siteadmin/Users/checkUserAvailability';
import { restrictUrls } from '../../../helpers/adminPrivileges';

async function action({ store, params, intl }) {
    const title = intl.formatMessage(messages.editRider);

    // From Redux Store
    let isAdminAuthenticated, adminPrivileges, privileges, response;;
    isAdminAuthenticated = store.getState().runtime?.isAdminAuthenticated;
    adminPrivileges = store.getState().account?.privileges?.privileges;
    privileges = store.getState().commonSettings?.privileges;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    // Admin restriction
    if (!restrictUrls('/siteadmin/users/', adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }

    const id = Number(params?.id);

    await store.dispatch(checkUserAvailability(id)).then(function (res) {
        response = res;
    });

    if (response == false) {
        return { redirect: '/siteadmin/users' }
    }

    return {
        title,
        component: (
            <AdminLayout>
                <EditUser title={title} id={id} />
            </AdminLayout>
        )
    }
}

export default action;
