import { initialize } from 'redux-form';
import { openSubMenuModal } from '../../../actions/siteadmin/modalActions';
import { getSubMenu as query } from '../../../lib/graphql';

const editItem = (id) => {
	return async (dispatch, getState, { client }) => {
		try {
			const { data } = await client.query({
				query,
				variables: {
					id
				},
				fetchPolicy: 'network-only'
			});

			if (data?.getSubMenu) {
				dispatch(initialize('AddSubMenuForm', data?.getSubMenu));
				dispatch(openSubMenuModal());
			} else {
				showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.getSubMenu?.errorMessage });
			}

		} catch (error) {
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
		}
	}
};

export default editItem;