import showToaster from '../../../../helpers/showToaster';
import { addPayout } from '../../../../actions/shop/payout/addPayout';

const submit = async (values, dispatch) => {

	let removeValues = ['firstname', 'lastname', 'accountNumber', 'routingNumber',
		'ssn4Digits', 'businessType', 'bankCode', 'branchCode',
		'bankName', 'branchName', 'accountOwnerName', 'transitNumber',
		'institutionNumber', 'bsbNumber', 'sortCode']

	if (!values?.isEnable) {
		showToaster({ messageId: 'shopPayoutWarn', toasterType: 'warning' })
		return;
	}

	if (values.methodId === 5) {
		values.email = values.payEmail
		removeValues.map((item) => {
			values[item] = null;
		})
	}

	dispatch(
		addPayout(
			{
				methodId: values?.methodId,
				payEmail: values?.email,
				address1: values?.address1,
				address2: values?.address2,
				city: values?.city,
				state: values?.state,
				country: values?.country,
				zipcode: values?.zipcode,
				currency: values?.currency,
				firstname: values?.firstname,
				lastname: values?.lastname,
				accountNumber: values?.accountNumber,
				routingNumber: values?.routingNumber,
				ssn4Digits: values?.ssn4Digits,
				businessType: values?.businessType,
				bankCode: values?.bankCode,
				branchCode: values?.branchCode,
				bankName: values?.bankName,
				branchName: values?.branchName,
				accountOwnerName: values?.accountOwnerName,
				transitNumber: values?.transitNumber,
				institutionNumber: values?.institutionNumber,
				bsbNumber: values?.bsbNumber,
				sortCode: values?.sortCode,
				accountToken: values?.accountToken,
				personToken: values?.personToken
			}
		)
	);
};

export default submit;
