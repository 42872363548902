import uploadShopDocument from '../../../actions/shop/documentUpload/uploadShopDocument'
import showToaster from '../../../helpers/showToaster';

async function submit(values, dispatch) {

    let documentError = false;

    values.documentList && values.documentList.map((item) => {
        item.fileName ? documentError = false : documentError = true
    })

    let documentList = values.documentList && values.documentList.length > 0
        ?
        JSON.stringify(values.documentList) : JSON.stringify([]);

    if (documentError) {
        return await showToaster({ messageId: '', toasterType: 'error' })
    } else {
        await dispatch(
            uploadShopDocument(documentList)
        )
    }

}

export default submit;