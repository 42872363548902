const europeCountries = [

    'AT', 'AE', 'BE', 'BG', 'CY', 'CZ', 'DK', 
    'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 
    'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'NO', 
    'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'CH',
];

export const isEuropeCountry = (requestCountry) => europeCountries.includes(requestCountry);



