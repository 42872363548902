import uploadDocument from '../../../../actions/siteadmin/ManageShop/uploadDocument';
import showToaster from '../../../../helpers/showToaster';

async function submit(values, dispatch) {
    let documentError = false;

    values.documentList && values.documentList.map((item) => {
        item.fileName ? documentError = false : documentError = true
    })

    let documentList = values.documentList && values.documentList.length > 0
        ?
        JSON.stringify(values.documentList) : JSON.stringify([]);

    documentError ? showToaster({ messageId: 'uploadDocError', toasterType: 'error' })
                     : await dispatch(uploadDocument(documentList))

}

export default submit;