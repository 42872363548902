import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Slider from 'react-slick';
import s from './StoreSlider.css';
import {
	Button, Row, Col, Container
} from 'react-bootstrap';
import cx from 'classnames';

//Styles
import prevIcon from '../../../../../public/Icons/leftArrow.svg';
import nextIcon from '../../../../../public/Icons/rightArrow.svg';

//Locale
import { api, partnerHomepageUploadDir } from '../../../../config'


class StoreSlider extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showSlider: true,
			updateCount: 1
		};
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.renderSliderItem = this.renderSliderItem.bind(this);
	}


	next() {
		const { updateCount } = this.state;
		let currentValue = updateCount;
		currentValue = Number(updateCount) + 1
		this.setState({
			updateCount: currentValue
		});
		if (updateCount >= 4) {
			this.setState({
				showSlider: !this.state.showSlider,
				updateCount: 1
			})
		}
		this.slider.slickNext();
	}

	previous() {
		const { updateCount } = this.state;
		let currentValue = updateCount;
		currentValue = Number(updateCount) - 1
		this.setState({
			updateCount: currentValue
		});
		if (updateCount <= 1) {
			this.setState({
				updateCount: 1
			})
		}
		this.slider.slickPrev();
	}

	renderSliderItem(data, index) {
		return (
			<div className={cx(s.displayGrid, 'reverseRTL')}>
				<div className={cx(s.svgImage, 'columnOneRTL')}>
					<div style={{backgroundImage:`url(${api.apiEndpoint + partnerHomepageUploadDir + data['sliderImage' + index]})`}} className={s.bgImage}></div>
				</div>
				<div className={cx(s.content, 'contentAnimation', 'columnTwoRTL')}>
					<h2>{data['sliderTitle' + index]}</h2>
					<p>{data['sliderContent' + index]}</p>
				</div>
			</div>
		);
	}

	render() {

		const { homepage } = this.props;

		const settings = {
			dots: false,
			infinite: true,
			fade: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			initialSlide: 0,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: false,
						dots: false
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						initialSlide: 0,
						dots: false
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: false
					}
				}
			]
		};

		let sliders = [1, 2, 3];

		return (
			<div className={cx(s.root)}>
				<Container fluid>
					<Row>
						<Col lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
							<div className={cx(s.minHeight, 'listYourPlace')}>
								<Slider ref={c => (this.slider = c)} {...settings} className={s.tabPadding}>
									{
										sliders && sliders.map((item, key) => {
											return (<div key={key}>{this.renderSliderItem(homepage, item)}</div>);
										})
									}
								</Slider>
								<div className={cx(s.buttonPosition, 'storeButtonRTL')}>
									<div className={s.buttonrelative}>
										<Button className={cx(s.listPrevArrow, 'storeArrowRTL')} onClick={() => this.previous()}>
											<img src={prevIcon} alt="preview" />
										</Button>
										<Button className={cx(s.listNextArrow, 'storeArrowRTL')} onClick={() => this.next()}>
											<img src={nextIcon} alt="next" />
										</Button>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withStyles(s)(StoreSlider);