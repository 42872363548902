import React, { Component } from 'react';
import DocumentForm from '../../../../components/SiteAdmin/ManageShop/DocumentForm';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getDocuments from './getDocuments.graphql';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Document.css';
import Loader from '../../../../components/Common/Loader';


export class Document extends Component {

    static defaultProps = {
        data: {
            loading: false
        }
    }
    render() {
        const { getDocuments: { loading, getDocuments } } = this.props;

        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.paddingRoutesSection}>
                            {!loading && <DocumentForm initialValues={getDocuments} />}
                        </div>
                    </div>
                </div>
            </Loader>
        )
    }
};

export default compose(withStyles(s),
    graphql(getDocuments, {
        name: 'getDocuments',
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only',
            ssr: false
        })
    })
)(Document)