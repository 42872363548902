import showToaster from '../../../helpers/showToaster';
import { closeDeclineReasonModal } from '../../siteadmin/modalActions';
import { getPendingOrders } from './getPendingOrders';
import { updateOrderStatus as mutation, getOrders } from '../../../lib/graphql';

export default function updateOrderStatus(id, bookingStatus, currentPage, reason, isOrderDetailPage, orderModal) {
    return async (dispatch, getState, { client }) => {
        try {
            let refetchQueries = [];

            if (isOrderDetailPage) {
                refetchQueries = [{
                    query: getOrders,
                    variables: {
                        id
                    }
                }];
            }

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    bookingStatus,
                    notes: reason,
                    orderModal
                },
                refetchQueries
            });

            if (orderModal) {
                await dispatch(getPendingOrders());
            }

            if (data?.updateOrderStatus?.status === 200) {
                showToaster({ messageId: 'updateOrder', toasterType: 'success' });
                dispatch(closeDeclineReasonModal());
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateOrderStatus?.errorMessage });
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
        }
    }
};