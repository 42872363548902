import { change } from 'redux-form';
import showToaster from '../../helpers/showToaster';
import { updateSiteSettingsMutation, updateTone } from '../../lib/graphql';
import { removeImage as removeTone } from '../../helpers/removeImage';
import {
    UPDATE_SITE_SETTINGS_START,
    UPDATE_SITE_SETTINGS_ERROR,
    UPDATE_SITE_SETTINGS_SUCCESS,
    TONE_UPLOAD_ERROR,
    TONE_UPLOAD_START,
    TONE_UPLOAD_SUCCESS
} from '../../constants/index';

export default function updateSiteSettings({
    siteName,
    siteTitle,
    metaDescription,
    facebookLink,
    twitterLink,
    instagramLink,
    metaKeyword,
    homeLogo,
    youtubeLink,
    currency,
    distanceUnits,
    appForceUpdate,
    userAndroidVersion,
    userIosVersion,
    driverAndroidVersion,
    driverIosVersion,
    preferredDelivery,
    modifiers,
    favicon,
    contactNumber,
    contactEmail,
    contactSkype,
    notificationInterval,
    orderAcceptInterval,
    requestTimeTone,
    requestToneFile,
    ogImage
}) {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: UPDATE_SITE_SETTINGS_START
            });

            const { data } = await client.mutate({
                mutation: updateSiteSettingsMutation,
                variables: {
                    siteName,
                    siteTitle,
                    metaDescription,
                    facebookLink,
                    twitterLink,
                    instagramLink,
                    metaKeyword,
                    homeLogo,
                    youtubeLink,
                    currency,
                    distanceUnits,
                    appForceUpdate,
                    userAndroidVersion,
                    userIosVersion,
                    driverAndroidVersion,
                    driverIosVersion,
                    preferredDelivery,
                    modifiers,
                    favicon,
                    contactNumber,
                    contactEmail,
                    contactSkype,
                    notificationInterval,
                    orderAcceptInterval,
                    requestTimeTone,
                    requestToneFile,
                    ogImage
                }
            });

            if (data?.updateSiteSettings?.status == 200) {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_SUCCESS
                });
                showToaster({ messageId: 'updateSiteSettings', toasterType: 'success' });
            } else {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_ERROR
                });
                showToaster({ messageId: 'commonError', toasterType: 'error' });
            }

        } catch (err) {
            dispatch({
                type: UPDATE_SITE_SETTINGS_ERROR
            });
            showToaster({ messageId: 'commonError', toasterType: 'error' });
        }
    }
};


export function deleteTone(fileName) {
    return async (dispatch, getState, { client }) => {

        try {
            let status;

            dispatch({
                type: TONE_UPLOAD_START
            })

            dispatch(change("SiteSettingsForm", "requestTimeTone", null));

            const url = "/deleteTone";

            const response = await removeTone({ url, fileName });

            status = response;

            if (status == 200) {
                await client.mutate({
                    mutation: updateTone
                });
                dispatch({
                    type: TONE_UPLOAD_SUCCESS
                });
            } else {
                dispatch({
                    type: TONE_UPLOAD_ERROR
                });
            }

        } catch (err) {
            dispatch({
                type: TONE_UPLOAD_ERROR
            });
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err });
            return true;
        }
    }
};