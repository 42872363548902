import history from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { updateModifierMutation } from '../../../lib/graphql';
import { getAllShopModifier } from './getAllShopModifier';
import {
	SHOP_MODIFIER_UPDATE_START,
	SHOP_MODIFIER_UPDATE_SUCCESS,
	SHOP_MODIFIER_UPDATE_ERROR
} from '../../../constants/index';

function addModifier(values) {
	return async (dispatch, getState, { client }) => {

		try {
			let successMessage;

			dispatch({
				type: SHOP_MODIFIER_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});

			successMessage = values?.id ? 'updateModifier' : 'createModifier';

			const { data: { updateModifierGroup } } = await client.mutate({
				mutation: updateModifierMutation,
				variables: values
			});

			if (updateModifierGroup?.status == 200) {
				dispatch({
					type: SHOP_MODIFIER_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: successMessage, toasterType: 'success' });
				history.push('/store/menu/modifier-groups');
			} else {
				dispatch({
					type: SHOP_MODIFIER_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: updateModifierGroup?.errorMessage });
			}

			dispatch(getAllShopModifier());

		} catch (error) {
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
			dispatch({
				type: SHOP_MODIFIER_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};

export default addModifier;