import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import { api } from '../../../config';
import s from './DriverMobileApp.css';

class DriverMobileApp extends React.Component {

	render() {

		const { data } = this.props;
		let homeSettings = {};

		data?.homePageData?.map((item) => {
			homeSettings[item.name] = item?.value
		});

		return (
			<div className={s.driverAppbg}>
				<Container className={cx(s.bannerLayoutContainer)} >
					<Row className={s.displayFlex}>
						<Col xl={6} md={6} sm={12} xs={12}>
							<div className={cx(s.signUp, s.space5)}>
								<h3>
									<span className={s.siteColor}>{homeSettings?.safetyGridTitle2}</span>
								</h3>
								<p>
									{homeSettings?.safetyGridContent2}
								</p>
							</div>
							<div className={cx(s.spaceTop4)}>
								<div className={cx(s.displayInlineBlock, s.paddingRight, 'paddingRightRTL')}>
									<a href={homeSettings?.safetyGridLink3} target="_blank" className={s.btn}>
										<img src={api.apiEndpoint + "/images/homepage/" + homeSettings?.safetyGridImage4} className={cx(s.appImg, 'appImgRTL')} />
										<span><FormattedMessage {...messages.androidLabel} /></span>
									</a>
								</div>
								<div className={cx(s.displayInlineBlock, s.paddingLeft, 'noPaddingMbRTL')}>
									<a href={homeSettings?.safetyGridLink4} target="_blank" className={s.btn}>
										<img src={api.apiEndpoint + "/images/homepage/" + homeSettings?.safetyGridImage5} className={cx(s.appImg, 'appImgRTL')} />
										<span><FormattedMessage {...messages.iphoneLabel} /></span>
									</a>
								</div>
							</div>
						</Col>
						<Col xl={6} md={6} sm={12} xs={12} className={cx(s.spaceTop6)}>
							<div className={s.signUp}>
								<div className={s.phoneImgSection}>
									<img src={api.apiEndpoint + "/images/homepage/" + homeSettings?.safetyGridImage6} className={s.phoneImg} />
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withStyles(s)(DriverMobileApp);