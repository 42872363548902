import { getPayouts as query } from '../../../lib/graphql';
import {
    GET_PAYOUT_START,
    GET_PAYOUT_SUCCESS,
    GET_PAYOUT_ERROR,
} from '../../../constants';

export const getPayouts = (currentAccountId, country) => {
    return async (dispatch, getState, { client }) => {

        try {
            await dispatch({
                type: GET_PAYOUT_START,
                payload: {
                    getPayoutLoading: true
                }
            });

            const { data } = await client.query({
                query,
                variables: {
                    currentAccountId,
                    country
                },
                fetchPolicy: 'network-only'
            });

            if ( data?.getPayouts?.status == 200 && data?.getPayouts?.results?.length >= 0) {
                await dispatch({
                    type: GET_PAYOUT_SUCCESS,
                    payload: {
                        payoutData: data?.getPayouts?.results,
                        getPayoutLoading: false
                    }
                });
            } else {
                await dispatch({
                    type: GET_PAYOUT_ERROR,
                    payload: {
                        error: 'No records found.',
                        getPayoutLoading: false
                    }
                });
            }

        } catch (error) {
            await dispatch({
                type: GET_PAYOUT_ERROR,
                payload: {
                    error,
                    getPayoutLoading: false
                }
            });
            return false;
        }
        return true;
    };
};
