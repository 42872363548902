import React, { Component } from 'react'
import { Field, reduxForm, getFormValues, change, formValueSelector, initialize } from 'redux-form';
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Link from '../../../Link';
import Loader from '../../../Common/Loader';
import ImageUploadComponent from '../../../ImageUploadComponent/ImageUploadComponent';

import messages from '../../../../locale/messages';
import validate from './validate';
import submit from './submit'
import { api, categoryUploadDir } from '../../../../config';

import s from './AddCategoryForm.css';

export class AddCategoryForm extends Component {
	static defaultProps = {
		loading: false
	};

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<div>
				<Form.Group className={s.space3}>
					<div>
						<label className={s.labelText} >{label}</label>
					</div>
					<div>
						<Form.Control
							{...input}
							className={className}
							placeholder={label}
							as="textarea"
							rows="4"
						>
							{children}
						</Form.Control>
						{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
					</div>
				</Form.Group>
			</div>
		);
	}

	render() {
		const { handleSubmit, categoryImage, loading, submitting, categoryImageLoader } = this.props;
		const { formatMessage } = this.props.intl;


		return (
			<div className={cx(s.space5, s.spaceTop5)}>
				<Container fluid>
					<Row>
						<Col lg={6} md={12} sm={12} xs={12} className={s.blackCenter}>
							<div className={s.blackCenterSection}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)} >
									<Col lg={12} md={12} sm={6} xs={12}>
										<Form.Group className={s.space3}>
											<div className={cx(s.centerFlex, s.profileImgSection)}>
												<div>
													<ImageUploadComponent
														className={cx(s.btnSecondary, s.profileNoPadding)}
														subTextClass={s.subText}
														subText={formatMessage(messages.maximumUploadSizeLabel)}
														defaultMessage={formatMessage(messages.chooseFile)}
														loaderName={'categoryImageLoader'}
														postUrl={api.apiEndpoint + '/uploadCategoryImage'}
														loader={categoryImageLoader}
														fieldName={'categoryImage'}
														imageSrc={categoryImage ? api.apiEndpoint + categoryUploadDir + 'medium_' + categoryImage : null}
														label={formatMessage(messages.categoryIcon)}
														inputContainer={'.dzInputContainer'}
														inputContainerClass={'dzInputContainer'}
														formName={'AddCategoryForm'}
													/>
												</div>
											</div>
										</Form.Group>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Form.Group className={s.space3}>
											<div>
												<Field
													name="categoryName"
													type="text"
													placeholder={formatMessage(messages.categoryName)}
													component={this.renderField}
													label={formatMessage(messages.categoryName)}
													labelClass={s.labelText}
													fieldClass={s.formControlInput}
												/>
											</div>
										</Form.Group>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Form.Group className={s.space3}>
											<div>
												<label>{formatMessage(messages.status)}</label><br />
												<Field name="isActive" className={cx(s.formControlSelect, s.formControlInput)} component="select">
													<option value={true}>{formatMessage(messages.active)}</option>
													<option value={false}>{formatMessage(messages.inactive)}</option>
												</Field>
											</div>
										</Form.Group>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Field name="description" component={this.renderFormControlTextArea} label={formatMessage(messages.description)} />
									</Col>
									<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textLeftRTL', 'borderBottomRTL')}>
										<Form.Group className={s.noMargin}>
											<div className={s.displayInlineBlock}>
												<Loader
													type={"button"}
													label={formatMessage(messages.submitButton)}
													show={loading}
													buttonType={'submit'}
													className={cx(s.button, s.btnPrimary)}
													disabled={submitting || loading}
													isSuffix={true}
												/>
											</div>
											<Link to={"/siteadmin/category"} className={cx(s.backBtn, s.btnSecondary, s.linkBtnInline, 'backBtnRTL')} >{formatMessage(messages.goBack)}</Link>
										</Form.Group>
									</Col>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

AddCategoryForm = reduxForm({
	form: 'AddCategoryForm',
	onSubmit: submit,
	validate
})(AddCategoryForm);

const selector = formValueSelector('AddCategoryForm');

const mapState = (state) => ({
	categoryImage: selector(state, 'categoryImage'),
	loading: state.loader.AddCategory,
	categoryImageLoader: state.loader.categoryImageLoader
})

const mapDispatch = {
	initialize
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AddCategoryForm)));