import showToaster from '../../../helpers/showToaster';
import { updateTempImages as mutation } from '../../../lib/graphql';

export const updateTempImages = (tableName, fieldName, fileName) => {
  return async (dispatch, getState, { client }) => {
    try {
      const { data } = await client.mutate({
        mutation,
        variables: {
          tableName,
          fieldName,
          fileName
        }
      });

      const result = data?.updateTempImages?.status;

      showToaster({
        messageId: result == 200 ? 'updateTempImages' : 'commonError',
        toasterType: result == 200 ? 'success' : 'error',
        requestContent: result == 200 ? fileName : null
      });

    } catch (error) {
      showToaster({ messageId: 'commonError', toasterType: 'error', requestMessage: error });
    }
  }
};