import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import messages from '../../../locale/messages';
import { change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Loader from '../../Common/Loader';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import validate from './validate';
import submit from './submit';
import { api, storeDocumentUploadDir } from '../../../config';

//Images
import closeIcon from '../../../../public/StoreIcon/closeIconRes.png';
import NoLogo from '../../../../public/StoreIcon/documentUpload.svg';

import s from './DocumentForm.css';
import rs from '../../storeCommon.css';
class DocumentForm extends React.Component {

	constructor(props) {
		super(props);

		this.handleDropzone = this.handleDropzone.bind(this);
		this.loaderStart = this.loaderStart.bind(this);
		this.loaderEnd = this.loaderEnd.bind(this);
	}

	static defaultProps = {
		loading: false
	}

	async loaderStart(index) {
		const { change } = this.props;
		await change(`documentList[${index}].loading`, true);
	}

	async loaderEnd(index) {
		const { change } = this.props;
		await change(`documentList[${index}].loading`, false);
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group>
				<Form.Label className='textRightRTL'>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	renderDocument = ({ fields, meta: { touched, error }, imageLoader }) => {
		const { documentList } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<div>
				{fields && fields.map((document, index) => {
					let documentName = fields.get(index) && fields.get(index).fileName
					let fieldLength = fields.length - 1;
					return (
						<div className={cx(s.displayInlineBock, 'floatRightRTL')}>
							<div>
								<Col lg={12} md={12} sm={12} xs={12} className={rs.space2}>
									<Form.Group className={s.formGroup}>
										<div className={cx(rs.profileImgSection, rs.profileImgWidth, 'textRightRTL')}>
											<div className={'retaurantDroupZone'}>
												<ImageUploadComponent
													className={cx(rs.profileButton)}
													subTextClass={s.subText}
													subText={formatMessage(messages.maximumUploadSizeLabel)}
													defaultMessage={formatMessage(messages.chooseFile)}
													inputContainer={'.dzInputContainerStoreDocument'}
													inputContainerClass={'dzInputContainerStoreDocument'}
													postUrl={api.apiEndpoint + '/uploadStoreDocument'}
													imageSrc={documentName ? api.apiEndpoint + storeDocumentUploadDir + documentName : null}
													fieldName={`${document}.fileName`}
													loaderName={`DropZoneLoader${index}`}
													loader={imageLoader[`DropZoneLoader${index}`]}
													label={formatMessage(messages.documentImage)}
													formName={'DocumentForm'}
													documentDefault={true}
													isExistDefault={true}
												/>
											</div>
										</div>
									</Form.Group>
								</Col>
								<Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
									<Form.Group className={s.formGroup}>
										<div className='textRightRTL'>
											<Field
												name={`${document}.fileType`}
												type="text"
												placeholder={formatMessage(messages.documentName)}
												component={this.renderField}
												fieldClass={rs.formControlInputStore}
												label={formatMessage(messages.documentName)}
											/>
										</div>
									</Form.Group>
								</Col>
								{index != 0 && <div className={s.removeSection}>
									<Button
										variant="primary"
										onClick={() => fields.remove(index)}
										className={s.removeBtnSection}
									>
										<img src={closeIcon} />
									</Button>
								</div>}
								{fieldLength == index && <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.space2, rs.alignRightText, 'textLeftRTL')}>
									<div>
										<Button
											variant="primary"
											className={cx(rs.button, rs.btnPrimaryBorder)}
											onClick={() => fields.push({ fileType: '', fileName: '' })}
										>
											{formatMessage(messages.add)}
										</Button>
									</div>
								</Col>}
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	componentWillMount() {
		const { array, initialValues } = this.props

		initialValues.documentList && initialValues.documentList.length > 0 ? '' : array.push('documentList', {})
	}

	handleDropzone(fileName, fieldName) {
		const { change } = this.props
		change(fieldName, fileName)
	}


	render() {
		const { loading, handleSubmit, submitting, imageLoader } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<div className={'mainContainer'}>
				<div>
					<Container fluid className={s.container}>
						<Row>
							<Col md={12} lg={12} sm={12} xs={12}>
								<div>
									<h1 className={cx(rs.storeTitleText, 'textRightRTL')}>
										<FormattedMessage {...messages.documentUpload} />
									</h1>
								</div>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<Row>
										<Col lg={12} md={12} sm={12} xs={12} className={rs.noPadding}>
											<FieldArray
												name="documentList"
												rerenderOnEveryChange={true}
												component={this.renderDocument}
												imageLoader={imageLoader}
											/>
										</Col>
										<Col lg={12} md={12} sm={12} xs={12}>
											<Form.Group className={s.formGroup}>
												<div className={cx(rs.alignRightText, 'textLeftRTL')}>
													<Loader
														type={"button"}
														label={formatMessage(messages.update)}
														show={loading}
														buttonType={'submit'}
														className={cx(rs.button, rs.btnPrimary)}
														disabled={submitting || loading}
														isSuffix={true}
													/>
												</div>
											</Form.Group>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		)
	}
}

DocumentForm = reduxForm({
	form: 'DocumentForm',
	onSubmit: submit,
	validate,
	enableReinitialize: true
})(DocumentForm);

const selector = formValueSelector('DocumentForm');

const mapState = (state) => ({
	documentList: selector(state, 'documentList'),
	imageLoader: state.loader
});

const mapDispatch = {
	change
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(DocumentForm)));