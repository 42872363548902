import gql from "graphql-tag";

export const updateDeliveryVehicle = gql`
mutation(
  $id: Int, 
  $vehicleName: String,
  $description: String,
  $isActive: Boolean,
  $vehicleImage: String
) {
  updateDeliveryVehicle(
      id: $id,
      vehicleName: $vehicleName,
      description: $description,
      isActive: $isActive,
      vehicleImage: $vehicleImage
  ){
      status
      errorMessage
  }
}
`;

export const deleteDeliveryVehicle = gql`
mutation($id: Int) {
    deleteDeliveryVehicle(id: $id) {
        status
        errorMessage
    }
}
`;

export const updateDeliveryVehicleImage = gql`
mutation($id: Int, $fileName: String) {
  updateDeliveryVehicleImage(id: $id, fileName: $fileName) {
      status
  }
}`;

export const updateDeliveryVehicleStatus = gql`
mutation($id: Int, $isActive: Boolean) {
    updateDeliveryVehicleStatus(id: $id, isActive: $isActive) {
        status
        errorMessage
    }
}
`;