import showToaster from '../../../helpers/showToaster';
import { deleteDeliveryVehicle as mutation } from '../../../lib/graphql';
import { removeImage } from '../../../helpers/removeImage';
import {
    DELETE_DELIVERY_VEHICLE_SUCCESS,
    DELETE_DELIVERY_VEHICLE_START,
    DELETE_DELIVERY_VEHICLE_ERROR
} from '../../../constants';

const deleteDeliveryVehicle = (id, fileName) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: DELETE_DELIVERY_VEHICLE_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                }
            });

            if (data?.deleteDeliveryVehicle?.status === 200) {
                dispatch({
                    type: DELETE_DELIVERY_VEHICLE_SUCCESS
                });
                showToaster({ messageId: 'deleteDeliveryVehicle', toasterType: 'success' });

                let url = "/deleteDeliveryVehicleImage";

                if (fileName) {
                    removeImage({ url, fileName });
                }

                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: DELETE_DELIVERY_VEHICLE_ERROR
                });
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.deleteDeliveryVehicle?.errorMessage });
                return {
                    status: 400
                };
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
            dispatch({
                type: DELETE_DELIVERY_VEHICLE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};

export default deleteDeliveryVehicle;
