import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AddCategory.css';
import AddCategoryForm from '../../../components/SiteAdmin/Category/AddCategoryForm';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class AddCategory extends Component {

    render() {
        const { title } = this.props;
        return (
            <div className={s.root}>
                <div className={s.container}>
                    <div className={s.heading}>
                    <FormattedMessage {...messages.addCategoryHeading} />
                    </div>
                    <div>
                        <AddCategoryForm initialValues={{ isActive: 'true' }} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default compose(withStyles(s),connect(mapStateToProps, mapDispatchToProps))(AddCategory)
