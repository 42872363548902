import messages from '../../../locale/messages';
import { inputTextLimit, inputDescLimit } from '../../../helpers/fieldRestriction';

const validate = values => {
    const errors = {};

    if (!values.citySectionTitle1) {
        errors.citySectionTitle1 = messages.required;
    } else if (values.citySectionTitle1.trim() == "") {
        errors.citySectionTitle1 = messages.required;
    } else if (values.citySectionTitle1.length < 2) {
        errors.citySectionTitle1 = messages.minExceedLimit
    } else if (values.citySectionTitle1.length > inputTextLimit) {
        errors.citySectionTitle1 = messages.textAreaError
    }

    if (!values.citySectionContent1) {
        errors.citySectionContent1 = messages.required;
    } else if (values.citySectionContent1.trim() == "") {
        errors.citySectionContent1 = messages.required;
    } else if (values.citySectionContent1.length < 2) {
        errors.citySectionContent1 = messages.minExceedLimit
    } else if (values.citySectionContent1.length > inputDescLimit) {
        errors.citySectionContent1 = messages.textAreaError1
    }



    return errors;
};

export default validate;