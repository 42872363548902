import showToaster from '../../../helpers/showToaster';
import { itemAvailable as query } from '../../../lib/graphql';

const itemAvailable = () => {
    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.query({
                query,
                fetchPolicy: 'network-only'
            });

            if (data?.itemAvailable?.status === 200) {
                return {
                    action: 'success'
                };
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.itemAvailable?.errorMessage })
                return {
                    action: 'failed'
                };
            }
        } catch (err) {
            return {
                action: 'failed'
            };
        }
    }
};

export default itemAvailable;