import showToaster from '../../../helpers/showToaster';
import { deleteUser as mutation } from '../../../lib/graphql';
import {
    USER_DELETE_SUCCESS,
    USER_DELETE_START,
    USER_DELETE_ERROR
} from '../../../constants/index';

const deleteUser = (id, userType) => {
    return async (dispatch, getState, { client }) => {

        try {
            let requestContent;

            dispatch({
                type: USER_DELETE_START,
                payload: {
                    deleteLoading: true
                }
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                }
            });

            if (data?.deleteUser?.status === 200) {
                dispatch({
                    type: USER_DELETE_SUCCESS,
                    payload: {
                        deleteLoading: false
                    }
                });
                requestContent = userType === 2 ? 'The driver' : 'The user';
                showToaster({ messageId: 'deleteUser', toasterType: 'error', requestContent });
                return {
                    status: data?.deleteUser?.status
                };
            } else {
                dispatch({
                    type: USER_DELETE_ERROR,
                    payload: {
                        deleteLoading: false
                    }
                });
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.deleteUser?.errorMessage });
                return {
                    status: 400
                };
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
            dispatch({
                type: USER_DELETE_ERROR,
                payload: {
                    deleteLoading: false
                }
            });
            return {
                status: 400
            };
        }
    }
};

export default deleteUser;