import messages from '../../../../locale/messages';
import { inputTextLimit, inputDescLimit } from '../../../../helpers/fieldRestriction';

const validate = values => {
    const errors = {};

    if (!values.vehicleName) {
        errors.vehicleName = messages.required;
    } else if (values.vehicleName && values.vehicleName.trim().toString() == "") {
        errors.vehicleName = messages.required;
    } else if (values.vehicleName.length > inputTextLimit) {
        errors.vehicleName = messages.textAreaError
    }

    if (!values.description) {
        errors.description = messages.required;
    } else if (values.description && values.description.trim().toString() == "") {
        errors.description = messages.required;
    } else if (values.description.length > inputDescLimit) {
        errors.description = messages.textAreaError1
    }

    return errors;
};

export default validate;