import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import DropzoneComponent from 'react-dropzone-component';
import s from '!isomorphic-style-loader!css-loader!./filepicker.css';
import cx from 'classnames';
import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';

export class Dropzone extends Component {

    constructor(props) {
        super(props)
        this.dropzone = null;
    }

    componentDidUpdate() {
        const isBrowser = typeof window !== 'undefined';
        const isDocument = typeof document !== undefined;
        if (isBrowser && isDocument) {
            document.querySelector(".dz-hidden-input").style.visibility = 'visible';
            document.querySelector(".dz-hidden-input").style.opacity = '0';
            document.querySelector(".dz-hidden-input").style.cursor = 'pointer';
        }
    }

    success = async (file, fromServer) => {
        const { handleDropzone, fieldName, isLoader, setLoaderComplete, loaderName, formName } = this.props;
        const fileName = fromServer.fileName;
        handleDropzone(fileName, fieldName, formName);
        if (isLoader) setLoaderComplete(loaderName);
        this.dropzone.removeFile(file);
    }

    error = (file) => {
        const { isLoader, setLoaderComplete, loaderName } = this.props;
        if (isLoader) setLoaderComplete(loaderName);
        this.dropzone.removeFile(file);
    }

    addedfile = async (file, fromServer) => {
        const { maxUploadSize, setLoaderStart, isLoader, setLoaderComplete, loaderName, fieldName } = this.props;
        let fileTypes = fieldName === 'favicon' ? ['image/png'] : ['image/jpeg', 'image/png', 'image/jpg'];
        let isOnline = typeof window !== 'undefined' && window.navigator.onLine;

        let fileTypeError = fieldName === 'favicon' ? 'PNG' : 'PNG, JPG & JPEG';

        if (isLoader) setLoaderStart(loaderName);

        if(!isOnline) {
			showToaster({ messageId: 'networkFailed', toasterType: 'error' })
			this.dropzone.removeFile(file);
            if (isLoader) setLoaderComplete(loaderName);
			return;
		}

        if (file && file.size > (1024 * 1024 * parseInt(maxUploadSize))) {
            showToaster({ messageId: 'limitError', toasterType: 'error' })
            this.dropzone.removeFile(file);
            if (isLoader) setLoaderComplete(loaderName);
            return;
        }

        if (file && !fileTypes.includes(file.type)) {
            this.dropzone.removeFile(file);
            showToaster({ messageId: 'fileTypeError', toasterType: 'error', requestContent: fileTypeError })
            if (isLoader) setLoaderComplete(loaderName);
            return;
        }
    }

    render() {
        const { defaultMessage, className, subTextClass, subText, inputContainer, inputContainerClass, maxUploadSize, componentConfig, fieldName } = this.props;

        let acceptedFiles = fieldName === 'favicon' ? 'image/png' : 'image/jpeg, image/png, image/jpg';

        const djsConfig = {
            dictDefaultMessage: '',
            addRemoveLinks: false,
            uploadMultiple: false,
            maxFilesize: parseInt(maxUploadSize),
            acceptedFiles,
            dictMaxFilesExceeded: 'Remove the existing image and try upload again',
            previewsContainer: false,
            hiddenInputContainer: inputContainer,
            timeout: 300000,
            // maxFiles: 1
        };

        const eventHandlers = {
            init: dz => this.dropzone = dz,
            success: this.success,
            addedfile: this.addedfile,
            error: this.error
        };


        return (
            <div className={cx('listPhotoContainer')}>
                <div className={cx(inputContainerClass)}>
                    <div className={className}>
                        <DropzoneComponent
                            config={componentConfig}
                            eventHandlers={eventHandlers}
                            djsConfig={djsConfig}
                        >
                            {defaultMessage}
                        </DropzoneComponent>
                    </div>
                </div>
                <p className={cx(subTextClass, 'droupText')}>
                    {subText}: {maxUploadSize}MB
                </p>
            </div>

        )
    }
}

const mapState = state => ({
    maxUploadSize: state?.siteSettings?.data?.maxUploadSize,
});

const mapDispatch = {
    setLoaderStart,
    setLoaderComplete
};

export default withStyles(s)(connect(mapState, mapDispatch)(Dropzone));
