import showToaster from '../../../../helpers/showToaster';
import { addUpdateDeliveryVehicle } from '../../../../actions/siteadmin/DeliveryVehicle/addUpdateDeliveryVehicle';

async function submit(values, dispatch) {
    if (!values?.vehicleImage) {
        showToaster({ messageId: 'vehicleImageError', toasterType: 'error' })
        return;
    }

    await dispatch(
        addUpdateDeliveryVehicle(
            {
                id: values?.id,
                vehicleName: values?.vehicleName,
                vehicleImage: values?.vehicleImage,
                description: values?.description,
                isActive: values?.isActive
            }
        )
    );
}

export default submit;