import showToaster from '../../../helpers/showToaster';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { updateCancelReason as mutation } from '../../../lib/graphql';
import {
    ADD_CANCEL_REASON_START,
    ADD_CANCEL_REASON_SUCCESS,
    ADD_CANCEL_REASON_ERROR
} from '../../../constants/index';

export const addCancelReason = (values) => {
    return async (dispatch, getState, { client }) => {

        try {
            let status, requestContent;

            dispatch({
                type: ADD_CANCEL_REASON_START
            })

            dispatch(setLoaderStart('AddCancellation'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id: values?.id,
                    reason: values?.reason,
                    userType: values?.userType,
                    isActive: values?.isActive == '0' ? 0 : 1
                }
            })

            status = data?.updateCancelReason?.status;

            if (status && status == '200') {
                history.push('/siteadmin/cancel-reasons');

                requestContent = values?.id ? 'updated' : 'added';
                showToaster({ messageId: 'addCancelReason', toasterType: 'success', requestContent });

                dispatch(setLoaderComplete('AddCancellation'));
                await dispatch({
                    type: ADD_CANCEL_REASON_SUCCESS,
                });
            } else {
                dispatch(setLoaderComplete('AddCancellation'));
                showToaster({ messageId: 'commonError', toasterType: 'error' });
                await dispatch({
                    type: ADD_CANCEL_REASON_ERROR,
                });
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
            dispatch({ type: ADD_CANCEL_REASON_ERROR });
        }
    }
};