import gql from "graphql-tag";

export const getAllSubMenu = gql`
query($currentPage: Int, $searchList: String, $menuId: Int) {
	getAllSubMenu(currentPage: $currentPage, searchList: $searchList, menuId: $menuId){
	  status
	  errorMessage
	  menuName
	  results {
		id
		shopId
		menuId
		name
		isEnable
		item {
			id
			subMenuId
			menuId
			itemName
			itemDescription
			price
			tax
			image
			itemType
			isEnable
		}
	  }
	}  
  }
`;

export const deleteItem = gql`
mutation deleteItem($id: Int) {
    deleteItem(id: $id) {
      status
      errorMessage
    }
  }
`;

export const getAllMenu = gql`
query($currentPage: Int, $searchList: String) {
	getAllMenu(currentPage: $currentPage, searchList: $searchList){
	  count
	  status
	  errorMessage
	  results{
	   id
	   shopId
	   menuName
	   isEnable
	   menuAvailability{
		menuId
		operationDay
		isOpen
		isWholeDay
	   }
	  }
	}
  }
`;

export const deleteMenu = gql`
mutation deleteMenu($id: Int) {
    deleteMenu(id: $id) {
      status
      errorMessage
    }
  }
`;

export const deleteSubMenu = gql`
mutation deleteSubMenu($id: Int) {
    deleteSubMenu(id: $id) {
      status
      errorMessage
    }
  }
`;

export const getSubMenu = gql`
query getSubMenu($id: Int!){
	getSubMenu( id: $id){
	   id
	   menuId
	   name
	   isEnable
	}
  }`;

export const itemAvailable = gql`
{
    itemAvailable {
        status
        errorMessage
    }
}
`;

export const updateSubMenuStatus = gql`
    mutation updateSubMenuStatus($id: Int, $status: Boolean) {
        updateSubMenuStatus(id: $id, status:$status) {
          status
          errorMessage
        }
      }
    `;

export const updateMenuStatus = gql`
    mutation updateMenuStatus($id: Int, $status: Boolean) {
        updateMenuStatus(id: $id, status:$status) {
          status
          errorMessage
        }
      }
    `;

export const updateItemStatus = gql`
mutation updateItemStatus($id: Int, $status: Boolean) {
    updateItemStatus(id: $id, status:$status) {
      status
      errorMessage
    }
  }
`;

export const updateItemMutation = gql`mutation ($id: Int, $menuId: Int, $subMenuId: Int, $itemName: String, $itemDescription: String, $price: Float, $tax: Float, $itemType: Int, $image: String, $isEnable: String, $modifiers: String) {
	updateItem(id: $id, menuId: $menuId, subMenuId: $subMenuId, itemName: $itemName, itemDescription: $itemDescription, price: $price, tax: $tax, itemType: $itemType, image: $image, isEnable: $isEnable, modifiers: $modifiers) {
	  status
	  errorMessage
	}
  }
`;

export const updateMenu = gql`mutation ($operationHours: String, $menuName: String, $id: Int, $isEnable: Boolean){
	updateMenu(operationHours: $operationHours, menuName: $menuName, id: $id, isEnable: $isEnable){
			status
			errorMessage
		}
	}`;

export const addMenuMutation = gql`mutation ($operationHours: String, $menuName: String, $isEnable: Boolean){
	addMenu(operationHours: $operationHours, menuName: $menuName, isEnable: $isEnable){
			status
			errorMessage
		}
	}`;