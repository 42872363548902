import showToaster from '../../../helpers/showToaster';
import { updateCategoryPrivilege as mutation } from '../../../lib/graphql';
import {
    UPDATE_CATEGORY_PRIVILEGE_SUCCESS,
    UPDATE_CATEGORY_PRIVILEGE_START,
    UPDATE_CATEGORY_PRIVILEGE_ERROR
} from '../../../constants';

const updateCategoryPrivilege = (id, isActive, privilegeType) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: UPDATE_CATEGORY_PRIVILEGE_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    privilegeType,
                    isActive
                }
            });

            if (data?.updateCategoryPrivilege?.status === 200) {
                dispatch({
                    type: UPDATE_CATEGORY_PRIVILEGE_SUCCESS
                });
                showToaster({ messageId: 'updateCategory', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: UPDATE_CATEGORY_PRIVILEGE_ERROR
                });
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateCategoryPrivilege?.errorMessage });
                return {
                    status: 400
                };
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
            dispatch({
                type: UPDATE_CATEGORY_PRIVILEGE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};

export default updateCategoryPrivilege;