import axios from 'axios';
import { decode } from './queryEncryption';

export async function getConfigSettings({ name }) {

    const query = `query getConfigSettings($name: String) {
        getConfigSettings(name: $name) {
            title
            name
            encryptValue
        }
    }`;
    
    try {
        const { data } = await axios.post('/graphql', {
            query,
            variables: {
                name: JSON.stringify(name)
            }
        }, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
        
        let settingsData = {};
        if (settingsData) {
            await Promise.all(data?.data?.getConfigSettings?.map((item, key) => {
                settingsData[item.name] = decode(item.encryptValue);
            }));
        }
        return settingsData;
    } catch (error) {
        return error;
    }
}