import showToaster from '../../../helpers/showToaster';
import { updateDeliveryVehicleImage as mutation } from '../../../lib/graphql';
import { removeImage } from '../../../helpers/removeImage';
import {
  UPDATE_DELIVERY_VEHICLE_PHOTO_START,
  UPDATE_DELIVERY_VEHICLE_PHOTO_SUCCESS,
  UPDATE_DELIVERY_VEHICLE_PHOTO_ERROR
} from '../../../constants';

const updateDeliveryVehicleImage = (id, fileName, oldPicture) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: UPDATE_DELIVERY_VEHICLE_PHOTO_START,
        payload: {
          imageUploadLoading: true
        }
      })

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          fileName
        }
      });

      if (data?.updateDeliveryVehicleImage?.status === 200) {
        dispatch({
          type: UPDATE_DELIVERY_VEHICLE_PHOTO_SUCCESS,
          payload: {
            imageUploadLoading: false
          }
        });
        showToaster({ messageId: 'updateVehicleImage', toasterType: 'success' })

        let url = "/deleteDeliveryVehicleImage";

        if (oldPicture !== null) {
          removeImage({ url, fileName: oldPicture })
        };
      } else {
        dispatch({
          type: UPDATE_DELIVERY_VEHICLE_PHOTO_ERROR,
          payload: {
            imageUploadLoading: false
          }
        })
      }

    } catch (err) {
      dispatch({
        type: UPDATE_DELIVERY_VEHICLE_PHOTO_ERROR,
        payload: {
          imageUploadLoading: false
        }
      })
    }
  }
};

export default updateDeliveryVehicleImage;
