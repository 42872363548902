import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { flowRight as compose } from 'lodash'
import { connect } from 'react-redux';
import EditPolygon from './EditPolygon';
import validate from './validate';

// Style
import {
  FormGroup,
  Col,
  FormControl,
  Container,
  Row,
  Form
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ManageLocation.css';
import Loader from '../../Common/Loader';
import { injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import submit from './submit';
import { googleMapAPI } from '../../../config';

class ManageLocationForm extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
  }

  static defaultProps = {
    loading: false
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, note }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={s.formGroup}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
            <label className={s.labelText} >{label}</label>
            <FormControl {...input} placeholder={label} type={type} className={className} />
            {
              note && <p className={s.subtext}>{note}</p>
            }
            {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
          </Col>
        </Row>
      </FormGroup>
    );
  }

  renderTextAreaField = ({ input, label, type, meta: { touched, error }, children, labelClass, fieldClass, placeholder }) => {
    return (
      <Form.Group className={s.formGroup}>
        <Form.Label>{label}</Form.Label>
        <Form.Control as="textarea" rows="3" {...input} placeholder={placeholder} type={type} className={fieldClass} />
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
      </Form.Group>
    )
  }

  render() {

    const { error, handleSubmit, submitting, title, loading, initialValues } = this.props;
    const { formatMessage } = this.props.intl;
    const mapUrl = "https://maps.googleapis.com/maps/api/js?key=" + googleMapAPI + "&v=3.exp&libraries=geometry,drawing,places";

    return (
      <div className={cx(s.pagecontentWrapper, 'mapSection')}>
        <Container className={s.containerWith}>
          <div className={s.contentBox}>
            <h1 className={s.headerTitle}>{title}</h1>
            <div className={cx(s.blockcenter, s.passwordSection)}>
              <Form className={s.fullWidth} onSubmit={handleSubmit(submit)} >
                {error && <strong>{error}</strong>}
                {
                  <Field
                    name="locationName"
                    type="text"
                    component={this.renderFormControl}
                    label={"Name"}
                    className={s.formControlInput}
                  />
                }
                {
                  <div className={s.space3}>
                    <label>{formatMessage(messages.status)}</label><br />
                    <Field name="isActive" className={cx(s.formControlSelect, s.formControlInput)} component="select">
                      <option value={true}>{formatMessage(messages.active)}</option>
                      <option value={false}>{formatMessage(messages.inactive)}</option>
                    </Field>
                  </div>
                }
                {
                  <Field
                    name="description"
                    type="text"
                    placeholder={formatMessage(messages.description)}
                    component={this.renderTextAreaField}
                    label={formatMessage(messages.description)}
                    labelClass={s.labelText}
                    fieldClass={s.formControlInput}
                  />
                }
                <div className={s.relative}>
                  <EditPolygon
                    googleMapURL={mapUrl}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    zoom={4}
                    path={initialValues.coordinates}
                  />
                </div>
                <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop3)}>
                  <Form.Group className={s.formGroup}>
                    <div className={s.displayInlineBlock}>
                      <Loader
                        type={"button"}
                        label={formatMessage(messages.submitButton)}
                        show={loading}
                        buttonType={'submit'}
                        className={cx(s.button, s.btnPrimary)}
                        disabled={submitting || loading}
                        isSuffix={true}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Form>
            </div>
          </div>
        </Container>
      </div>
    );
  }

}

ManageLocationForm = reduxForm({
  form: 'ManageLocationForm',
  onSubmit: submit,
  validate
})(ManageLocationForm);

const mapState = (state) => ({
  loading: state.loader.updateLocation
});

const mapDispatch = {};

export default injectIntl(compose(
  withStyles(s),
  connect(mapState, mapDispatch)
)(ManageLocationForm));
