import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import cx from 'classnames';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
// style
import s from './HeaderAfterLogin.css';

// components
import Switch from '../../../Common/Switch/Switch';
import DropdownNavLink from '../../../Common/DropdownNavLink/DropdownNavLink';
import Link from '../../../Link/Link';
import HeaderModal from '../../../HeaderModal/HeaderModal';

// redux actions
import { shopLogout } from '../../../../actions/shop/logout/shopLogout';
import shopUserActivityChange from '../../../../actions/shop/userAccount/shopUserActivityChange';
import { openHeaderModal } from '../../../../actions/siteadmin/modalActions';
import { closeOrderModal, openOrderModal } from '../../../../actions/modalActions';
// Image
import store from '../../../../../public/StoreIcon/defaultstoreimage.svg';
import languageIcon from '../../../../../public/Icons/Language-black.svg';

// helper
import { formatLocale } from '../../../../helpers/formatLocale';
// Local
import messages from '../../../../locale/messages';

// Config
import { api, storeImageUploadDir } from '../../../../config';
import OrderModal from '../../OrderModal/OrderModal';
import { getPendingOrders } from '../../../../actions/shop/orders/getPendingOrders';
import checkUserStatusQuery from './getCheckUserStatus.graphql';

export class HeaderAfterLogin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dropDown: false,
        };
        this.handleSwitch = this.handleSwitch.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { socket, userId } = this.props;
        if (socket && userId) {
            socket.on(`orderReceivedShop-${userId}`, (data) => { this.handleSocketResponse() });
        }
        if (socket && userId) {
            socket.on(`shopApproved-${userId}`, (data) => {
                this.handleModalClose()
            })
        }
    }

    componentWillUnmount() {
        const { socket, userId } = this.props;
        if (socket && userId) {
            socket.removeListener(`webUserLogout-${userId}`);
        }
    }

    async handleSwitch(status) {
        const { shopUserActivityChange, shopStatus } = this.props;
        shopUserActivityChange(status);
    }
    handleModalClose = async () => {
        const { getPendingOrders } = this.props;
        await getPendingOrders();
    }

    handleSocketResponse = async () => {
        const { openOrderModal, getPendingOrders } = this.props;
        await getPendingOrders();
        await openOrderModal();
    }

    handleChange(e) {
        const { openHeaderModal } = this.props;
        openHeaderModal('languageModal');
    }


    render() {
        const { shopLogout, shopPicture, shopStatus, currentLocale } = this.props;
        const { socket, userId } = this.props;
        const { checkLoginUserExist: { userExistloading, getUserExists } } = this.props;

        if (socket && userId) {
            socket.on(`webUserLogout-${userId}`, (data) => {
                if (typeof window !== "undefined") window.location.assign('/shopUserLogout');
            });
        }

        if (!userExistloading && getUserExists) {
            if (getUserExists.userExistStatus) {
                const isBrowser = typeof window !== 'undefined';
                if (isBrowser) {
                    shopLogout();
                    window.location.reload();
                }
            }
        }

        return (
            <div className={cx(s.root)}>

                <Nav className={cx("mr-auto")}>
                    <HeaderModal />
                    <OrderModal />
                    <div className={s.menuHeight}>
                        <Switch
                            switchOnLabel={<FormattedMessage {...messages.available} />}
                            switchOffLabel={<FormattedMessage {...messages.unavailable} />}
                            handleSwitch={this.handleSwitch}
                            value={shopStatus}
                            component={'HeaderAfterLogin'}
                        />
                    </div>
                    <div className={s.menuHeight}>
                        <a
                            onClick={(e) => this.handleChange(e)}
                            className={cx(s.cursurPointer)}>
                            <span className={s.languageIcon}><img src={languageIcon} /></span>
                            <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding, 'iconTextPaddingRTL')}>
                                {formatLocale(currentLocale)}
                            </span>
                        </a>
                    </div>
                    <div className={cx(s.menuHeight, 'headerDroup')}>
                        <form>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    {shopPicture ?
                                        <img
                                            src={api.apiEndpoint + storeImageUploadDir + shopPicture}
                                            height={30}
                                            width={30}
                                            alt={'Profile Image'}
                                            className={s.imgSection}
                                            onClick={() => this.setState({ dropDown: true })}
                                        >
                                        </img>
                                        :
                                        <img
                                            src={store}
                                            height={30}
                                            width={30}
                                            alt={'Profile Image'}
                                            className={s.imgSection}
                                            onClick={() => this.setState({ dropDown: true })}
                                        >
                                        </img>
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={s.linkBorderColor}>
                                    <DropdownNavLink to={'/store/edit-profile'}>
                                        <FormattedMessage {...messages.editProfile} />
                                    </DropdownNavLink>
                                    <DropdownNavLink to={'/store/document'}>
                                        <FormattedMessage {...messages.documentUpload} />
                                    </DropdownNavLink>
                                    <DropdownNavLink to={'/store/password'}>
                                        <FormattedMessage {...messages.password} />
                                    </DropdownNavLink>
                                    <Dropdown.Item  onClick={() => shopLogout()} className={s.menuHeight}>
                                        <FormattedMessage {...messages.logout} />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </form>
                    </div>
                </Nav>
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    shopPicture: state && state.account && state.account.data && state.account.data.profile && state.account.data.profile.picture,
    shopStatus: state && state.account && state.account.data && state.account.data.isActive,
    userId: state.account && state.account.data && state.account.data.id,
    currentLocale: state.intl.locale
});

const mapDispatchToProps = {
    shopLogout,
    shopUserActivityChange,
    openHeaderModal,
    openOrderModal,
    getPendingOrders,
    closeOrderModal
};

export default compose(
    withStyles(s),
    graphql(checkUserStatusQuery, {
        name: 'checkLoginUserExist',
        options: {
            ssr: false,
            pollInterval: 60000, // 1 minute
        },
    }),
    (connect(mapStateToProps, mapDispatchToProps))
)(HeaderAfterLogin);