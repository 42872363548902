import { checkUserAvailability as query } from '../../../lib/graphql';
import {
    CHECK_USER_AVAILABLE_START,
    CHECK_USER_AVAILABLE_SUCCESS,
    CHECK_USER_AVAILABLE_ERROR
} from '../../../constants';

export const checkUserAvailability = (id) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: CHECK_USER_AVAILABLE_START,
            });

            const { data } = await client.query({
                query,
                variables: {
                    id
                },
                fetchPolicy: 'network-only',
            });

            if (data?.checkUserAvailability?.status == 200) {
                dispatch({
                    type: CHECK_USER_AVAILABLE_SUCCESS,
                });
                return true;
            } else {
                dispatch({
                    type: CHECK_USER_AVAILABLE_ERROR,
                });
                return false;
            }

        } catch (error) {
            dispatch({
                type: CHECK_USER_AVAILABLE_ERROR,

            });
            return false;
        }
    };
};