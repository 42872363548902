const errorMessage = async (errorCode, requestContent) => {

    const message = {
        success: 'Success!',
        error: 'Error!',
        warning: 'Warning!',
        ogImageError: 'Please upload the OG Image',
        banUserError: 'Please do not ban the customer. It looks like the customer is having an active delivery order and try again later.',
        checkUserStatus: 'Please do not change the customer status. It looks like the customer is having an active delivery order and try again later.',
        checkUserEmail: 'Oops, the provided email is already exits with the other user.',
        checkUserNumb: 'Oops, the provided phone number is already exits with the other user.',
        findAdminRole: 'Sorry, unable to find this admin role.',
        adminUsing: 'Oops! Unable to delete the admin role due to some admin user using this role.',
        findActiveBooking: 'Oops! It looks like the customer has an order and unable to delete them',
        createCommonSetting: `Oops! Something went wrong. Unable to ${requestContent} the setting.`,
        noDriver: 'Sorry, no delivery partner found.',
        noUpdate: 'Not Updated',
        addItem: 'Please add a item.',
        updateItemError: 'Something went wrong! Item status not updated',
        disableBaseCurrency: "Sorry, you can't disable the base currency. Please change the platform's base currency and try again.",
        disableCurrency: 'Oops! Unable to disable this currency. The currency is used for the platform\'s preferred currency. Please change the platform\'s preferred currency and try again',
        updateCurrencies: 'Oops! Unable to change the currency status. Please try again.',
        deliveryVehicle: 'Oops! something went wrong! Unable to update the delivery vehicle image.',
        createUser: 'Oops, something went wrong. Unable to create an admin user.',
        phoneVerified: 'Invalid Verification Code',
        invalidConfirmPass: 'Wrong Confirm Password',
        operationHoursError: 'Operation Hours Not Updated',
        deletePromo: 'Oops! something went wrong. Unable to delete the promo code.',
        promoExist: 'Oops! It looks like already this promo code is used. Please try again with different promo code name.',
        createPromo: 'Oops! Something went wrong! Unable to create a new promo code. Please try again.',
        updatePromo: 'Oops! Something went wrong! Unable to update the promo code. Please try again.',
        noPage: 'Oops! The page not found!',
        userLogin: 'Not Logged In as User',
        getBooking: 'Oops! It looks like the booking is not found and please try again.',
        updateModifierGroup: 'Oops! Unable to update a modifier group. Please try again.',
        modifierExist: 'Sorry, Unable to delete the modifier due to mapped with the items. Please remove it from the items and try again!',
        tokenError: 'Invalid token',
        checkLogin: 'Currently, you are logged in.',
        findOrder: 'Oops! Something went wrong! Unable to find the order.',
        userDeleted: 'Oops! We are unable to find your account. Please contact support for help.',
        banStatus: 'Sorry, it looks like your account has been blocked. Please contact our support team.',
        checkUser: "Oops! We don't recognize this email address",
        noOrder: 'Oops! Something went wrong! Order Not Found!',
        checkPassword: 'Oops! The password you’ve entered is incorrect.',
        findBooking: 'Oops! Unable to find the booking information. Please try again',
        noRecord: 'No record found.',
        changeShopStatus: "Oops! You can't able to change the status as the store doesn't complete the profile information.",
        updateCustomer: 'Oops! Something went wrong, unable to update the customer information.',
        findAccount: 'Oops! We are unable to find your account. Please logout and try again.',
        activeItemCount: 'Oops! Please activate at least one item to go available.',
        activeItemCount1: 'Oops! Please activate at least one sub-menu to go available.',
        activeItemCount2: 'Oops! Please activate at least one menu to go available.',
        approveAccount: 'Oops! You account has not been approved yet. Please our contact support for the help.',
        activePayment: 'AtLeast one payment method option must be active.',
        enableStripe: 'Please enable Stripe Payment or PayPal Payment to activate the wallet.',
        authorizedError: 'You haven\'t authorized for this action.',
        DeliveryVehicle: 'Unable to find the requested delivery vehicle.',
        noReview: 'Something went wrong! Review not found!',
        adminLogin: 'Please login as an admin user and try again.',
        loginError: 'Please login with your account and try again.',
        catchError: `Something went wrong! ${requestContent}`,
        urlExist: 'The page URL already exist!',
        commonError: requestContent || 'Oops! something went wrong! Please try again.',
        limitError: 'Maximum upload size Exceeded!, Try with smallest size image',
        fileTypeError: 'You are trying to upload invalid image file. Please upload PNG, JPG & JPEG format image file.',
        audioError: 'Maximum upload size Exceeded!, Try with smallest size audio',
        audioFileError: 'You are trying to upload invalid file. Please upload mp3 format audio file.',
        addContent: 'Please add content for the page.',
        uploadShopDoc: 'Your Document has been successfully uploaded.',
        uploadDocError: 'Please upload document.',
        updateShopProfile: 'Your Profile has been successfully updated.',
        sendForgotPasswordLink: 'Reset link email is on its way to your inbox',
        sendNotification: 'The notification has been sent successfully.',
        updateServiceFee: 'Service Fee Updated!',
        updateServiceFeeError: 'Update Service Fee failed',
        vehicleImageError: 'Vehicle type image is required.',
        updateContPageError: 'Updating Content Page Details failed',
        addContPageError: 'Creating Content Page Details failed',
        commonUpdate: 'Changes are updated!',
        commonAdd: 'Changes are added!',
        invalidRole: 'Oops, it looks like the chosen role is not valid. Please try with different valid role.',
        changeAdminUser: '"Admin details changed successfully!',
        changeAdminUserErr: 'Your changes to admin details failed!',
        emailExist: 'Oops! this email address is already exist.',
        addCategoryImg: 'Category image is required.',
        noEmail: 'No account exists for the given email id',
        addressError: 'Please provide address.',
        fareUsed: 'Sorry, unable to inactive. The chosen location is used on the manage fare. Please remove the fare and try again.',
        deleteStore: 'Oops! Unable to delete the store. They have some active orders.',
        activeCategory: "Oops, the provided category type doesn't exist.",
        userStatus: "Oops! You can't able to change the status as the store doesn't complete the profile information.",
        existNumber: 'Oops, the provided phone number is already exits.',
        phoneNumberError: 'Please provide phone number.',
        shopPayoutWarn: 'This feature is currently not available. Please contact admin for more information.',
        prepareTimeError: 'Please check your time slot. Either time slot is invalid or already available.',
        changePassword: 'Password is updated successfully',
        endTimeError: 'The end time must be higher than the start time.',
        addItemError: 'Please upload the image for item',
        enterNumb: 'Please enter phone number',
        uploadStoreImg: 'Please upload store image.',
        missMatchPassword: 'Password mismatch.Please try again.',
        updateTempImages: `Your Image has been ${requestContent ? 'uploaded' : 'removed'}.`,
        deletePromoCode: 'Promo Code has been deleted successfully.',
        updateOperationHour: 'The operating hours have been updated successfully.',
        updateForgotPassword: 'Your password has changed successfully, you can try login now!',
        updateItem: 'Your changes has been updated successfully.',
        addItem: 'A new item has been added successfully.',
        updateSubMenu: 'The Sub Menu status has been updated successfully.',
        addPromoCode: `Promo Code has been ${requestContent ? 'updated' : 'added'} successfully.`,
        deletePricing: 'The fare has been deleted successfully.',
        addUpdatePricing: `The fare has been ${requestContent} successfully.`,
        updateHomepage: 'The Store Homepage has been updated successfully.',
        uploadDocument: 'Your Document has been successfully uploaded.',
        updateShopStatus: `The ${requestContent} status has been updated successfully.`,
        updatePromoCode: `Promo Code has been ${requestContent} successfully.`,
        updatePricingStatus: `The fare has been ${requestContent} successfully.`,
        updateShop: 'The Store details has been updated successfully.',
        deleteShop: 'The Store has been deleted successfully.',
        updateVehicle: 'The vehicle type status has been updated.',
        updateVehicleImage: 'The vehicle type image has been uploaded!',
        deleteDeliveryVehicle: 'The vehicle has been deleted.',
        configSettings: 'Configurations updated successfully!',
        updateCategory: 'The category has been updated.',
        updateCategoryError: "Can't update the status as the category is used by stores",
        updateCategoryStatus: 'The category status has been updated.',
        updateCategoryImage: 'Category image has been uploaded successfully!',
        addMenu: 'A new Menu added successfully',
        addSubMenu: 'A new Sub Menu added successfully',
        deleteItem: 'The Item has been deleted successfully.',
        deleteMenu: 'The Menu has been deleted successfully.',
        deleteSubMenu: 'The Sub Menu has been deleted successfully.',
        updateItemStatus: 'The Item status has been updated successfully.',
        updateMenu: 'The Menu status has been updated successfully.',
        updateSubMenu: 'The Sub Menu status has been updated successfully.',
        updateModifier: 'The modifier changes have been updated successfully.',
        createModifier: 'The modifier has been created successfully.',
        deleteModifier: 'The Modifier has been deleted successfully.',
        getModifier: 'The modifier is unavailable at the moment. Please activate it on the "Modifier Groups" section.',
        updateModifierStatus: 'The Modifier status has been updated successfully.',
        updateOrder: 'The Order status has been updated successfully!',
        addPayoutError: 'This feature is currently not available. Please contact admin for more information.',
        removePayout: 'Payout account have been removed.',
        setDefaultPayout: 'Default payment method has been set.',
        updatePreparationTime: 'Your packing time preferences have been updated successfully.',
        createShopUser: 'You have successfully signed up.',
        verifyOtpCode: 'Phone Number verified successfully',
        addCancelReason: `Cancel Reason has been ${requestContent} successfully.`,
        addLocationError: 'Please select location',
        addLocation: 'The Location has been added successfully.',
        deleteLocation: 'The Location has been deleted successfully.',
        updateLocation: 'The Location has been updated successfully.',
        uploadImage: 'The image has been uploaded!',
        deleteUser: `${requestContent} has been deleted successfully.`,
        updateDriver: 'Driver has been updated!',
        updateRider: 'The customer information has been updated successfully!',
        removeCancel: 'Cancel Reason has been deleted successfully.',
        removeCancelError: 'Cancel Reason deletion failed',
        updateHomePage: 'Homepage settings changes have been successfully applied',
        commonDelete: 'Deleted Successfully',
        commonFail: 'Deleted Failed',
        updateContent: 'Status has changed',
        updateSiteSettings: 'Site settings changes have been successfully applied',
        uploadInsuranceImage: 'Insurance image has been uploaded!',
        uploadLicenseImage: 'License images has been uploaded!',
        uploadProfileImage: 'Profile image has been uploaded!',
        uploadRCbookImage: 'RC book image has been uploaded!',
        createAdminRole: `Admin Role has been ${requestContent} successfully!`,
        deleteAdminRole: 'Admin Role has been deleted successfully!',
        createAdminUser: `Admin User has been ${requestContent} successfully!`,
        deleteAdminUser: 'Admin User has been deleted successfully!',
        addCategory: 'The category has been added successfully.',
        deleteCategory: 'The category has been deleted.',
        deleteCategoryError: "Can't delete the category as the category is used by stores",
        updateStaticPage: 'Changes are updated!',
        updateStaticFail: 'Updating failed',
        addCommonSettings: 'The setting has been created successfully.',
        updateCommonSettings: 'The setting has been updated successfully.',
        deleteCommonSetting: 'The setting has been deleted Successfully!',
        updateCommonSettingStatus: 'The setting status has been updated successfully!',
        addVehicle: `The vehicle type has been ${requestContent} successfully.`,
        updateCurrency: 'The currency status has been updated successfully.',
        setBaseCurrency: 'Base Currency is set successfully',
        baseCurrencyError: 'Failed to set Base Currency',
        allowPaymentCurrency: 'Allowed Payment Currency Success',
        paymentCurrError: 'Failed to set allowed payment currency success',
        updatePayout: 'The requested changes has been updated successfully.',
        updatePayment: 'Payment method status has been updated successfully.',
        orderUnAvail: 'Oops! It looks like the order is not available.',
        orderExist: `It looks like the order has already ${requestContent}`,
        checkShopUser: 'Sorry, it looks like the email or phone number is already exist.',
        updateDriveInform: 'Oops! Something went wrong, unable to update the delivery partner information.',
        unableToProceed: 'Oops! Unable to proceed the requested action. Please try again.',
        alreadyLoggedIn: 'Sorry, It looks like you are already logged-in with your account. Please logout and try again.',
        loggedInAsAnAdmin: 'Sorry, It looks like you are already logged-in as an admin. Please logout and try again.',
        networkFailed: 'You are offline. Please try again later',
        paymentTypeError: 'Oops! you are not allowed to remove all the currencies. Please make sure that single currency is added.',

    }
    return message[errorCode] || 'Oops! something went wrong! Please try again.'

}
export default errorMessage;