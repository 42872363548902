import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
// style
import s from './ViewBooking.css';
// graphql
import viewBookingDetails from './viewBookingDetails.graphql';
// components
import ViewBookingDetails from '../../../components/SiteAdmin/Booking/ViewBookingDetails';
import Loader from '../../../components/Common/Loader/Loader';

export class ViewBooking extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        data: {
            loading: true
        },
        pageType: 'bookings'
    };

    render() {
        const { data: { loading, getBookingDetails, refetch }, title, pageType } = this.props;

        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.paddingRoutesSection}>
                            {
                                !loading && <ViewBookingDetails data={getBookingDetails}
                                    title={title}
                                    refetch={refetch}
                                    pageType={pageType}
                                />
                            }
                        </div>
                    </div>
                </div>
            </Loader>
        )
    }
}

export default compose(
    withStyles(s),
    graphql(viewBookingDetails, {
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only',
        })
    })
) (ViewBooking);
