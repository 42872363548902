import history from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { getPaymentMethods as query } from '../../../lib/graphql';
import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../../constants';

export const checkPayout = () => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: ADD_PAYOUT_START
      });

      const { data } = await client.query({
        query,
        fetchPolicy: 'network-only'
      });

      dispatch({
        type: data?.getPaymentMethods?.length > 0 ? ADD_PAYOUT_SUCCESS : ADD_PAYOUT_ERROR
      });

      data?.getPaymentMethods?.length <= 0 && showToaster({ messageId: 'addPayoutError', toasterType: 'warning' });
      data?.getPaymentMethods?.length > 0 && history.push('/store/addpayout');
    } catch (error) {
      dispatch({
        type: ADD_PAYOUT_ERROR
      });
    }
  };
};