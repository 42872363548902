import showToaster from '../../../helpers/showToaster';
import { updatePayoutMutation, getPaymentMethodsQuery, updatePaymentMutation } from '../../../lib/graphql';
import {
  SET_PAYOUT_START,
  SET_PAYOUT_SUCCESS,
  SET_PAYOUT_ERROR,
  UPDATE_PAYMENT_STATUS_START,
  UPDATE_PAYMENT_STATUS_SUCCESS,
  UPDATE_PAYMENT_STATUS_ERROR
} from '../../../constants';

const updatePayoutStatus = (id, requestStatus, fieldName) => {
  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: SET_PAYOUT_START,
      });

      const { data } = await client.mutate({
        mutation: updatePayoutMutation,
        variables: {
          id,
          requestStatus,
          fieldName
        }
      });

      if (data?.updatePayout?.status === 200) {
        dispatch({
          type: SET_PAYOUT_SUCCESS,
        });
        showToaster({ messageId: 'updatePayout', toasterType: 'success' });

        return {
          status: 200
        };

      } else {
        dispatch({
          type: SET_PAYOUT_ERROR,
          payload: {
            error: data?.updatePayout?.errorMessage || 'Something went wrong. Please try again.'
          }
        });
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updatePayout?.errorMessage });
      }

      return {
        status: 400
      };

    } catch (error) {
      dispatch({
        type: SET_PAYOUT_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });

      return {
        status: 400
      };

    }
  };
}

const updatePaymentMethod = (id, status) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: UPDATE_PAYMENT_STATUS_START,
      });

      const { data } = await client.mutate({
        mutation: updatePaymentMutation,
        variables: {
          id,
          status
        },
        refetchQueries: [{ query: getPaymentMethodsQuery }]
      });

      if (data?.updatePayment?.status === 200) {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_SUCCESS,
        });
        showToaster({ messageId: 'updatePayment', toasterType: 'success' });
      } else {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_ERROR,
          payload: {
            error: data?.updatePayment?.errorMessage
          }
        });
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updatePayment?.errorMessage });
        return false;
      }

    } catch (error) {
      dispatch({
        type: UPDATE_PAYMENT_STATUS_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
      return false;
    }
    return true;
  };
}

export {
  updatePayoutStatus, updatePaymentMethod
};