exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1j8fL {\n\tdisplay: inline-block;\n\tmargin-left: 19px;\n}\n._3jHEq {\n\twidth: 100%;\n\tmax-width: 320px;\n}\n.nWlb- {\n    color: #1A1B1C;\n    font-weight: bold;\n}\n._31z4x {\n\tpadding: 6px 0px 0px 0px;\n\tfont-weight: 500;\n\tfont-size: 16px;\n\tposition: absolute;\n\tcolor: red;\n}\n@media screen and (max-width: 767px) {\n\t._3jHEq {\n\t\twidth: 93%;\n\t}\n\t._1j8fL {\n\t\tdisplay: block;\n\t\tmargin-left: 0;\n\t\ttext-align: right;\n\t\tmargin: 15px 0;\n\t}\n}", ""]);

// exports
exports.locals = {
	"displayInline": "_1j8fL",
	"firstSection": "_3jHEq",
	"highlight": "nWlb-",
	"noResult": "_31z4x"
};