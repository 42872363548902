import showToaster from '../../../helpers/showToaster';
import { getAllModifierQuery, deleteModifierMutation } from '../../../lib/graphql';
import { getAllShopModifier } from './getAllShopModifier';
import {
	SHOP_MODIFIER_UPDATE_START,
	SHOP_MODIFIER_UPDATE_SUCCESS,
	SHOP_MODIFIER_UPDATE_ERROR
} from '../../../constants/index';

const deleteModifier = (id, currentPage, searchList) => {
	return async (dispatch, getState, { client }) => {

		try {
			dispatch({
				type: SHOP_MODIFIER_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});

			const { data: { deleteModifier } } = await client.mutate({
				mutation: deleteModifierMutation,
				variables: {
					id
				},
				refetchQueries: [{
					query: getAllModifierQuery,
					variables: {
						currentPage,
						searchList
					}
				}]
			});

			if (deleteModifier?.status === 200) {
				dispatch({
					type: SHOP_MODIFIER_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'deleteModifier', toasterType: 'success' });
			} else {
				dispatch({
					type: SHOP_MODIFIER_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: deleteModifier?.errorMessage });
			}
			dispatch(getAllShopModifier());

		} catch (err) {
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err })
			dispatch({
				type: SHOP_MODIFIER_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};

export default deleteModifier;