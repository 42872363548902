import gql from "graphql-tag";

export const updateCancelReason = gql`
mutation(
    $id: Int, 
    $userType: Int, 
    $reason: String, 
    $isActive: Boolean
    ) {
    updateCancelReason(
        id: $id, 
        userType: $userType, 
        reason: $reason, 
        isActive: $isActive
        ) {
        status
        errorMessage
    }
}`;

export const checkUserAvailability = gql`
query ($id:Int!) {
    checkUserAvailability (id:$id) {
      status        
    }
  }
`;

export const deleteUser = gql`
mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
        status
        errorMessage
    }
}
`;

export const updateDriver = gql`
mutation updateDriver(
    $id: ID,
    $firstName: String,
    $lastName: String,
    $email: String,
    $password: String,
    $phoneDialCode: String
    $phoneNumber: String,
    $vehicleName: String,
    $vehicleNumber: String,
    $vehicleType: Int,
    $userStatus: String,
    $isBan: Int,
    $phoneCountryCode: String
    ) {
    updateDriver(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email:$email
      password: $password
      phoneDialCode: $phoneDialCode
      phoneNumber: $phoneNumber
      vehicleName: $vehicleName
      vehicleNumber: $vehicleNumber
      vehicleType: $vehicleType
      userStatus: $userStatus
      isBan: $isBan
      phoneCountryCode: $phoneCountryCode
    )
    {
      status
      errorMessage
    }
  }
`;

export const getAllDrivers = gql`
query getAllDrivers($currentPage: Int, $searchList: String){
  getAllDrivers(currentPage: $currentPage, searchList: $searchList){
    count
    userData {
      id
      email
      phoneDialCode
      phoneNumber
      isBan
      userStatus
      createdAt
      profile {
        profileId
        firstName
        lastName
        country
      }
    }   
  }
}
`;

export const updateRider = gql`
mutation updateRider(
    $id: ID,
    $firstName: String,
    $lastName: String,
    $email: String,
    $password: String,
    $phoneDialCode: String
    $phoneNumber: String,
      $userStatus: String,
      $isBan: Int
      $phoneCountryCode: String
    ) {
    updateRider(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email:$email
      password: $password
      phoneDialCode: $phoneDialCode
      phoneNumber: $phoneNumber
      userStatus: $userStatus
      isBan: $isBan
      phoneCountryCode: $phoneCountryCode
    )
    {
      status
      errorMessage
    }
  }
`;

export const updateVehicle = gql`
mutation updateVehicle($id: Int, $vehicleName: String, $vehicleType: Int, $vehicleNumber: String, $vehicleStatus: String) {
    updateVehicle(
    id: $id
    vehicleName: $vehicleName,
    vehicleType: $vehicleType
    vehicleNumber: $vehicleNumber,
    vehicleStatus: $vehicleStatus
    ){
    status
    }
}
`;

export const getAllVehicles = gql`
query getAllVehicles($currentPage: Int){
    getAllVehicles(currentPage: $currentPage){
    count
    vehicleData{
        id
        vehicleType
        vehicleNumber
        vehicleName 
        vehicleStatus
        category{
            categoryName
        }
        user{
            email
            profile{
            firstName
            }
        }
    }
    }
}
`;

export const getAllCancelReason = gql`
query getAllCancelReason ($searchList: String $currentPage: Int) {
    getAllCancelReason(searchList: $searchList currentPage: $currentPage) {
      count
      result{
        id
        reason
        userType
        isActive
      }
      status
    }
  }
`;

export const removeCancelReason = gql`
mutation($id: Int) {
    removeCancelReason(id: $id) {
        status
        errorMessage
    }
}
`;

export const getContentPageDetails = gql`
query getContentPageDetails {
    getContentPageDetails{
      id
      metaTitle
      metaDescription
      pageUrl
      pageTitle
      content
      isEnable
      createdAt
    }
  }
`;

export const deleteContentPage = gql`
mutation deleteContentPage ($id: Int!) {
    deleteContentPage (id: $id) {
        status
    }
}
`;

export const updateContentPageStatusMutation = gql`
mutation updateContentPageStatus ($id: Int, $isEnable: Boolean) {
    updateContentPageStatus(id: $id, isEnable: $isEnable){
        status
    }
}
`;

export const getSiteSettings = gql`
query getSiteSettings($type:String){
    getSiteSettings(type:$type){
    name
    value
    }
}
`;

export const updateHomePageAbout = gql`
mutation updateHomePageAbout (
    $aboutGridImage1: String
    $aboutGridImage2: String
    $aboutGridTitle1: String
    $aboutGridTitle2: String
    $aboutGridTitle3: String
    $aboutGridTitle4: String
    $aboutGridTitle5: String
    $aboutGridTitle6: String
    $aboutGridContent1: String
    $aboutGridContent2: String
    $aboutGridContent3: String
    $aboutGridContent4: String
    $aboutGridContent5: String
    $aboutGridContent6: String,
    $aboutGridImage3: String,
  ) {
    updateHomePageAbout (
      aboutGridImage1: $aboutGridImage1
      aboutGridImage2: $aboutGridImage2
      aboutGridTitle1: $aboutGridTitle1
      aboutGridTitle2: $aboutGridTitle2
      aboutGridTitle3: $aboutGridTitle3
      aboutGridTitle4: $aboutGridTitle4
      aboutGridTitle5: $aboutGridTitle5
      aboutGridTitle6: $aboutGridTitle6
      aboutGridContent1: $aboutGridContent1
      aboutGridContent2: $aboutGridContent2
      aboutGridContent3: $aboutGridContent3
      aboutGridContent4: $aboutGridContent4
      aboutGridContent5: $aboutGridContent5
      aboutGridContent6: $aboutGridContent6,
      aboutGridImage3: $aboutGridImage3
      
    ) {
      status
    }
  }
`;

export const updateHomePageCity = gql`
mutation updateHomePageCity (
    $citySectionTitle1: String
    $citySectionContent1: String
  ) {
    updateHomePageCity (
      citySectionTitle1: $citySectionTitle1
      citySectionContent1: $citySectionContent1
    ) {
      status
    }
  }
`;

export const updateHomePageDriver = gql`
mutation updateHomePageDriver (
    $safetyGridImage4: String
    $safetyGridImage5: String
    $safetyGridImage6: String
    $safetyGridTitle2: String
    $safetyGridContent2: String
    $safetyGridLink3: String
    $safetyGridLink4: String
  ) {
    updateHomePageDriver (
        safetyGridImage4: $safetyGridImage4
        safetyGridImage5: $safetyGridImage5
        safetyGridImage6: $safetyGridImage6
        safetyGridTitle2: $safetyGridTitle2
        safetyGridContent2: $safetyGridContent2
        safetyGridLink3: $safetyGridLink3
        safetyGridLink4: $safetyGridLink4
    ) {
      status
    }
  }
`
export const updateHomePageHome = gql`
mutation updateHomePageHome (
    $homeSectionImage1: String
    $homeSectionImage2: String
    $homeSectionImage3: String
    $homeSectionImage4: String
    $homeSectionImage5: String
    $homeSectionImage6: String
    $homeSectionDescription: String
    $homeSectionTitle1: String
  ) {
    updateHomePageHome (
      homeSectionImage1: $homeSectionImage1
      homeSectionImage2: $homeSectionImage2
      homeSectionImage3: $homeSectionImage3
      homeSectionImage4: $homeSectionImage4
      homeSectionImage5: $homeSectionImage5
      homeSectionImage6: $homeSectionImage6
      homeSectionDescription: $homeSectionDescription
      homeSectionTitle1: $homeSectionTitle1
    ) {
      status
    }
  }
`;

export const updateHomePageFooter = gql`
mutation updateHomePageFooter (
    $footerLogo1: String
    $footerLogo2: String
    $footerLogo3: String
    $footerLogo4: String
    $footerTitle1: String
    $footerContent1: String
    $footerLink1: String
    $footerLink2: String
    $footerLink3: String
    $footerLink4: String
    $footerLinkName1: String
    $footerLinkName2: String
    $footerLinkName3: String
    $footerLinkName4: String
    $footerLinkTitle: String
    $footerBottom: String
  ) {
    updateHomePageFooter (
      footerLogo1: $footerLogo1
      footerLogo2: $footerLogo2
      footerLogo3: $footerLogo3
      footerLogo4: $footerLogo4
      footerTitle1: $footerTitle1
      footerContent1: $footerContent1
      footerLink1: $footerLink1
      footerLink2: $footerLink2
      footerLink3: $footerLink3
      footerLink4: $footerLink4
      footerLinkName1: $footerLinkName1
      footerLinkName2: $footerLinkName2
      footerLinkName3: $footerLinkName3
      footerLinkName4: $footerLinkName4
      footerLinkTitle: $footerLinkTitle
      footerBottom: $footerBottom
    ) {
      status
    }
  }
`;

export const updateHomePageSafety = gql`
mutation updateHomePageSafety (
    $safetyGridImage1: String
    $safetyGridImage2: String
    $safetyGridImage3: String
    $safetyGridTitle1: String
    $safetyGridContent1: String
    $safetyGridLink1: String
    $safetyGridLink2: String
  ) {
    updateHomePageSafety (
      safetyGridImage1: $safetyGridImage1
      safetyGridImage2: $safetyGridImage2
      safetyGridImage3: $safetyGridImage3
      safetyGridTitle1: $safetyGridTitle1
      safetyGridContent1: $safetyGridContent1
      safetyGridLink1: $safetyGridLink1
      safetyGridLink2: $safetyGridLink2
    ) {
      status
    }
  }
`;

export const updateHomePageSignup = gql`
mutation updateHomePageSignup (
    $signupGridImage1: String
    $signupGridImage2: String
    $signupGridImage3: String
    $signupGridTitle1: String
    $signupGridContent1: String
    $signupGridLink1: String
    $signupGridLink2: String
  ) {
    updateHomePageSignup (
      signupGridImage1: $signupGridImage1
      signupGridImage2: $signupGridImage2
      signupGridImage3: $signupGridImage3
      signupGridTitle1: $signupGridTitle1
      signupGridContent1: $signupGridContent1
      signupGridLink1: $signupGridLink1
      signupGridLink2: $signupGridLink2
    ) {
      status
    }
  }
`;