import React, { Component } from 'react';
import s from './AddCancelReasonForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector, initialize } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    Container
} from 'react-bootstrap';
import Link from '../../Link';
import messages from '../../../locale/messages';
import validate from './validate';
import Loader from '../../Common/Loader';

export class AddCancelReasonForm extends Component {

    static defaultProps = {
        loading: false
    };

    componentDidMount() {
        const { initialize, initialValues } = this.props;
        if (initialValues && initialValues.id) {
            initialize({
                id: initialValues && initialValues.id,
                userType: initialValues && initialValues.userType,
                reason: initialValues && initialValues.reason,
                isActive: initialValues && initialValues.isActive == 0 ? 0 : 1
            })
        } else {

            initialize({ userType: 1, isActive: 1 })
        }
    }

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderTextAreaField = ({ input, label, type, meta: { touched, error }, children, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control as="textarea" rows="4" {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    render() {
        const { handleSubmit, initialValues, loading, submitting } = this.props;
        const id = initialValues && initialValues.id
        const { formatMessage } = this.props.intl;
        return (

            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col lg={8} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field
                                                    name="reason"
                                                    type="text"
                                                    placeholder={formatMessage(messages.reason)}
                                                    component={this.renderTextAreaField}
                                                    label={formatMessage(messages.reason)}
                                                    labelClass={s.labelText}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <Form.Group className={s.formGroup}>
                                                    <div>
                                                        <label>{formatMessage(messages.cancelledBy)}</label>
                                                        <Field name="userType" className={cx(s.formControlSelect, s.formControlInput)} component="select">
                                                            {/* <option value={1}>{formatMessage(messages.userCancel)}</option> */}
                                                            <option value={2}>{formatMessage(messages.driverCancel)}</option>
                                                            <option value={3}>{formatMessage(messages.driverUnableToDeliver)}</option>
                                                            <option value={4}>{formatMessage(messages.storeDecline)}</option>
                                                        </Field>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={12}>
                                                <Form.Group className={s.formGroup}>
                                                    <div>
                                                        <label>{formatMessage(messages.status)}</label>
                                                        <Field name="isActive" className={cx(s.formControlSelect, s.formControlInput)} component="select">
                                                            <option value={1}>{formatMessage(messages.active)}</option>
                                                            <option value={'0'}>{formatMessage(messages.inactive)}</option>
                                                        </Field>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textLeftRTL', 'borderBottomRTL')}>
                                        <Form.Group className={s.noMargin}>
                                            <div className={s.displayInlineBlock}>
                                                <Loader
                                                    type={"button"}
                                                    label={formatMessage(messages.submitButton)}
                                                    show={loading}
                                                    buttonType={'submit'}
                                                    className={cx(s.button, s.btnPrimary)}
                                                    disabled={submitting || loading}
                                                    isSuffix={true}
                                                />
                                            </div>
                                            <Link to={"/siteadmin/cancel-reasons"} className={cx(s.backBtn, s.btnSecondary, s.linkBtnInline, 'backBtnRTL')} >{formatMessage(messages.goBack)}</Link>
                                        </Form.Group>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

AddCancelReasonForm = reduxForm({
    form: 'AddCancelReasonForm',
    onSubmit: submit,
    validate
})(AddCancelReasonForm);

const mapState = (state) => ({
    loading: state.loader.AddCancellation
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AddCancelReasonForm)));