import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { flowRight as compose } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';

import UserDetails from './UserDetails';
import MessageItem from './MessageItem';
import Link from '../../Link/Link';

import DefaultIcon from '../../../../public/Icons/defaultIcon.svg';

import s from './ViewChatDetails.css';
import bt from '../../commonStyle.css';

class ViewChatDetails extends Component {
  static propTypes = {
    from: PropTypes.string.isRequired,
    threadItems: PropTypes.shape({
      getAllThreadItems: PropTypes.shape({
        threadItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            threadId: PropTypes.number.isRequired,
            isRead: PropTypes.bool.isRequired,
            authorId: PropTypes.string.isRequired,
            receiverId: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
          }),
        ),
        userDetails: PropTypes.shape({
          id: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          profile: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            picture: PropTypes.string.isRequired,
          }),
        }),
        deliveryPartnerDetails: PropTypes.shape({
          id: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          profile: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            picture: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
  };

  render() {
    const {
      threadItems: { getAllThreadItems },
      loadMore,
      from,
    } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div
        className={cx(
          s.pagecontentWrapper,
          s.widthInner,
          bt.space5,
          'bgBlackTwo',
        )}
      >
        <div className={cx(s.ViewChatFlexContainer)}>
          <h1 className={cx(s.titleTextAdmin, s.noMargin)}>
            {formatMessage(messages.chatMessage)}
          </h1>
          <Link to={`/siteadmin/${from}`} className={s.btnSecondary}>
            <FormattedMessage {...messages.goBack} />
          </Link>
        </div>
        <div>
          <UserDetails
            userDetails={getAllThreadItems?.userDetails}
            deliveryPartnerDetails={getAllThreadItems?.deliveryPartnerDetails}
          />
        </div>
        {getAllThreadItems?.threadItems?.map((item, index) => (
          <div key={index}>
            <MessageItem
              avatarImage={DefaultIcon}
              text={item.message}
              chatPadding={
                getAllThreadItems.userDetails &&
                  item.authorId == getAllThreadItems.userDetails.id
                  ? 'left'
                  : 'right'
              }
              userImage={
                getAllThreadItems.userDetails &&
                  item.authorId == getAllThreadItems.userDetails.id
                  ? getAllThreadItems?.userDetails?.profile.picture
                  : getAllThreadItems?.deliveryPartnerDetails?.profile.picture
              }
            />
          </div>
        ))}
        {getAllThreadItems?.threadItems?.length > 0 &&
          getAllThreadItems.threadItems.length < getAllThreadItems.count && (
            <div className={s.textCenter}>
              <a
                href="javascript:void(0)"
                onClick={() => loadMore()}
                className={cx(bt.button, bt.btnPrimary)}
              >
                <FormattedMessage {...messages.loadMoreLabel} />
              </a>
            </div>
          )}
      </div>
    );
  }
}
export default compose(injectIntl, withStyles(s, bt))(ViewChatDetails);
