import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Button,
} from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './OrderAccept.css';
import Loader from '../../Common/Loader';
import cx from 'classnames';
import updateOrderStatus from '../../../actions/shop/orders/updateOrderStatus';
import { getPendingOrders } from '../../../actions/shop/orders/getPendingOrders';

import messages from '../../../locale/messages';
import { api, toneUploadDir } from '../../../config';

//image
import tick from '../../../../public/Icons/tickTimer.svg';
import close from '../../../../public/Icons/delineIcon.svg';

class OrderAccept extends React.Component {
	static propTypes = {
		formatMessage: PropTypes.func
	}

	constructor(props) {
		super(props);
		this.state = {
			play: false,
			loadingState: []
		}
	}
	togglePlay = () => {
		const audioEl = document.getElementById("audio-element");
		this.setState({ play: !this.state.play }, () => {
			this.state.play ? audioEl.play() : audioEl.pause();
		});
	}

	componentDidMount() {
		this.togglePlay();
	}

	async handleStatusChange(id, bookingStatus) {
		const { updateOrderStatus } = this.props;
		this.setState({
			loadingState: {
				...this.state.loadingState,
				[id]: true
			}
		});
		if (bookingStatus === 'approved') {
			await updateOrderStatus(id, bookingStatus, 1, null, null, true);
		} else if (bookingStatus === 'declined') {
			await updateOrderStatus(id, bookingStatus, 1, null, null, true);
		}
		this.setState({
			loadingState: {
				...this.state.loadingState,
				[id]: false
			}
		});
	}

	render() {
		const { orderLoading, data, requestTone, getPendingOrders, orderAcceptInterval } = this.props;
		const { loadingState } = this.state;
		const { formatMessage } = this.props.intl;

		return (
			<Loader
				show={orderLoading}
				type={"page"}
			>
				<div>
					<audio id="audio-element">
						<source src={api.apiEndpoint + toneUploadDir + requestTone}></source>
					</audio>
					{
						!orderLoading && data && data.length > 1 && data.map((item, index) => {
							let timeDiff = parseInt(orderAcceptInterval) - parseInt(item.second_difference);
							if (timeDiff > 0) {
								return (
									<div key={index}>
										<div className={s.grid}>
											<div>
												<CountdownCircleTimer
													isPlaying
													duration={parseInt(orderAcceptInterval)}
													initialRemainingTime={timeDiff}
													colors={['#018E4D']}
													size={85}
													strokeWidth={4}
													onComplete={() => {
														getPendingOrders();
													}}
												>
													{({ remainingTime }) => {
														return (
															<span className={s.circleTextCss}>{remainingTime}</span>
														)
													}}
												</CountdownCircleTimer>
											</div>
											<div className='textRightRTL'>
												<div className={s.userName}>{item.userName}</div>
												<div className={s.orderIdCss}>{formatMessage(messages.orderId)}: #{item.orderId}</div>
												<div className={s.itemCss}>
													{formatMessage(messages.numberOfOrder)}: {item.orderItemsCount} {' '}
													<a href={`/store/order-details/upcoming/${item.id}`} target="_blank" className={s.linkCss}>
														(<FormattedMessage {...messages.viewDetails} />)
													</a>
												</div>
											</div>
											<div>
												<Button className={cx(s.button, s.btnPrimary, s.secondaryBtn, s.btnWidth)} disabled={loadingState[item.id]}
													onClick={() => this.handleStatusChange(item.id, "declined")}>
													<img src={close} /> {formatMessage(messages.rejectedLabel)}
												</Button>
												<Button className={cx(s.button, s.btnPrimary, s.btnWidth)} disabled={loadingState[item.id]}
													onClick={() => this.handleStatusChange(item.id, "approved")}>
													<img src={tick} /> {formatMessage(messages.acceptedLabel)}
												</Button>

											</div>
										</div>
									</div>
								)
							}
						})
					}

					{
						!orderLoading && data && data.length <= 1 && data.map((item, index) => {
							let timeDiff = parseInt(orderAcceptInterval) - parseInt(item.second_difference);
							if (timeDiff > 0) {
								return (
									<div key={index}>
										<div className={s.timerSection}>
											<CountdownCircleTimer
												isPlaying
												duration={parseInt(orderAcceptInterval)}
												initialRemainingTime={timeDiff}
												colors={['#018E4D']}
												size={115}
												strokeWidth={8}
												onComplete={() => {
													getPendingOrders();
												}}
											>
												{({ remainingTime }) => {
													return (
														<span className={s.circleText}>{remainingTime}</span>
													)
												}}
											</CountdownCircleTimer>
											<div className={s.name}>{item.userName}</div>
											<div className={s.orderId}>{formatMessage(messages.orderId)}: #{item.orderId}</div>
										</div>

										<div className={s.detailsCss}>
											<div className={s.orderItemCss}>
												{formatMessage(messages.numberOfOrder)}: {item.orderItemsCount} {' '}
												<a href={`/store/order-details/upcoming/${item.id}`} target="_blank" className={s.linkCss}>
													(<FormattedMessage {...messages.viewDetails} />)
												</a>
											</div>
											<Button className={cx(s.button, s.btnPrimary, s.secondaryBtn, 'timerButtonRTL')} disabled={loadingState[item.id]}
												onClick={() => this.handleStatusChange(item.id, 'declined')}>
												<img src={close} /> {formatMessage(messages.rejectedLabel)}
											</Button>
											<Button className={cx(s.button, s.btnPrimary)} disabled={loadingState[item.id]}
												onClick={() => this.handleStatusChange(item.id, 'approved')}>
												<img src={tick} /> {formatMessage(messages.acceptedLabel)}
											</Button>

										</div>
									</div>
								)
							}
						})
					}
				</div>
			</Loader>
		)
	}
}

const mapState = (state) => ({
	orderLoading: state.orderData.orderLoading,
	data: state.orderData.data,
	count: state.orderData.count,
	requestTone: state.siteSettings?.data?.requestTimeTone,
	orderAcceptInterval: state.siteSettings?.data?.orderAcceptInterval,
});

const mapDispatch = {
	updateOrderStatus,
	getPendingOrders
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(OrderAccept)));