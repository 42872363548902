import React, { Component } from 'react';
import { connect } from 'react-redux';
import { api } from '../../../config';
import withStyles from 'isomorphic-style-loader/withStyles';
import Col from 'react-bootstrap/Col';
import cx from 'classnames';
import s from './HomeItem.css';

export class HomeItem extends Component {
    render() {
        const { categoryName, categoryImage } = this.props;

        return (

            <div>
                <Col xs={12} sm={12} md={12} lg={12} className={cx(s.sliderBox, s.tabPaddingTop)}>
                    <div className={s.sliderBg} />
                    <div>
                        <img
                            src={api.apiEndpoint + "/images/category/" + categoryImage}
                        />
                    </div>
                    <div className={s.sliderBottom}>
                        <label>{categoryName}</label>
                    </div>
                </Col>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(HomeItem)) 
