// import React from 'react';
import React from "react";
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './StoreNavigation.css';
import cx from 'classnames';
import { connect } from 'react-redux';
import HeaderAfterLogin from "../HeaderAfterLogin/HeaderAfterLogin";
import HeaderBeforeLogin from "../HeaderBeforeLogin/HeaderBeforeLogin";
import SocketContext from '../../../SocketContext';
class StoreNavigation extends React.Component {

  render() {
    const { isStore } = this.props
    return (
      <SocketContext.Consumer>
        {
          socket => (
            <div className={cx(s.navigationLink, s.navigationLinkres)}>
              {isStore ? <HeaderAfterLogin socket={socket} /> : <HeaderBeforeLogin />}
            </div>
          )
        }
      </SocketContext.Consumer>

    );
  }
}
const mapStateToProps = (state) => ({
  isStore: state.runtime.isStoreAuthenticated
});

const mapDispatchToProps = {};

export default withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(StoreNavigation));