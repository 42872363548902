import showToaster from '../../helpers/showToaster';
import { uploadProfileImage as mutation } from '../../lib/graphql';
import { removeImage } from '../../helpers/removeImage';
import {
  PROFILE_IMAGE_UPLOAD_ERROR,
  PROFILE_IMAGE_UPLOAD_START,
  PROFILE_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index'

const uploadProfileImage = (id, fileName, oldFile) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: PROFILE_IMAGE_UPLOAD_START
      });

      const { data } = await client.mutate({
        mutation,
        variables: {
          userId: id,
          picture: fileName
        }
      });

      let url = "/deleteProfileImage";

      if (oldFile !== undefined) {
        removeImage({ url, fileName: oldFile })
      };

      if (data?.uploadProfileImage?.status == "200") {
        dispatch({
          type: PROFILE_IMAGE_UPLOAD_SUCCESS
        });
        showToaster({ messageId: 'uploadProfileImage', toasterType: 'success' });
      } else {
        dispatch({
          type: PROFILE_IMAGE_UPLOAD_ERROR
        });
      }

    } catch (err) {
      dispatch({
        type: PROFILE_IMAGE_UPLOAD_ERROR
      });
    }
  }
};

export default uploadProfileImage;