import showToaster from '../../../helpers/showToaster';
import history from '../../../history';
import { getAllSubMenu as query, updateItemMutation } from '../../../lib/graphql';

const addItem = (values) => {
	return async (dispatch, getState, { client }) => {

		try {
			let successMessage;

			const { data: { updateItem } } = await client.mutate({
				mutation: updateItemMutation,
				variables: values,
				refetchQueries: [{
					query,
					variables: {
						currentPage: 1,
						searchList: '',
						menuId: values?.menuId
					}
				}]
			});

			successMessage = values?.id ? 'updateItem' : 'addItem';

			if (updateItem?.status == 200) {
				showToaster({ messageId: successMessage, toasterType: 'success' })
				history.push('/store/menu/' + values.menuId + '/item');
			} else {
				showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: updateItem?.errorMessage })
			}

		} catch (error) {
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
		}
	}
};

export default addItem;