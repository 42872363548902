import { toastr } from 'react-redux-toastr';
import { addPromoCode } from '../../../../actions/siteadmin/PromoCode/addPromoCode';

async function submit(values, dispatch) {
    if (!values.promoCodeImage) {
        return toastr.error('Error!', 'Promo code image is required!');
    }
    await dispatch(addPromoCode(values));
}

export default submit;