import showToaster from '../../../helpers/showToaster';
import { getPaymentMethods as query, addPayoutMutation } from '../../../lib/graphql';
import { processStripePayment } from '../../../core/payment/stripe/processStripePayment';
import { setLoaderComplete, setLoaderStart } from '../../loader/loader';
import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../../constants';
import history from '../../../history';

export const addPayout = ({
  methodId,
  payEmail,
  address1,
  address2,
  city,
  state,
  country,
  zipcode,
  currency,
  firstname,
  lastname,
  accountNumber,
  routingNumber,
  ssn4Digits,
  businessType,
  bankCode,
  branchCode,
  bankName,
  branchName,
  accountOwnerName,
  transitNumber,
  institutionNumber,
  bsbNumber,
  sortCode,
  accountToken,
  personToken
}) => {

  return async (dispatch, getState, { client }) => {

    try {
      let userDetails, bankDetails;
      dispatch(setLoaderStart('addPayoutForm'));
      dispatch({
        type: ADD_PAYOUT_START
      });

      const { data } = await client.query({
        query,
        fetchPolicy: 'network-only'
      });

      if (data?.getPaymentMethods?.length > 0) {
        if (!data?.getPaymentMethods[0]?.isEnable && !data?.getPaymentMethods?.results[1]?.isEnable) {
          dispatch({
            type: ADD_PAYOUT_ERROR,
          });
          dispatch(setLoaderComplete('addPayoutForm'));
          showToaster({ messageId: 'addPayoutError', toasterType: 'warning' })
          return;
        }
      };

      if (methodId == 5) { // PayPal
        const { data } = await client.mutate({
          mutation: addPayoutMutation,
          variables: {
            methodId,
            payEmail,
            address1,
            address2,
            city,
            state,
            country,
            zipcode,
            currency,
            isVerified: true
          }
        });

        dispatch({
          type: data?.addPayout?.status == 200 ? ADD_PAYOUT_SUCCESS : ADD_PAYOUT_ERROR,
        });
        
        history.push('/store/payout');
      } else {
        userDetails = {
          payEmail
        };

        bankDetails = {
          payEmail,
          address1,
          address2,
          city,
          state,
          country,
          zipcode,
          currency,
          firstname,
          lastname,
          accountNumber,
          routingNumber,
          ssn4Digits,
          businessType,
          bankCode,
          branchCode,
          bankName,
          branchName,
          accountOwnerName,
          transitNumber,
          institutionNumber,
          bsbNumber,
          sortCode,
          accountToken,
          personToken
        };

        const { status, errorMessage, accountId } = await processStripePayment(
          'addPayout',
          userDetails,
          bankDetails
        );

        dispatch({
          type: status == 200 && accountId ? ADD_PAYOUT_SUCCESS : ADD_PAYOUT_ERROR
        });
        dispatch(setLoaderComplete('addPayoutForm'));

        status != 200 && showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: errorMessage })
      }

    } catch (error) {
      dispatch({
        type: ADD_PAYOUT_ERROR,
        payload: error
      });
      dispatch(setLoaderComplete('addPayoutForm'));
    }
  };
};