exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._24bVU {\n\tbackground-color: #fff !important;\n\tborder-radius: 4px;\n\t-webkit-box-shadow: 0 1px 10px 0px #484848;\n\t        box-shadow: 0 1px 10px 0px #484848;\n\tborder-radius: 20px;\n}\n._2HCPs {\n\tpadding: 10px;\n}\n._1_Y_C {\n\tcolor: #484848 !important;\n\tfont-size: 16px;\n\tfont-weight: 400;\n\tline-height: normal;\n}\n.eyqzO {\n\tcolor: #484848 !important;\n\tfont-style: italic;\n\tfont-weight: bold;\n\ttext-decoration: underline;\n}\n._2ZQlr {\n\tfont-weight: 700;\n\tmargin-top: 0px;\n\tfloat: right;\n\tz-index: 9;\n}\n._3gZae {\n\tposition: fixed !important;\n\tright: 0px;\n\tbottom: 0px;\n\tleft: 0px;\n\tz-index: 1005;\n}\n._14VGa {\n\twidth: 100%;\n\tdisplay: table;\n}\n._2tskJ {\n\twidth: 100%;\n\tdisplay: table-row;\n}\n.v-BH- {\n\tdisplay: table-cell;\n\twidth: 90%;\n\tvertical-align: middle;\n}\n.U7u2r {\n\twidth: 10%;\n\tdisplay: table-cell;\n\tvertical-align: middle;\n}\n._2SB38 {\n\tpadding: 15px;\n}\n@media screen and (max-width: 1030px) {\n\t._1_Y_C {\n\t\tfont-size: 14px;\n\t}\n}\n@media screen and (max-width: 767px) {\n\t.v-BH- {\n\t\tdisplay: block;\n\t\twidth: 100%;\n\t}\n\t.U7u2r {\n\t\twidth: 100%;\n\t\tdisplay: block;\n\t\tmargin-top: 12px;\n\t}\n\t._2ZQlr {\n\t\tmargin-top: 8px;\n\t}\n\t._2SB38 {\n\t\tpadding: 15px;\n\t}\n}\n@media screen and (max-width: 480px) {\n\t._24bVU {\n\t\tbackground-color: #fff !important;\n\t\theight: auto;\n\t}\n\t._2ZQlr {\n\t\tfloat: right;\n\t\tmargin-top: 0px;\n\t\tmargin-bottom: 10px;\n\t\tfont-size: 15px;\n\t\tfont-weight: 300;\n\t}\n\t._1_Y_C {\n\t\tfont-size: 15px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"cookiesBackground": "_24bVU",
	"root": "_2HCPs",
	"labelText": "_1_Y_C",
	"linkStyle": "eyqzO",
	"button": "_2ZQlr",
	"fixedPosition": "_3gZae",
	"displayTable": "_14VGa",
	"displayRow": "_2tskJ",
	"displayText": "v-BH-",
	"displayBtn": "U7u2r",
	"displayTableSection": "_2SB38"
};