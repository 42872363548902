import messages from '../../../../locale/messages';
import { inputTextLimit, inputDescLimit } from '../../../../helpers/fieldRestriction';

const validate = values => {
    const errors = {};

    if (!values.bannerTitle1) {
        errors.bannerTitle1 = messages.required;
    } else if (values.bannerTitle1.trim() == "") {
        errors.bannerTitle1 = messages.required;
    } else if (values.bannerTitle1.length < 2) {
        errors.bannerTitle1 = messages.required
    } else if (values.bannerTitle1.length > inputTextLimit) {
        errors.bannerTitle1 = messages.textAreaError
    }

    if (!values.bannerTitle2) {
        errors.bannerTitle2 = messages.required;
    } else if (values.bannerTitle2.trim() == "") {
        errors.bannerTitle2 = messages.required;
    } else if (values.bannerTitle2.length < 2) {
        errors.bannerTitle2 = messages.required
    } else if (values.bannerTitle2.length > inputTextLimit) {
        errors.bannerTitle2 = messages.textAreaError
    }

    if (!values.bannerContent1) {
        errors.bannerContent1 = messages.required;
    } else if (values.bannerContent1.trim() == "") {
        errors.bannerContent1 = messages.required;
    } else if (values.bannerContent1.length < 2) {
        errors.bannerContent1 = messages.required
    } else if (values.bannerContent1.length > inputDescLimit) {
        errors.bannerContent1 = messages.textAreaError1
    }


    return errors;
};

export default validate;