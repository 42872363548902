import gql from "graphql-tag";

export const getAllCommonSettingsData = gql`
{
  getAllCommonSettingsData {
    status
    errorMessage
    count
    results {
      id
      typeName
      typeLabel
      fieldType
      isEnable
      step
    }
  }
}
`;

export const getCurrenciesData = gql`
    {
      getCurrencies {
        id
        symbol
        isEnable
        isPayment
        isBaseCurrency
        status
      }
    }
`;

export const getCurrencyRatesData = gql`{
    getCurrencyRates {
        base
        rates
    }
  }
`;

export const sendForgotPasswordLink = gql`
mutation sendForgotPasswordLink($email: String!) {
    sendForgotPasswordLink (email: $email) {
        status
        errorMessage
    }
}
`;

export const updateForgotPassword = gql`
mutation updateForgotPassword($email: String!, $token: String!, $newPassword: String!) {
  updateForgotPassword (email:$email, token: $token, newPassword: $newPassword) {
    status
    errorMessage
  }
}
`;

export const verifyOtpCode = gql`
mutation verifyOtpCode($otp: Int!) {
    verifyOtpCode(otp: $otp){
        status
        errorMessage
    }
}
`;

export const updatePhoneNumber = gql`
mutation updatePhoneNumber($phoneNumber: String!, $phoneDialCode: String!) {
    updatePhoneNumber(phoneNumber: $phoneNumber, phoneDialCode: $phoneDialCode) {
        status
        errorMessage
    }
}
`;

export const updateConfigSettingsMutation = gql`mutation updateConfigSettings(
  $smtpHost: String,
  $smtpPort: String,
  $smptEmail: String,
  $smtpSender: String,
  $smtpSenderEmail: String,
  $smtpPassWord: String,
  $twilioAccountSid: String,
  $twilioAuthToken: String,
  $twilioPhone: String,
  $subtractTime: String,
  $maxDistance: String,
  $maxUploadSize: String,
  $stripePublishableKey: String,
  $fcmPushNotificationKey: String,
  $fcmApiKey: String,
  $fcmAuthDomain: String,
  $fcmProjectId: String,
  $fcmStorageBucket: String,
  $fcmMessagingSenderId: String,
  $fcmAppId: String,
  $fcmMeasurementId: String,
  $fcmVapidKey: String,
  $enableFirebase: String,
  $payPalClientId: String,
  $payPalSecret: String,
  $payPalHost: String,
) {
  updateConfigSettings(
      smtpHost: $smtpHost,
      smtpPort: $smtpPort,
      smptEmail: $smptEmail,
      smtpSender: $smtpSender,
      smtpSenderEmail: $smtpSenderEmail,
      smtpPassWord: $smtpPassWord,
      twilioAccountSid: $twilioAccountSid,
      twilioAuthToken: $twilioAuthToken,
      twilioPhone: $twilioPhone,
      maxUploadSize: $maxUploadSize,
      stripePublishableKey: $stripePublishableKey
      subtractTime: $subtractTime,
      maxDistance: $maxDistance,
      fcmPushNotificationKey: $fcmPushNotificationKey,
      fcmApiKey: $fcmApiKey,
      fcmAuthDomain: $fcmAuthDomain,
      fcmProjectId: $fcmProjectId,
      fcmStorageBucket: $fcmStorageBucket,
      fcmMessagingSenderId: $fcmMessagingSenderId,
      fcmAppId: $fcmAppId,
      fcmMeasurementId: $fcmMeasurementId,
      fcmVapidKey: $fcmVapidKey,
      enableFirebase: $enableFirebase,
      payPalClientId: $payPalClientId,
      payPalSecret: $payPalSecret,
      payPalHost: $payPalHost,
  ){
      status
      errorMessage
  }
}`;

export const getCurrencyRatesQuery = gql`{
  getCurrencyRates {
      base
      rates
  }
}
`;

export const getCurrency = gql`
query getCurrency($currentPage: Int, $searchList: String){
  getCurrency(currentPage: $currentPage, searchList:$searchList){
    count
    currencyList{
        id
        symbol
        isEnable
        isPayment
        isBaseCurrency
    }
  }
}`;

export const updateCurrency = gql`
mutation updateCurrency ($id: Int, $isEnable: Boolean, $symbol: String){
  updateCurrency(id: $id, isEnable: $isEnable, symbol: $symbol){
        status
        errorMessage
    }
}
`;

export const setBaseCurrencyMutation = gql`
mutation setBaseCurrency($id: Int){
  setBaseCurrency(id: $id){
        status
    }
}
`;

export const updatePartnerHomepage = gql`
mutation updatePartnerHomepage (
    $bannerTitle1: String,
    $bannerTitle2: String,
    $bannerContent1: String,
    $bannerImage1: String,
    $sliderTitle1: String,
    $sliderContent1: String,
    $sliderImage1: String,
    $sliderTitle2: String,
    $sliderContent2: String,
    $sliderImage2: String,
    $sliderTitle3: String,
    $sliderContent3: String,
    $sliderImage3: String,
    $sliderTitle4: String,
    $sliderContent4: String,
    $sliderTitle5: String,
    $infoTitle1: String,
    $infoImage1: String,
    $infoTitle2: String,
    $infoImage2: String,
    $infoTitle3: String,
    $infoImage3: String,
    $infoTitle4: String,
    $infoContent4: String,
    $buttonName: String,
    $buttonLink: String,
    $sliderImage5: String
) {
  updatePartnerHomepage (
    bannerTitle1: $bannerTitle1,
    bannerTitle2: $bannerTitle2,
    bannerContent1: $bannerContent1,
    bannerImage1: $bannerImage1,
    sliderTitle1: $sliderTitle1,
    sliderContent1: $sliderContent1,
    sliderImage1: $sliderImage1,
    sliderTitle2: $sliderTitle2,
    sliderContent2: $sliderContent2,
    sliderImage2: $sliderImage2,
    sliderTitle3: $sliderTitle3,
    sliderContent3: $sliderContent3,
    sliderImage3: $sliderImage3,
    sliderTitle4: $sliderTitle4,
    sliderContent4: $sliderContent4,
    sliderTitle5: $sliderTitle5,
    infoTitle1: $infoTitle1,
    infoImage1: $infoImage1,
    infoTitle2: $infoTitle2,
    infoImage2: $infoImage2,
    infoTitle3: $infoTitle3,
    infoImage3: $infoImage3,
    infoTitle4: $infoTitle4,
    infoContent4: $infoContent4,
    buttonName: $buttonName,
    buttonLink: $buttonLink,
    sliderImage5: $sliderImage5
  ) {
    status
  }
}
`;

export const deletePromoCode = gql`
mutation( 
  $id: Int!
) {
  deletePromoCode(
      id: $id
  ) {
      status
      errorMessage
  }
}`;

export const getPromoCodes = gql`query($currentPage: Int) {
  getPromoCodes(currentPage: $currentPage) {
      status
      errorMessage
      count
      data {
        id
        title
        description
        code
        type
        promoValue
        currency
        expiryDate
        isEnable
        createdAt
        updatedAt
      }
  }
}`;

export const updatePromoCode = gql`
mutation( 
  $id: Int,
  $title: String!, 
  $description: String!, 
  $code: String!, 
  $type: Int!,
  $promoValue: Float!, 
  $currency: String,
  $expiryDate: String,
  $isEnable: String,
  $promoCodeImage: String,
) {
  updatePromoCode(
      id: $id,
      title: $title,
      description: $description,
      code: $code,
      type: $type,
      promoValue: $promoValue,
      currency: $currency,
      expiryDate: $expiryDate,
      isEnable: $isEnable,
      promoCodeImage: $promoCodeImage
  ) {
      status
      errorMessage
  }
}`;

export const updateTempImages = gql`
mutation updateTempImages(
  $tableName: String,
  $fieldName: String,
  $fileName: String
) {
updateTempImages(
  tableName: $tableName,
  fieldName: $fieldName,
  fileName: $fileName
){
  status
}
}`;

export const updateSiteSettingsMutation = gql`
mutation updateSiteSettings(
    $siteName: String,
    $siteTitle: String,
    $metaDescription: String, 
    $facebookLink: String,
    $twitterLink: String,
    $instagramLink: String,
    $metaKeyword: String,
    $homeLogo: String,
    $youtubeLink: String,
    $currency: String,
    $distanceUnits: String,
    $appForceUpdate: String,
    $userAndroidVersion: String,
    $userIosVersion: String,
    $driverAndroidVersion: String,
    $driverIosVersion: String,
    $preferredDelivery: String,
    $modifiers: String,
    $favicon: String,
    $contactNumber: String,
    $contactEmail: String,
    $contactSkype: String,
    $notificationInterval: String,
    $orderAcceptInterval:String,
    $requestTimeTone:String,
    $requestToneFile:String,
    $ogImage: String,
    ) {
        updateSiteSettings(
        siteName: $siteName
        siteTitle: $siteTitle
        metaDescription: $metaDescription
        facebookLink: $facebookLink
        twitterLink: $twitterLink
        instagramLink: $instagramLink
        metaKeyword: $metaKeyword
        homeLogo: $homeLogo
        youtubeLink: $youtubeLink
        currency: $currency
        distanceUnits: $distanceUnits
        appForceUpdate: $appForceUpdate
        userAndroidVersion: $userAndroidVersion
        userIosVersion: $userIosVersion
        driverAndroidVersion: $driverAndroidVersion
        driverIosVersion: $driverIosVersion
        preferredDelivery: $preferredDelivery,
        modifiers: $modifiers,
        favicon: $favicon,
        contactNumber: $contactNumber
        contactEmail: $contactEmail
        contactSkype: $contactSkype
        notificationInterval:  $notificationInterval,
        orderAcceptInterval:$orderAcceptInterval,
        requestTimeTone:$requestTimeTone,
        requestToneFile:$requestToneFile,
        ogImage: $ogImage
        ){
            status
            errorMessage
        }
    }
`;

export const updateTone = gql`
mutation updateTone {
    updateTone {
    status
    }
  }
`;

export const updateStaticPage = gql`
mutation updateStaticPage(
  $id: Int,
  $content: String,
  $metaTitle: String,
  $metaDescription: String,
  $pageBanner: String,
) {
  updateStaticPage(
    id: $id,
    content: $content,
    metaTitle: $metaTitle,
    metaDescription: $metaDescription,
    pageBanner: $pageBanner,
  ) {
      status
  }
}
`;

export const getEditStaticPage = gql`query getEditStaticPage($id: Int!) {
  getEditStaticPage (id: $id) {
      id
      pageName
      content
      metaTitle
      metaDescription
      pageBanner
      createdAt
  }
}`;

export const uploadInsuranceImage = gql`
mutation uploadInsuranceImage($userId: String, $vehicleInsurance: String) {
    uploadInsuranceImage(userId:$userId, vehicleInsurance:$vehicleInsurance) {
      status
    }
  }
`;

export const uploadLicenceBackImage = gql`
mutation uploadLicenceBackImage($userId:ID,$licenceBack:String){
    uploadLicenceBackImage(userId:$userId, licenceBack:$licenceBack) {
      status
    }
  }
`;

export const uploadLicenceFrontImage = gql`
mutation uploadLicenceFrontImage($userId:ID,$licenceFront:String){
    uploadLicenceFrontImage(userId:$userId, licenceFront:$licenceFront) {
      status
    }
  }
`;

export const uploadProfileImage = gql`
mutation uploadProfileImage($userId:ID,$picture:String){
    uploadProfileImage(userId:$userId, picture:$picture) {
      status
    }
  }
`;

export const uploadRcbookImage = gql`
mutation uploadRcbookImage($userId: String, $vehicleRC: String) {
    uploadRcbookImage(userId:$userId, vehicleRC:$vehicleRC) {
      status
    }
  }
`;

export const allowPaymentCurrencyMutation = gql`
mutation allowPaymentCurrency($id: Int,$isPayment: Int){
  allowPaymentCurrency(id: $id,isPayment: $isPayment){
        status
        errorMessage
    }
}
`;

export const getStripeSettings = gql`
  {
    getStripeSettings {
      errorMessage
      status
      name
      value
    }
  }
`; 