import messages from '../../../../locale/messages';
import { isEuropeCountry } from '../../../../helpers/europeCountryHelper';
const validate = values => {

    const errors = {};

    if (!values.email) {
        errors.email = messages.required;
    } else if (!/^(([^<>()[\]\\.,;.!-#$_&%*+/=?:{|}~-\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
        errors.email = messages.emailInvalid;
    }

    if (!values.country) {
        errors.country = messages.required;
    }

    if (!values.city) {
        errors.city = messages.required;
    }

    if (!values.state) {
        errors.state = messages.required;
    }

    if (!values.zipcode) {
        errors.zipcode = messages.required;
    }

    if (!values.firstname || (values.firstname && values.firstname.toString().trim() == '')) {
        errors.firstname = messages.required;
    }

    if (values.businessType && values.businessType === "individual"
        && (!values.lastname || (values.lastname && values.lastname.toString().trim() == ''))) {
        errors.lastname = messages.required;
    }

    if (!values.routingNumber) {
        errors.routingNumber = messages.required;
    }

    if (!values.accountNumber) {
        errors.accountNumber = messages.required;
    } else if (values.accountNumber && values.accountNumber.toString().trim() === '') {
        errors.accountNumber = isEuropeCountry(values.country) ? messages.ibanNumberInvalid : (values.country == 'MX' ? messages.clabeNumberInvalid : messages.accountNumberInvalid);
    }

    if (!values.confirmAccountNumber) {
        errors.confirmAccountNumber = messages.required;
    } else if (values.confirmAccountNumber && values.confirmAccountNumber.toString().trim() === '') {
        errors.confirmAccountNumber = isEuropeCountry(values.country) ? messages.confirmIbanNumberInvalid : (values.country == 'MX' ? messages.confirmClabeNumberInvalid : messages.confirmAccountNumberInvalid);
    }

    if (values.confirmAccountNumber && values.accountNumber) {
        if (values.confirmAccountNumber !== values.accountNumber) {
            errors.confirmAccountNumber = isEuropeCountry(values.country) ? messages.confirmIbanNumberMismatch : (values.country == 'MX' ? messages.confirmClabeNumberMismatch : messages.confirmAccountNumberMismatch);
        }
    }

    if (values.country && values.country === 'US' && values.businessType && values.businessType === "individual") {

        if (!values.ssn4Digits) {
            errors.ssn4Digits = messages.required;
        } else if (values.ssn4Digits) {
            if (isNaN(values.ssn4Digits) || (parseInt(values.ssn4Digits, 10) < 1)) {
                errors.ssn4Digits = messages.ssn4DigitsInvalid;
            }
        }
    }

    if (!values.business_type) {
        errors.business_type = messages.required;
    }

    if (!values.business_type) {
        errors.business_type = messages.required;
    }

    if (!values.bsbNumber) {
        errors.bsbNumber = messages.required;
    }

    if (!values.transitNumber) {
        errors.transitNumber = messages.required;
    }

    if (!values.institutionNumber) {
        errors.institutionNumber = messages.required;
    }

    if (!values.bankCode) {
        errors.bankCode = messages.required;
    }

    if (!values.accountOwnerName) {
        errors.accountOwnerName = messages.required;
    }

    if (!values.sortCode) {
        errors.sortCode = messages.required;
    }

    if (!values.branchCode) {
        errors.branchCode = messages.required;
    }

    if (!values.bankName) {
        errors.bankName = messages.required;
    }

    if (!values.branchName) {
        errors.branchName = messages.required;
    }

    return errors;
};

export default validate;