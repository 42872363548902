import updateShopProfile from '../../../actions/shop/editProfile/updateShopProfile';
import { toastr } from 'react-redux-toastr';
import showToaster from '../../../helpers/showToaster';

async function submit(values, dispatch) {

    let profilePicError;

    values.picture ? profilePicError = false : profilePicError = true;

    profilePicError ? showToaster({ messageId: 'uploadStoreImg', toasterType: 'error' }) : await dispatch(updateShopProfile(values))

}

export default submit;