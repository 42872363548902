import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ConfigSettings.css';
import ConfigSettingsForm from '../../../components/SiteAdmin/ConfigSettingsForm';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getSiteSettings from './getSiteSettings.graphql'
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class ConfigSettings extends Component {

  render() {
    const { getSiteSettings: { loading, getSiteSettings, refetch } } = this.props;
    let siteSettingsCollection = {}

    if (!loading) {
      getSiteSettings && getSiteSettings.map((item) => {
        siteSettingsCollection[item.name] = item.value
      })
    }

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          {!loading && <div className={cx(s.container, 'paddingBottomLg')}>
            <div className={s.heading}>
              <FormattedMessage {...messages.manageSiteConfig} />
            </div>
            <div className={cx(s.paddignLeftRight, s.spaceTop5)}>
              <ConfigSettingsForm refetch={refetch} getSiteSettings={getSiteSettings} initialValues={siteSettingsCollection} />
            </div>
          </div>}
        </div>
      </Loader>
    )
  }
}


export default compose(
  withStyles(s),
  graphql(getSiteSettings, {
    name: 'getSiteSettings',
    options: {
      ssr: true,
      fetchPolicy: 'network-only'
    }
  })
)(ConfigSettings)
