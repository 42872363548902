/*

Supported languages

English - en
Spanish - es
French - fr
Russian - ru
Japanese - ja	
Indonesian - id

*/

export function bookingStatus(status, lang) {
    let statusLabelSpec = {}, requestLang = lang || 'en-US';

    console.log('langlanglang', lang)

    if (status === 'pending') {
        statusLabelSpec = {
            'en-US': 'PENDING',
            'es': 'PENDIENTE',
            'fr-FR': 'EN ATTENTE',
            'ru-RU': 'ОЖИДАНИЕ',
            'ja-JP': '保留中',
            'id-ID': 'TERTUNDA',
            'ar': 'ريثما'
        };
    }

    if (status === 'cancelledByUser') {
        statusLabelSpec = {
            'en-US': 'CANCELLED',
            'es': 'CANCELADO',
            'fr-FR': 'ANNULÉ',
            'ru-RU': 'ОТМЕНЕН',
            'ja-JP': 'キャンセル',
            'id-ID': 'DIBATALKAN',
            'ar': 'ألغيت',
        };
    }

    if (status === 'approved') {
        statusLabelSpec = {
            'en-US': 'CONFIRMED',
            'es': 'CONFIRMADO',
            'fr-FR': 'CONFIRMÉ',
            'ru-RU': 'ПОДТВЕРЖДЕНО',
            'ja-JP': '確認済み',
            'id-ID': 'DIKONFIRMASI',
            'ar': 'تم تأكيد'
        };
    }

    if (status === 'declined') {
        statusLabelSpec = {
            'en-US': 'DECLINED',
            'es': 'DECLINADO',
            'fr-FR': 'DIMINUÉ',
            'ru-RU': 'ОТКЛОНЕН',
            'ja-JP': '辞退',
            'id-ID': 'DITOLAK',
            'ar': 'رفض'
        };
    }

    if (status === 'expired') {
        statusLabelSpec = {
            'en-US': 'DECLINED',
            'es': 'DECLINADO',
            'fr-FR': 'DIMINUÉ',
            'ru-RU': 'ОТКЛОНЕН',
            'ja-JP': '辞退',
            'id-ID': 'DITOLAK',
            'ar': 'رفض'
            
        };
    }

    if (status === 'cancelledByPartner') {
        statusLabelSpec = {
            'en-US': 'CANCELLED BY STORE',
            'es': 'CANCELADO POR TIENDA',
            'fr-FR': 'ANNULÉ PAR MAGASIN',
            'ru-RU': 'ОТМЕНЕНО МАГАЗИНОМ',
            'ja-JP': 'ストアによってキャンセルされました',
            'id-ID': 'DIBATALKAN OLEH TOKO',
            'ar': 'تم الإلغاء عن طريق المتجر'
        };
    }

    if (status === 'readyForDelivery') {
        statusLabelSpec = {
            'en-US': 'READY FOR PICKUP',
            'es': 'LISTO PARA RECOGER',
            'fr-FR': 'PRÊT POUR LE RAMASSAGE',
            'ru-RU': 'ГОТОВ',
            'ja-JP': 'ピックアップの準備ができて',
            'id-ID': 'SIAP UNTUK PICKUP',
            'ar': 'جاهز للإستلام'
        };
    }

    if (status === 'deliveryNotFound') {
        statusLabelSpec = {
            'en-US': 'DELIVERY FAILED',
            'es': 'ENTREGA FALLIDA',
            'fr-FR': 'LIVRAISON ÉCHOUÉE',
            'ru-RU': 'ДОСТАВКА НЕ УДАЛАСЬ',
            'ja-JP': '配信に失敗しました',
            'id-ID': 'PENGIRIMAN GAGAL',
            'ar': 'فشل التسليم'
        };
    }

    if (status === 'deliveryApproved') {
        statusLabelSpec = {
            'en-US': 'DELIVERY CONFIRMED',
            'es': 'ENTREGA CONFIRMADA',
            'fr-FR': 'LIVRAISON CONFIRMÉ',
            'ru-RU': 'ДОСТАВКА ПОДТВЕРЖДЕНА',
            'ja-JP': '配達が確認されました',
            'id-ID': 'PENGIRIMAN DIKONFIRMASI',
            'ar': 'تم تأكيد التسليم'
        };
    }

    if (status === 'deliveryDeclined') {
        statusLabelSpec = {
            'en-US': 'DELIVERY DECLINED',
            'es': 'ENTREGA RECHAZADA',
            'fr-FR': 'LIVRAISON REFUSÉE',
            'ru-RU': 'ДОСТАВКА ОТКЛОНЕНА',
            'ja-JP': '配達が拒否されました',
            'id-ID': 'PENGIRIMAN DITOLAK',
            'ar': 'التسليم مرفوض'
        };
    }

    if (status === 'cancelledByDelivery') {
        statusLabelSpec = {
            'en-US': 'DELIVERY CANCELLED',
            'es': 'ENTREGA CANCELADA',
            'fr-FR': 'LIVRAISON ANNULÉ',
            'ru-RU': 'ДОСТАВКА ОТМЕНЕНА',
            'ja-JP': '配達がキャンセルされました',
            'id-ID': 'PENGIRIMAN DIBATALKAN',
            'ar': 'تم إلغاء التسليم'
        };
    }

    if (status === 'deliveryConfirmed') {
        statusLabelSpec = {
            'en-US': 'DELIVERY PICKED UP',
            'es': 'ENTREGA RECOGIDO',
            'fr-FR': 'LIVRAISON RAMASSÉS',
            'ru-RU': 'ДОСТАВКА ЗАБРАНА',
            'ja-JP': '配達物を受け取りました',
            'id-ID': 'PENGIRIMAN DIAMBIL',
            'ar': 'انتقاء التسليم'

        };

    }

    if (status === 'startDelivery') {
        statusLabelSpec = {
            'en-US': 'DELIVERY IN PROGRESS',
            'es': 'ENTREGA EN CURSO',
            'fr-FR': 'LIVRAISON EN COURS',
            'ru-RU': 'ДОСТАВКА В ПРОЦЕССЕ',
            'ja-JP': '進行中の配信',
            'id-ID': 'PENGIRIMAN DALAM PROGRESS',
            'ar': 'التسليم قيد التقدم'
        };
    }

    if (status === 'completed') {
        statusLabelSpec = {
            'en-US': 'DELIVERED',
            'es': 'ENTREGADO',
            'fr-FR': 'LIVRÉ',
            'ru-RU': 'ДОСТАВЛЕН',
            'ja-JP': '配信',
            'id-ID': 'TERSIRAT',
            'ar': 'تم التوصيل'
        };
    }

    if (status === 'failed') {
        statusLabelSpec = {
            'en-US': 'UNABLE TO DELIVERED',
            'es': 'INCAPAZ DE ENTREGA',
            'fr-FR': 'IMPOSSIBLE DE LIVRER',
            'ru-RU': 'НЕВОЗМОЖНО ДОСТАВИТЬ',
            'ja-JP': '配信できません',
            'id-ID': 'TIDAK DAPAT DIBERIKAN',
            'ar': 'غير قادر على التسليم'
        };
    }

    if (status === 'pickUpRequest') {
        statusLabelSpec = {
            'en-US': 'DELIVERY DONE',
            'es': 'ENTREGA HECHO',
            'fr-FR': 'LIVRAISON EFFECTUÉE',
            'ru-RU': 'ДОСТАВКА ГОТОВА',
            'ja-JA': '配達完了',
            'id-ID': 'PENGIRIMAN SELESAI',
            'ar': 'تم التسليم'
        };
    }

    return status ? (statusLabelSpec[requestLang] ? statusLabelSpec[requestLang] : statusLabelSpec['en-US']) : '';
}