import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Container } from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
// Styles
import s from './TransactionList.css';
import rs from '../../../storeCommon.css';
import messages from '../../../../locale/messages';
// Components
import Link from '../../../Link';
import CurrencyConverter from '../../../CurrencyConverter';
// Images
import viewIcon from '../../../../../public/StoreIcon/view-icon.svg';
// Helpers
import { bookingStatus } from '../../../../helpers/bookingStatus';


class TransactionList extends Component {
    static defaultProps = {
        tabType: 'completed'
    };

    render() {
        const { formatMessage, locale } = this.props.intl;
        const { data, data: { results, count }, siteCurrency, tabType } = this.props;

        return (
            <div>
                <div className={s.container}>
                    <Container fluid>
                        <Row>
                            <Col md={12} lg={12} sm={12} xs={12} className={rs.noPadding}>
                                <div className={s.panelBody}>
                                    <div className={cx(s.tableCss, 'tableCss', 'storetableSticky', 'storeTableCss', 'storetableOrder', 'NewResponsiveTable')}>
                                        <table className={cx('table', s.noBorder)}>
                                            <thead>
                                                <tr className={cx(s.rowBorder, s.sectionTitleLight, s.textTruncate)}>
                                                    <th className={s.noBorder} scope="col"><FormattedMessage {...messages.orderId} /></th>
                                                    <th className={s.noBorder} scope="col"><FormattedMessage {...messages.details} /></th>
                                                    <th className={s.noBorder} scope="col"><FormattedMessage {...messages.subTotalLabel} /></th>
                                                    <th className={s.noBorder} scope="col"><FormattedMessage {...messages.shopFee} /></th>
                                                    <th className={s.noBorder} scope="col"><FormattedMessage {...messages.earnings} /></th>
                                                    {
                                                        tabType === 'completed' && <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutStatus} /></th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data && results && count > 0 && results.map((item, key) => {
                                                        return (
                                                            <tr className={cx(s.rowBorder, s.sectionTitleLight)} key={key}>
                                                                <td data-label={formatMessage(messages.orderId)}>{'#' + item.orderId}</td>
                                                                <td className={'text-left'} data-label={formatMessage(messages.details)}>
                                                                    <p className={rs.space1}><b><FormattedMessage {...messages.userName} />:</b> {item.userDetails && item.userDetails.firstName}</p>
                                                                    <p className={rs.space1}><b><FormattedMessage {...messages.orderStatus} />:</b> {bookingStatus(item.bookingStatus, locale)}</p>
                                                                    <p className={rs.space1}><b><FormattedMessage {...messages.date} />:</b> {moment(item.createdAt).format('MM/DD/YYYY')}</p>
                                                                    <p className={rs.space1}><b><FormattedMessage {...messages.totalOrderedItems} />:</b> {item.orderItemsCount}</p>
                                                                    {
                                                                        item.deliveryPartnerDetails && <p className={rs.space1}>
                                                                            <b><FormattedMessage {...messages.driverName} />:</b> {item.deliveryPartnerDetails.firstName}
                                                                        </p>
                                                                    }
                                                                    <Link to={`/store/order-details/${tabType}/${item.id}`} className={s.linkColor}>
                                                                        <span>
                                                                            <img src={viewIcon} className={cx(s.iconWidth, 'editIconRTL')} />
                                                                        </span>
                                                                        <span>
                                                                            <FormattedMessage {...messages.view} />
                                                                        </span>
                                                                    </Link>
                                                                </td>
                                                                <td data-label={formatMessage(messages.subTotalLabel)}>
                                                                    <CurrencyConverter
                                                                        from={item.currency}
                                                                        to={siteCurrency}
                                                                        amount={item.subTotal}
                                                                    />
                                                                </td>
                                                                <td data-label={formatMessage(messages.shopFee)}>
                                                                    <div>
                                                                        <span>-</span>{' '}
                                                                        <span>
                                                                            <CurrencyConverter
                                                                                from={item.currency}
                                                                                to={siteCurrency}
                                                                                amount={item.shopServiceFare}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td data-label={formatMessage(messages.earnings)}>
                                                                    <CurrencyConverter
                                                                        from={item.currency}
                                                                        to={siteCurrency}
                                                                        amount={item.shopTotalFare}
                                                                    />
                                                                </td >
                                                                {
                                                                    tabType === 'completed' && <td data-label={formatMessage(messages.payoutStatus)}>
                                                                        {item.isPayoutPaid ? formatMessage(messages.paidLabel) : formatMessage(messages.notPaidLabel)}
                                                                    </td>
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {
                                                    data && results && count === 0 && <tr className={cx(s.rowBorder, s.sectionTitleLight)}>
                                                        <td colspan={tabType === 'completed' ? 7 : 6} className={s.alignCenter}>
                                                            <FormattedMessage {...messages.noTransactionFound} />
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
};

const mapDispatch = {};

const mapState = (state) => ({
    userId: state.user.id,
    siteCurrency: state.siteSettings?.data?.currency
});

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(TransactionList)));