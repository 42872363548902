import histroy from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { addCategory as mutation } from '../../../lib/graphql';

export const addCategory = (categoryName, isActive, categoryImage, description) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch(setLoaderStart('AddCategory'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          categoryName,
          isActive: (isActive === true || 'true') ? 1 : 0,
          categoryImage,
          description
        }
      });

      dispatch(setLoaderComplete('AddCategory'));

      if (data?.addCategory?.status === 200) {
        showToaster({ messageId: 'addCategory', toasterType: 'success' });
        histroy.push('/siteadmin/category');
      } else if (data?.addCategory?.status !== 200) {
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.addCategory?.errorMessage });
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error' });
      }

    } catch (error) {
      return false;
    }
  }
};