import messages from '../../../locale/messages';
import { inputDescLimit } from '../../../helpers/fieldRestriction';

const validate = values => {

  const errors = {}

  if (!values.reason) {
    errors.reason = messages.reasonRequired;
  } else if (values.reason.trim() == "") {
    errors.reason = messages.reasonRequired
  } else if (values.reason.length > inputDescLimit) {
    errors.reason = messages.textAreaError1
  }

  return errors
}

export default validate
