import addItem from '../../../../../actions/shop/menu/addItem';
import showToaster from '../../../../../helpers/showToaster';

async function submit(values, dispatch) {
     let modifiers = JSON.stringify(values.modifiers), tax = values?.tax, variables;

     if (!values?.tax || (values?.tax?.toString().trim() === '')) {
          tax = null;
     }

     variables = Object.assign({}, values, {
          modifiers
     },
          {
               tax
          });

     values?.image ? await dispatch(addItem(variables)) : showToaster({ messageId: 'addItemError', toasterType: 'error' })
}

export default submit;