import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import messages from '../../../locale/messages';
import { change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './PreparationTime.css';
import rs from '../../storeCommon.css';
import {
    Col,
    Row,
    Form,
    Button,
    FormGroup,
    Container
} from 'react-bootstrap';
import validate from './validate';
import submit from './submit';
import Loader from '../../Common/Loader/Loader';
import PreparationTimeList from './PreparationTimeList';

class PreparationTime extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultPreparationTime: 5
        }

        this.handleDefaultPreparationTime = this.handleDefaultPreparationTime.bind(this);
    }

    static defaultProps = {
        loading: false
    }

    componentWillMount() {
        const { initialValues, initialize, change } = this.props;
        const { defaultPreparationTime } = this.state;

        let preparationTime = initialValues && initialValues.shopEstimatedTime ? initialValues.shopEstimatedTime : defaultPreparationTime;
        // if(initialValues && initialValues.preparationTime && initialValues.preparationTime.length > 0) {
        //     let preparationTimeList = initialValues && initialValues.preparationTime
        //     change('preparationTime', preparationTimeList)
        // }

        this.setState({ defaultPreparationTime: preparationTime })
    }

    handleDefaultPreparationTime(action) {
        const { defaultPreparationTime } = this.state;
        const { change } = this.props;

        let preparationTime = action == 'add' ? defaultPreparationTime + 5 : defaultPreparationTime - 5;

        if (preparationTime <= 55 && preparationTime >= 5) {
            this.setState({ defaultPreparationTime: preparationTime })
            change('shopEstimatedTime', preparationTime)
        } else {

        }
    };

    render() {
        const { loading, handleSubmit, submitting, error } = this.props;
        const { formatMessage } = this.props.intl;
        const { defaultPreparationTime } = this.state;

        return (
            <div className={'mainContainer'}>
                <div className={s.container}>
                    <Container fluid>
                        <Row>
                            <Col md={12} lg={12} sm={12} xs={12}>
                                <div>
                                    <h1 className={cx(cx(rs.storeTitleText, 'textRightRTL'))}>
                                        <FormattedMessage {...messages.preparationTime} />
                                    </h1>
                                </div>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Row>
                                        <Col lg={12} md={12} sm={12} xs={12} className={cx(s.spaceTop1)}>
                                            <Form.Group className={cx(s.formGroup, 'textRightRTL')}>
                                                <Form.Label>{formatMessage(messages.shopEstimatedTime)}</Form.Label>
                                                <div>
                                                    <div className={cx(s.addSection, s.displayInlineSection)}>
                                                        <div className={cx(s.displayInlineSection, s.vtrTop)}>
                                                            <Button onClick={() => this.handleDefaultPreparationTime('minus')} className={rs.preparDecreseBtn}>-</Button>
                                                        </div>
                                                        <div className={cx(s.displayInlineSection, s.addmainSection)}>
                                                            {defaultPreparationTime} {formatMessage(messages.minutes)}
                                                        </div>
                                                        <div className={cx(s.displayInlineSection, s.vtrTop)}>
                                                            <Button onClick={() => this.handleDefaultPreparationTime('add')} className={rs.preparAddBtn}>+</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} md={12} sm={12} xs={12} className={cx(s.spaceTop1)}>
                                            <Form.Group className={s.formGroup}>
                                                <PreparationTimeList fieldName={'preparationTime'} formName={'PreparationTime'} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} md={12} sm={12} xs={12} className={cx(s.spaceTop1)}>
                                            <Form.Group className={s.formGroup}>
                                                <div className={cx(rs.alignRightText, 'textLeftRTL')}>
                                                    <Loader
                                                        type={"button"}
                                                        label={formatMessage(messages.update)}
                                                        show={loading}
                                                        buttonType={'submit'}
                                                        className={cx(rs.button, rs.btnPrimary)}
                                                        disabled={submitting || loading}
                                                        isSuffix={true}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {/* {error && <span className={s.errorMessage}>{formatMessage(error)}</span>} */}
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

PreparationTime = reduxForm({
    form: 'PreparationTime',
    onSubmit: submit,
    validate
})(PreparationTime);

const selector = formValueSelector('PreparationTime');

const mapState = (state) => ({

});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(PreparationTime)));