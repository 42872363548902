import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import Container from 'react-bootstrap/Container';

import Loader from '../../../Common/Loader/Loader';
import ImageUploadComponent from '../../../ImageUploadComponent/ImageUploadComponent';

import submit from './submit';
import validate from './validate';
import messages from '../../../../locale/messages';
import { api, partnerHomepageUploadDir } from '../../../../config';



import s from './SliderForm.css';

export class SliderForm extends Component {

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<div>
				<FormGroup className={s.space3}>
					<div>
						<label className={s.labelText} >{label}</label>
					</div>
					<div>
						<FormControl
							{...input}
							className={className}
							placeholder={label}
							as="textarea"
							rows="4"
						>
							{children}
						</FormControl>
						{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
					</div>
				</FormGroup>
			</div>
		);
	}

	render() {
		const { handleSubmit, sliderImage1, sliderImage2, sliderImage3, sliderImage5, loading, submitting } = this.props;
		const { sliderImage1Loader, sliderImage2Loader, sliderImage3Loader } = this.props;
		const { formatMessage } = this.props.intl;
		return (

			<div className={cx(s.space5, s.spaceTop5)}>
				<Container fluid>
					<Row>
						<Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
							<div className={s.blackCenterSection}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<Col lg={12} md={12} sm={12} xs={12}>
										<h1 className={cx(s.titleTextAdmin, 'textRightRTL')}>{formatMessage(messages.sliderSection)}</h1>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Row>
											<Col lg={4} md={12} sm={12} xs={12} className={s.space1}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<div>
															<ImageUploadComponent
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																loaderName={'sliderImage1Loader'}
																postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
																loader={sliderImage1Loader}
																fieldName={'sliderImage1'}
																formName={'SliderForm'}
																imageSrc={sliderImage1 ? api.apiEndpoint + partnerHomepageUploadDir + 'medium_' + sliderImage1 : null}
																inputContainer={'.dzInputContainerStoreImage'}
																inputContainerClass={'dzInputContainerStoreImage'}
																label={formatMessage(messages.sliderImage1Label)}
															/>
														</div>
													</div>
												</Form.Group>
												<Form.Group className={s.space3}>
													<div>
														<Field name="sliderTitle1" type="text" component={this.renderField} label={formatMessage(messages.sliderTitle1Label)} />
													</div>
												</Form.Group>
												<Form.Group className={s.space3}>
													<div>
														<Field name="sliderContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.sliderDescription1Label)} />
													</div>
												</Form.Group>
											</Col>
											<Col lg={4} md={12} sm={12} xs={12} className={s.space1}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<div>
															<ImageUploadComponent
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																loaderName={'sliderImage2Loader'}
																postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
																loader={sliderImage2Loader}
																fieldName={'sliderImage2'}
																formName={'SliderForm'}
																imageSrc={sliderImage2 ? api.apiEndpoint + partnerHomepageUploadDir + 'medium_' + sliderImage2 : null}
																inputContainer={'.dzInputContainerStoreImage'}
																inputContainerClass={'dzInputContainerStoreImage'}
																label={formatMessage(messages.sliderImage2Label)}
															/>
														</div>
													</div>
												</Form.Group>
												<Form.Group className={s.space3}>
													<Field name="sliderTitle2" type="text" component={this.renderField} label={formatMessage(messages.sliderTitle2Label)} />
												</Form.Group>
												<Form.Group className={s.space3}>
													<Field name="sliderContent2" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.sliderDescription2Label)} />
												</Form.Group>
											</Col>
											<Col lg={4} md={12} sm={12} xs={12} className={s.space1}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<div>
															<ImageUploadComponent
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																loaderName={'sliderImage3Loader'}
																postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
																loader={sliderImage3Loader}
																fieldName={'sliderImage3'}
																formName={'SliderForm'}
																imageSrc={sliderImage3 ? api.apiEndpoint + partnerHomepageUploadDir + 'medium_' + sliderImage3 : null}
																inputContainer={'.dzInputContainerStoreImage'}
																inputContainerClass={'dzInputContainerStoreImage'}
																label={formatMessage(messages.sliderImage3Label)}
															/>
														</div>
													</div>
												</Form.Group>
												<Form.Group className={s.space3}>
													<Field name="sliderTitle3" type="text" component={this.renderField} label={formatMessage(messages.sliderTitle3Label)} />
												</Form.Group>
												<Form.Group className={s.space3}>
													<Field name="sliderContent3" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.sliderDescription3Label)} />
												</Form.Group>
											</Col>
											<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textLeftRTL', 'borderBottomRTL')}>
												<Form.Group className={s.noMargin}>
													<div className={s.displayInlineBlock}>
														<Loader
															type={"button"}
															label={formatMessage(messages.submitButton)}
															show={loading}
															buttonType={'submit'}
															className={cx(s.button, s.btnPrimary)}
															disabled={submitting || loading}
															isSuffix={true}
														/>
													</div>
												</Form.Group>
											</Col>
										</Row>
									</Col>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

SliderForm = reduxForm({
	form: 'SliderForm',
	onSubmit: submit,
	validate
})(SliderForm);

const selector = formValueSelector('SliderForm')

const mapState = (state) => ({
	sliderImage1: selector(state, 'sliderImage1'),
	sliderImage2: selector(state, 'sliderImage2'),
	sliderImage3: selector(state, 'sliderImage3'),
	sliderImage5: selector(state, 'sliderImage5'),
	loading: state.loader.UpdatePartnerHomepage,
	sliderImage1Loader: state.loader.sliderImage1Loader,
	sliderImage2Loader: state.loader.sliderImage2Loader,
	sliderImage3Loader: state.loader.sliderImage3Loader
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(SliderForm)));