import {
	SHOP_MENU_UPDATE_START,
	SHOP_MENU_UPDATE_SUCCESS,
	SHOP_MENU_UPDATE_ERROR
} from '../../../constants/index';
import showToaster from '../../../helpers/showToaster';
import { loadAccount } from '../userAccount/userAccount';
import { getAllSubMenu as query, updateItemStatus as mutation } from '../../../lib/graphql';

export default function updateItemStatus(id, status, currentPage, menuId) {
	return async (dispatch, getState, { client }) => {

		try {
			dispatch({
				type: SHOP_MENU_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});

			const { data } = await client.mutate({
				mutation,
				variables: {
					id,
					status: status == 'false' ? 0 : 1
				},
				refetchQueries: [{
					query,
					variables: {
						currentPage,
						menuId
					}
				}]
			});

			if (data?.updateItemStatus?.status === 200) {
				dispatch({
					type: SHOP_MENU_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'updateItemStatus', toasterType: 'success' });
				dispatch(loadAccount());
			} else {
				dispatch({
					type: SHOP_MENU_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateItemStatus?.errorMessage });
			}

		} catch (error) {
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
			dispatch({
				type: SHOP_MENU_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};
