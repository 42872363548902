import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
	Row,
	Col,
	Form,
	InputGroup,
	Button
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ModifierGroupItemForm.css';
import rs from '../../../../components/storeCommon.css';
import messages from '../../../../locale/messages';
import { Field, FieldArray, formValueSelector, change, reduxForm } from 'redux-form';
import closeIcon from '../../../../../public/StoreIcon/DeleteIcon.png';
import Link from '../../../Link/Link';
import ConfirmationPopup from '../ConfirmationPopup';
import getBookedModifierItem from '../../../../actions/shop/modifier/getBookedModifierItem';


class ModifierGroupItemForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			itemLength: 0,
			showPopUp: ""
		}
		this.handleModifierItem = this.handleModifierItem.bind(this);
		this.handleDeleteModifierItem = this.handleDeleteModifierItem.bind(this);
		this.handlePopUp = this.handlePopUp.bind(this);
	}

	async handleModifierItem(fields, index, itemId) {
		const { getBookedModifierItem, change } = this.props;
		if (itemId) {
			let { data } = await getBookedModifierItem(itemId);
			if (data && data.length > 0) {
				this.handlePopUp(index, true);
			} else {
				fields.remove(index);
			}
		} else {
			fields.remove(index);
		}
	};

	async handleDeleteModifierItem(fields, index) {
		this.handlePopUp(index, false);
		fields.remove(index);
	};

	async handlePopUp(showPopUp, isOpen) {
		const { change, modifierItems } = this.props;
		this.setState(prevState => ({
			showPopUp: prevState.showPopUp !== showPopUp ? showPopUp : ""
		}));
		modifierItems && modifierItems.length > 0 && modifierItems.map((item, index) => (
			change(`modifierItems[${index}].showPopUp`, false)
		));
		change(`modifierItems[${showPopUp}].showPopUp`, isOpen);
	};

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxLength }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} maxLength={maxLength} />
				{touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	renderPrice = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div className={'retaurantInputFormAddon'}>
				<Form.Group>
					<div>
						<label className={cx(rs.storeLabelText, rs.space1, 'textRightRTL')}>{label}</label>
					</div>
					<InputGroup>
						<InputGroup.Append>
							<InputGroup.Text>$</InputGroup.Text>
						</InputGroup.Append>
						<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} maxLength={10} />
					</InputGroup>
					{touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
				</Form.Group>
			</div>
		)
	}

	renderMultiField = ({ fields }) => {
		const { modifierItems } = this.props;
		const { formatMessage } = this.props.intl;
		const { showPopUp } = this.state;

		const withDotParser = (number) => number ? number.replace(/[^\d\.]/g, '') : '';
		this.setState({ itemLength: fields.length });

		return (
			<div>
				{fields.map((item, index) => (
					<div key={index} className={s.bgMobile}>
						<Row className={cx(s.positionRelative)}>
							<Col lg={5} md={5} sm={5} xs={12}>
								<Field
									name={`[${item}].modifierItemName`}
									type="text"
									placeholder={formatMessage(messages.modifierItemName)}
									component={this.renderField}
									labelClass={s.labelText}
									label={formatMessage(messages.modifierItemName)}
									fieldClass={rs.formControlInputStore}
									maxLength={150}
								/>
							</Col>
							<Col lg={5} md={5} sm={5} xs={12}>
								<Field
									name={`[${item}].modifierItemPrice`}
									type="text"
									placeholder={formatMessage(messages.price)}
									component={this.renderPrice}
									labelClass={s.labelText}
									fieldClass={rs.formControlInputStore}
									label={formatMessage(messages.priceOptional)}
									parse={withDotParser}
								/>
							</Col>
							{fields && fields.length > 1 && <Col lg={2} md={2} sm={2} xs={4} className={cx(s.disPlayFlex, s.positionMb)}>
								<a href="#deletePopup" className='d-block d-md-none'>
									<Button variant="primary" className={s.removeBtnSection} onClick={(e) => this.handleModifierItem(fields, index, modifierItems[index].id)}>
										<img src={closeIcon} />
									</Button>
								</a>
								<Button variant="primary" className={cx(s.removeBtnSection, 'd-none d-xl-block')} onClick={(e) => this.handleModifierItem(fields, index, modifierItems[index].id)}>
									<img src={closeIcon} />
								</Button>

							</Col>}
						</Row>
						<div id='deletePopup'>
							{showPopUp === index && <div className={s.deletePopup}>
								<div className={s.customPopup}><ConfirmationPopup key={index}
									body={formatMessage(messages.deleteConfirmationModifierItem)}
									popupButtonLeftName={formatMessage(messages.cancelButton)}
									popupButtonRightName={formatMessage(messages.deleteAction)}
									popupButtonLeftFunction={() => this.handlePopUp(index, false)}
									popupButtonRightFunction={() => this.handleDeleteModifierItem(fields, index)}
								/></div></div>}
						</div>
					</div>
				))}
				<div className={cx(s.marginTop, 'textRightRTL')}>
					<Link className={s.addItems} onClick={() => fields.push({
						modifierItemName: null,
						modifierItemPrice: null
					})}>
						{formatMessage(messages.addItems)}
					</Link>
				</div>
			</div>
		)
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { itemLength } = this.state;
		return (
			<div>
				<div className={cx(s.itemCount, 'textRightRTL')}>
					<span className={s.subHeading}>{formatMessage(messages.item)}</span>{' '}
					<span className={s.subHeading}>({itemLength})</span>
				</div>
				<div>
					<FieldArray
						name="modifierItems"
						rerenderOnEveryChange={true}
						component={this.renderMultiField}
					/>
				</div>
			</div>
		);
	}
};

ModifierGroupItemForm = reduxForm({
	form: 'ModifierGroupForm',
})(ModifierGroupItemForm);


const selector = formValueSelector('ModifierGroupForm');

const mapState = (state) => ({
	modifierItems: selector(state, 'modifierItems')
});

const mapDispatch = {
	getBookedModifierItem,
	change
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(ModifierGroupItemForm)));
