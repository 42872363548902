import React, { Component } from 'react'
import EditDriverForm from '../../../components/SiteAdmin/EditDriverForm/EditDriverForm';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getDriver from './getDriver.graphql';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditDriver.css';
import cx from 'classnames';
import Loader from '../../../components/Common/Loader/Loader';
import gql from 'graphql-tag';

export class EditDriver extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
    };

    static defaultProps = {
        data: {
            loading: true
        }
    }
    render() {
        const { data: { loading, getDriver }, data, id, deliveryVehicles: { getAllDeliveryVehicle } } = this.props;
        let initialValues = {};

        if (!loading && getDriver) {
            initialValues = {
                id: getDriver.id,
                firstName: getDriver.profile.firstName,
                lastName: getDriver.profile.lastName,
                email: getDriver.email,
                phoneDialCode: getDriver.phoneDialCode,
                phoneNumber: getDriver.phoneNumber,
                userStatus: getDriver.userStatus,
                isBan: getDriver.isBan,
                country: getDriver.profile.country,
                phoneCountryCode: getDriver.phoneCountryCode,
                picture: getDriver.profile.picture,
                licenceFront: getDriver.profile.licenceFront,
                licenceBack: getDriver.profile.licenceBack,
                rcBookImage: getDriver.vehicleDetails && getDriver.vehicleDetails.vehicleRC,
                insuranceImage: getDriver.vehicleDetails && getDriver.vehicleDetails.vehicleInsurance,
                vehicleName: getDriver.vehicleDetails && getDriver.vehicleDetails.vehicleName,
                vehicleNumber: getDriver.vehicleDetails && getDriver.vehicleDetails.vehicleNumber,
                vehicleType: getDriver.vehicleDetails && getDriver.vehicleDetails.vehicleType
            }
        }
        return (
            <div>
                <Loader type={"page"} show={loading}>
                    <div className={s.root}>
                        <div className={cx(s.container, 'paddingBottomLg')}>
                            {!loading && <EditDriverForm getDriver={getDriver} initialValues={initialValues} vehicleTypes={getAllDeliveryVehicle} />}
                        </div>
                    </div>
                </Loader>
            </div>
        )
    }
}




export default compose(
    withStyles(s),
    graphql(getDriver, {
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only',
            ssr: false
        })
}),
graphql(gql`query{
    getAllDeliveryVehicle{
      results {
        id
        vehicleName
        isActive
      }
    }
  }`,{name: 'deliveryVehicles',
      options: {
          fetchPolicy: 'network-only',
          ssr: false
      }
  })

)(EditDriver)
