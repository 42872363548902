import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { updateShopMutation as mutation } from '../../../lib/graphql';
import {
    SHOP_UPDATE_SUCCESS,
    SHOP_UPDATE_START,
    SHOP_UPDATE_ERROR
} from '../../../constants/index';

const updateShop = (values) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_UPDATE_START
            });

            dispatch(setLoaderStart('UpdateStore'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id: values?.id,
                    shopName: values?.shopName,
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    email: values?.email,
                    phoneDialCode: values?.phoneDialCode,
                    phoneNumber: values?.phoneNumber,
                    phoneCountryCode: values?.phoneCountryCode,
                    address: values?.address,
                    lat: values?.lat,
                    lng: values?.lng,
                    city: values?.city,
                    state: values?.state,
                    zipcode: values?.zipcode,
                    country: values?.country,
                    categoryType: values?.categoryType,
                    description: values?.description,
                    priceRangeId: values?.priceRange,
                    picture: values?.picture,
                    preferredCurrency: values?.preferredCurrency,
                    shopEstimatedTime: values?.shopEstimatedTime,
                    password: values?.password,
                    isBan: values?.isBan,
                    userStatus: values?.userStatus,
                    isTakeAway: values?.isTakeAway,
                    isDoorDelivery: values?.isDoorDelivery,
                }
            });

            if (data?.updateShop?.status == "200") {
                dispatch({
                    type: SHOP_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('UpdateStore'));
                showToaster({ messageId: 'updateShop', toasterType: 'success' });
            } else {
                dispatch({
                    type: SHOP_UPDATE_ERROR
                });
                await dispatch(setLoaderComplete('UpdateStore'));
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateShop?.errorMessage });
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            dispatch({
                type: SHOP_UPDATE_ERROR
            });
        }
    }
};

export default updateShop;