import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Store.css';
import StoresList from '../../../../components/SiteAdmin/ManageShop/StoresList'
import getAllShops from './getAllShops.graphql';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Loader from '../../../../components/Common/Loader/Loader';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';

class Store extends React.Component {

  static defaultProps = {
    storeDetails: {
      loading: true
    }
  }

  render() {
    const { title, storeDetails, storeDetails: { loading } } = this.props;
    
    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.heading}>
            <FormattedMessage {...messages.manageStoresHeading} />
            </div>
            <div className={s.paddingRoutesSection}>
            { !loading && <StoresList storeDetails={storeDetails} /> }
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllShops, {
    name: 'storeDetails',
    options: {
      ssr: true,
      fetchPolicy: 'network-only',
      variables: {
        currentPage: 1,
        searchList: ''
      }
    }
  })
)(Store);
