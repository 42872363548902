import showToaster from '../../../helpers/showToaster';
import { closeSmsVerificationModal } from './smsVerificationModaAction';
import { verifyOtpCode as mutation } from '../../../lib/graphql';

const checkOtp = (otp) => {
    return async (dispatch, getState, { client }) => {

        try {
            const { data } = await client.mutate({
                mutation,
                variables: {
                    otp
                }
            });

            if (data?.verifyOtpCode?.status === 200) {
                showToaster({ messageId: 'verifyOtpCode', toasterType: 'success' });
                await dispatch(closeSmsVerificationModal());
            } else {
                return false;
            }

        } catch (error) {
            return false;
        }
        return true;
    }
};

export default checkOtp;