import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EatsMobileApp.css';
import cx from 'classnames';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import { api } from '../../../config';

class EatsMobileApp extends React.Component {

    render() {

        const { data } = this.props;
        let homeSettings = {};

        data && data.homePageData.map((item) => {
            homeSettings[item.name] = item.value
        });

        return (
            <div className={cx(s.root, s.MobileBg)}>
                <Container className={cx(s.bannerLayoutContainer)} >
                    <Row className={s.displayFlex}>
                        <Col xl={6} md={6} sm={12} xs={12}>
                            <div className={s.signUp}>
                                <div className={s.phoneImgSection}>
                                    <img src={api.apiEndpoint + "/images/homepage/" + homeSettings.safetyGridImage3} className={s.phoneImg} />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={6} sm={12} xs={12} className={cx(s.spaceTop6)}>
                            <div className={cx(s.signUp, s.space5)}>
                                <h3>
                                    <span className={s.siteColor}>{homeSettings.safetyGridTitle1}</span>
                                </h3>
                                <p>
                                    {homeSettings.safetyGridContent1}
                                </p>
                            </div>
                            <div className={cx(s.spaceTop4)}>
                                <div className={cx(s.displayInlineBlock, s.paddingRight, 'paddingRightRTL')}>
                                    <a href={homeSettings.safetyGridLink1} target="_blank" className={s.btn}>
                                        <img src={api.apiEndpoint + "/images/homepage/" + homeSettings.safetyGridImage1} className={cx(s.appImg, 'appImgRTL')} />
                                        <span><FormattedMessage {...messages.androidLabel} /></span>
                                    </a>
                                </div>
                                <div className={cx(s.displayInlineBlock, s.paddingLeft, 'noPaddingMbRTL')}>
                                    <a href={homeSettings.safetyGridLink2} target="_blank" className={s.btn}>
                                        <img src={api.apiEndpoint + "/images/homepage/" + homeSettings.safetyGridImage2} className={cx(s.appImg, 'appImgRTL')} />
                                        <span><FormattedMessage {...messages.iphoneLabel} /></span>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withStyles(s)(EatsMobileApp);