import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import { api, profilePhotouploadDir } from '../../../config';

import defaultVehicleIcon from '../../../../public/Icons/defaultVehicleIcon.svg';

import s from './ViewChatDetails.css';
import bt from '../../commonStyle.css';

class MessageItem extends Component {
  static propTypes = {
    avatarImage: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    userImage: PropTypes.string.isRequired,
    chatPadding: PropTypes.string.isRequired,
  };

  render() {
    const { avatarImage, text, userImage, chatPadding } = this.props;
    return (
      <div
        className={cx(
          { [s.displayFlexSender]: chatPadding == 'left' },
          { [s.displayFlexReverse]: chatPadding == 'right' },
        )}
      >
        <div
          className={cx(
            { [s.senderBgScetion]: chatPadding == 'left' },
            { [s.receiverBgScetion]: chatPadding == 'right' },
            { receiverBgScetionRTL: chatPadding == 'right' },
            { senderBgScetion: chatPadding == 'left' },
          )}
        >
          <>
            {userImage ? (
              <>
                <img
                  src={`${api.apiEndpoint +
                    profilePhotouploadDir}small_${userImage}`}
                  className={s.viewChatImgContainer}
                />
                {chatPadding == 'right' && (
                  <span className={cx(s.viewChatReceiverMessageIcon, "viewChatReceiverMessageIconRtl")}>
                    <img
                      src={defaultVehicleIcon}
                    />
                  </span>
                )}
              </>
            ) : (
              <>
                {' '}
                <img src={avatarImage} className={s.viewChatImgContainer} />
                {chatPadding == 'right' && (
                  <span className={cx(s.viewChatReceiverMessageIcon, 'viewChatReceiverMessageIconRtl')}>
                    <img src={defaultVehicleIcon} />
                  </span>
                )}{' '}
              </>
            )}
          </>
        </div>
        <div
          className={cx(
            { [cx(s.senderRadius, 'senderRadiusRTL')]: chatPadding == 'left' },
            {
              [cx(s.receiverRadius, 'receiverRadiusRTL')]:
                chatPadding == 'right',
            },
            {
              [cx(s.receiverRadius, 'receiverRadiusRTL')]:
                chatPadding == 'right',
            },
          )}
        >
          {text}
        </div>
      </div>
    );
  }
}

export default compose(injectIntl, withStyles(s, bt))(MessageItem);
