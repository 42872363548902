import history from '../../history';
import showToaster from '../../helpers/showToaster';
import { getContentPageDetails, deleteContentPage, updateContentPageStatusMutation } from '../../lib/graphql';
import {
    ADMIN_DELETE_CONTENTPAGE_START,
    ADMIN_DELETE_CONTENTPAGE_SUCCESS,
    ADMIN_DELETE_CONTENTPAGE_ERROR
} from '../../constants';

const deleteContentPageDetails = (id) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: ADMIN_DELETE_CONTENTPAGE_START,
                data: id
            });

            const { data } = await client.mutate({
                mutation: deleteContentPage,
                variables: {
                    id
                },
                refetchQueries: [{ query: getContentPageDetails }]
            });

            if (data?.deleteContentPage?.status == "200") {
                dispatch({
                    type: ADMIN_DELETE_CONTENTPAGE_SUCCESS
                });
                showToaster({ messageId: 'commonDelete', toasterType: 'success' });
                history.push('/siteadmin/contentpage/manage');
            } else {
                showToaster({ messageId: 'commonFail', toasterType: 'error' });
            }

        } catch (error) {
            dispatch({
                type: ADMIN_DELETE_CONTENTPAGE_ERROR,
                payload: {
                    error
                }
            });
        }
    }
};

const updateContentPageStatus = (id, isEnable) => {
    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation: updateContentPageStatusMutation,
                variables: {
                    id,
                    isEnable
                },
                refetchQueries: [{ query: getContentPageDetails }]
            });

            if (data?.updateContentPageStatus?.status == "success") {
                showToaster({ messageId: 'updateContent', toasterType: 'success' });
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
        }
    }
};

export {
    deleteContentPageDetails, updateContentPageStatus
};