import showToaster from '../../../helpers/showToaster';
import { deleteCategory as mutation } from '../../../lib/graphql';
import {
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_START,
    CATEGORY_DELETE_ERROR
} from '../../../constants';

const deleteCategory = (id) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: CATEGORY_DELETE_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                }
            });

            if (data?.deleteCategory?.status === 200) {
                dispatch({
                    type: CATEGORY_DELETE_SUCCESS
                });
                showToaster({ messageId: 'deleteCategory', toasterType: 'success' });
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: CATEGORY_DELETE_ERROR
                });
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.deleteCategory?.errorMessage });
                return {
                    status: 400
                };
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            dispatch({
                type: CATEGORY_DELETE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};

export default deleteCategory;