import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';

import Link from '../../../Link';
import ImageUploadComponent from '../../../ImageUploadComponent/ImageUploadComponent';

import submit from './submit';
import validate from './validate';
import { formatURL } from '../../../../helpers/formatUrl';
import messages from '../../../../locale/messages';
import { api, contentPageUploadDir, siteUrl } from '../../../../config';

import s from './AddContentPageForm.css';

class AddContentPageForm extends React.Component {
    constructor(props) {
        super(props)
        if (typeof window !== 'undefined') {
            this.ReactQuill = require('react-quill');
        }
        this.state = { editorHtml: '' };
        this.handlePageTitle = this.handlePageTitle.bind(this);
    }

    static propTypes = {
        title: PropTypes.string.isRequired,
        initialValues: PropTypes.object,
    };

    async handlePageTitle(e) {
        const { change } = this.props;
        if (e.target.value) {
            await change('pageUrl', formatURL(e.target.value));
        } else {
            await change('pageUrl', '');
        }
    }

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        )
    }

    renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl {...input} placeholder={label} type={type} className={cx(className, s.formControlInput)} maxlength={maxlength} />
                        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }

    renderFormControlPageUrl = ({ input, label, placeholder, type, meta: { touched, error }, className }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={s.space3}>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <label className={s.labelText} >{label}</label>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <div className={cx('pageUrlInput', 'pageUrlRadius')}>
                        <InputGroup>
                            <InputGroup.Text>
                                <InputGroup.Append>
                                    <span>{siteUrl}/<FormattedMessage {...messages.page} />/</span>
                                </InputGroup.Append>
                            </InputGroup.Text>
                            <FormControl {...input} placeholder={placeholder} type={type} className={cx(className, 'pageUrlFormInput', s.formControlInput)} />
                        </InputGroup>
                        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
                    </div>
                </Col>
            </FormGroup>

        );
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }

    renderQuill = ({ input, label, type, meta: { touched, error }, className }) => {
        const ReactQuill = this.ReactQuill;
        const { formatMessage } = this.props.intl;

        let modules = {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link'],
                // ['link', 'image'],
            ],
            clipboard: {
                matchVisual: false,
            }
        };

        let formats = [
            'header', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link'
            // 'link', 'image'
        ];
        return (
            <div>
                <ReactQuill
                    {...input}
                    onChange={(newValue, delta, source) => {
                        if (source === 'user') {
                            input.onChange(newValue);
                        }
                    }}
                    onBlur={(range, source, quill) => {
                        if (quill.getHTML() == '<p><br></p>') {
                            input.onBlur('');
                        }
                        else {
                            input.onBlur(quill.getHTML());
                        }
                    }}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                />

                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </div>
        );
    }

    render() {
        const { handleSubmit, pageBannerLoader } = this.props;
        const { pageBanner } = this.props;
        const ReactQuill = this.ReactQuill;
        const { formatMessage } = this.props.intl;
        if (typeof window !== 'undefined' && ReactQuill) {

            return (
                <div className={cx(s.space5, s.spaceTop5)}>
                    <Container fluid>
                        <Row>
                            <Col lg={8} md={12} sm={12} xs={12} className={s.blackCenter}>
                                <div className={s.blackCenterSection}>
                                    <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                        <div>
                                            <Col lg={12} md={12} sm={12} xs={12} className={s.paddingTop}>
                                                <FormGroup className={s.space3}>
                                                    <div className={cx(s.profileImgSection)}>
                                                        <div>
                                                            <ImageUploadComponent
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                loaderName={'pageBannerLoader'}
                                                                postUrl={api.apiEndpoint + '/uploadContentPageBannerImage'}
                                                                loader={pageBannerLoader}
                                                                fieldName={'pageBanner'}
                                                                formName={'ContentPageForm'}
                                                                imageSrc={pageBanner ? api.apiEndpoint + contentPageUploadDir + 'large_' + pageBanner : null}
                                                                label={formatMessage(messages.pageImageBanner)}
                                                                inputContainer={'.dzInputContainer'}
                                                                inputContainerClass={'dzInputContainer'}
                                                            />
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </div>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <FormGroup className={s.space3}>
                                                <Field
                                                    name="metaTitle"
                                                    type="text"
                                                    placeholder={formatMessage(messages.metaTitle)}
                                                    component={this.renderFormControl}
                                                    label={formatMessage(messages.metaTitle)}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <FormGroup className={s.space3}>
                                                <Field
                                                    name="metaDescription"
                                                    type="text"
                                                    placeholder={formatMessage(messages.metaDescriptionText)}
                                                    component={this.renderFormControlTextArea}
                                                    label={formatMessage(messages.metaDescriptionText)}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <FormGroup className={s.space3}>
                                                <Field
                                                    name="pageTitle"
                                                    type="text"
                                                    component={this.renderFormControl}
                                                    label={formatMessage(messages.pageTitle)}
                                                    placeholder={formatMessage(messages.pageTitle)}
                                                    onChange={(event) => this.handlePageTitle(event)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
                                            <FormGroup className={s.space3}>
                                                <Field
                                                    name="pageUrl"
                                                    type="text"
                                                    component={this.renderFormControlPageUrl}
                                                    label={formatMessage(messages.pageUrl)}
                                                    placeholder={formatMessage(messages.pageUrl)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <FormGroup className={s.space3}>
                                                <div>
                                                    <label className={s.labelText} ><FormattedMessage {...messages.content} /></label>
                                                </div>
                                                <div xs={12} sm={9} md={9} lg={9}>
                                                    <Field name="content" component={this.renderQuill} />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, s.paddingBottom, 'textLeftRTL', 'borderBottomRTL')}>
                                            <Form.Group className={s.noMargin}>
                                                <Button
                                                    type="submit"
                                                    className={cx(s.button, s.btnPrimary)}
                                                >
                                                    <FormattedMessage {...messages.submitButton} />
                                                </Button>
                                                <Link to={'/siteadmin/contentpage/manage'} className={cx(s.backBtn, s.btnSecondary, s.linkBtnInline, 'backBtnRTL')} ><FormattedMessage {...messages.goBack} /></Link>
                                            </Form.Group>
                                        </Col>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        } else {
            return <div />;
        }
    }

}

AddContentPageForm = reduxForm({
    form: 'ContentPageForm',
    validate,
    onSubmit: submit,
})(AddContentPageForm);

const contentPageFormSelector = formValueSelector('ContentPageForm');

const mapState = (state) => ({
    pageTitle: contentPageFormSelector(state, 'pageTitle'),
    pageBanner: contentPageFormSelector(state, 'pageBanner'),
    pageBannerLoader: state.loader.pageBannerLoader
});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AddContentPageForm)));