import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import SignupForm from '../../SignupForm/SignupForm';

import { api, partnerHomepageUploadDir } from '../../../../config';
import s from './StoreBanner.css';

class StoreBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    scrollTop = () => {
        window.scrollTo({
            top: screen.height,
            behavior: 'smooth'
        })
    }

    render() {
        const { getCategory, homepage } = this.props

        return (
            <div className={cx(s.layout4Container)}>
                <div className={cx(s.bannerBackgroundImage)}
                    style={{ backgroundImage: `url(${api?.apiEndpoint + partnerHomepageUploadDir + homepage?.bannerImage1})` }} />
                <Container className={s.bannerLayoutContainer}>
                    <Row>
                        <div className={s.bannerDisplayGrid}>
                            <div className={s.searchFormContainer}>
                                <div className={cx(s.searchFormWrap, 'textRightRTL')}>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <h1>
                                            {homepage?.bannerTitle1} <span className={s.subTitle}>{homepage?.bannerTitle2}</span>
                                        </h1>
                                        <p>
                                            {homepage?.bannerContent1}
                                        </p>
                                    </Col>
                                </div>
                            </div>
                            <div className={s.formSection}>
                                <SignupForm getCategory={getCategory} />
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapState = (state) => ({});

const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(StoreBanner));