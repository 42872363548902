import gql from "graphql-tag";

export const getPaymentMethods = gql`query getPaymentMethods {
    getPaymentMethods {
      id
      name
      processedIn
      fees
      currency
      details
      isEnable
      paymentType
      createdAt
      updatedAt
      status
    }
  }`;

export const getPayouts = gql`
query getPayouts($currentAccountId: String, $country:String) {
    getPayouts(currentAccountId: $currentAccountId, country: $country) {
      status
      results{
        id
        methodId
        paymentMethod{
          id
          name
        }
        userId
        payEmail
        address1
        address2
        city
        state
        country
        zipcode
        currency
        default
        createdAt
        status
        last4Digits
        isVerified
        firstName
        lastName
      }
    }
  }
`;

export const removePayout = gql`
mutation removePayout($id: Int! ){
  removePayout(id: $id) {
      status
  }
}
`;

export const setDefaultPayout = gql`
mutation setDefaultPayout($id: Int!){
  setDefaultPayout(id: $id) {
      status
  }
}
`;

export const updatePayoutMutation = gql`
mutation updatePayout ($id: Int!, $requestStatus: Boolean!, $fieldName: String){
  updatePayout(id: $id, requestStatus: $requestStatus, fieldName: $fieldName){
      status
      errorMessage
    }
}`;

export const getPaymentMethodsQuery = gql` query {
	getAllPaymentMethods {
		results {
			id
			paymentName
			isEnable
			paymentType
		}
		status
		errorMessage
	}
}`;

export const updatePaymentMutation = gql`mutation updatePayment($id: Int, $status: Boolean){
  updatePayment(id: $id, status: $status){
    status
    errorMessage
  }
}`;

export const addPayoutMutation = gql`mutation addPayout($methodId: Int!, $payEmail: String!, $address1: String, $address2: String, $city: String!, $state: String!, $country: String!, $zipcode: String!, $currency: String!, $isVerified: Boolean) {
  addPayout(methodId: $methodId, payEmail: $payEmail, address1: $address1, address2: $address2, city: $city, state: $state, country: $country, zipcode: $zipcode, currency: $currency, isVerified: $isVerified) {
    status
    result{
      id
      methodId
      userId
      payEmail
      last4Digits
      address1
      address2
      city
      state
      country
      zipcode
      currency
      createdAt
      status
    }
  }
}`;