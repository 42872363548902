import { updatePassword } from '../../../../actions/shop/forgotPassword/updatePassword';
import showToaster from '../../../../helpers/showToaster';

async function submit(values, dispatch) {
  if (values.newPassword != values.confirmPassword) {
    showToaster({ messageId: 'missMatchPassword', toasterType: 'error' })
  }
  else {
    await dispatch(updatePassword({ email: values.email, newPassword: values.newPassword, token: values.token }))
  }
}

export default submit;