
import React from 'react';
import Layout from '../../../components/Shop/StoreLayout/Layout/Layout';
import Signup from './Signup';
import messages from '../../../locale/messages';

function action({intl}) {
  const title = intl.formatMessage(messages.signup);

  return {
    title,
    component: (
      <Layout>
        <Signup title={title} />
      </Layout>
    ),
  };
};

export default action;
