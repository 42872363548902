export const commonRoutes = [
    {
        path: '/',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./home'));
            reject(error);
        }),
    },
    {
        path: '/contact',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./static/contact'));
            reject(error);
        }),
    },
    {
        path: '/privacy',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./static/privacy'));
            reject(error);
        }),
    },
    {
        path: '/faq',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./static/faq'));
            reject(error);
        }),
    },
    {
        path: '/cookie-policy',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./static/cookiePolicy'));
            reject(error);
        }),
    },
    {
        path: '/legal',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./static/legal'));
            reject(error);
        }),
    }

];