import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EmptyList.css';
import rs from '../../../storeCommon.css';
import {
  Col,
  Row,
  Container,
  Button
} from 'react-bootstrap';
import messages from '../../../../locale/messages';
import { checkPayout } from '../../../../actions/shop/payout/checkPayout';

class EmptyList extends Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick() {
    const { checkPayout } = this.props;
    checkPayout();
  }

  render() {
    const { siteName } = this.props;

    return (
      <div className={'mainContainer'}>
        <div className={s.container}>
          <Container fluid>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} className={s.noPadding}>
                <h1 className={cx(rs.storeTitleText, 'textRightRTL')}>
                  <FormattedMessage {...messages.payoutMethod} />
                </h1>
                <div className={cx(s.spaceTop3, s.textCenter)}>
                  <span className={s.textTitle}><FormattedMessage {...messages.addPayoutMethod} /></span>
                </div>
                <div>
                  <span className={s.textLead}>{siteName} <FormattedMessage {...messages.paymentReleaseInfo1} /></span><br />
                  <span className={s.textLead}><FormattedMessage {...messages.paymentReleaseInfo2} /></span>
                </div>
                <div className={cx(rs.spaceTop4, rs.space2)}>
                  <Button onClick={() => this.handleClick()} className={cx(rs.button, rs.btnPrimary)}><FormattedMessage {...messages.addPayout} /></Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div >
      </div >
    );
  }
};

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
});

const mapDispatch = {
  checkPayout
};

export default compose(
  injectIntl,
  withStyles(s, rs),
  connect(mapState, mapDispatch)
)(EmptyList);