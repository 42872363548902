import gql from 'graphql-tag';
import histroy from '../../history';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';
import showToaster from '../../helpers/showToaster';

export function updateLocation(LocationName, coordinates, id, description, isActive) {

  let coordinatesError = '';
  if (!coordinates || coordinates == '') {
    showToaster({ messageId: 'addLocationError', toasterType: 'error' })
    coordinatesError = 'yes';
  }

  return async (dispatch, getState, { client }) => {
    const mutation = gql`
        mutation updateLocation(
  				    $LocationName: String!,
              $coordinates: String!,
              $id: Int!,
              $description: String!,
              $isActive: Boolean!
            ) {
              updateLocation(
              LocationName: $LocationName
              coordinates: $coordinates,
              id: $id,
              description: $description,
              isActive: $isActive
            ){
              status
              errorMessage
            }
          }
        `;


    dispatch(setLoaderStart('updateLocation'));

    if (coordinatesError != '') {
      dispatch(setLoaderComplete('updateLocation'));
    }

    const { data } = await client.mutate({
      mutation,
      variables: {
        LocationName,
        coordinates,
        id,
        description,
        isActive: isActive == 'true' ? 1 : 0
      },

    });

    dispatch(setLoaderComplete('updateLocation'));

    if (data && data.updateLocation && data.updateLocation.status === 200) {
      showToaster({ messageId: 'updateLocation', toasterType: 'success' })
      histroy.push('/siteadmin/location');
    } else if (data && data.updateLocation && data.updateLocation.status !== 200) {
      showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateLocation?.errorMessage })
    } else {
      showToaster({ messageId: 'commonError', toasterType: 'error' })
    }
  }
}