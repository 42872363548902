import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Nav, NavDropdown, Navbar, Collapse, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi';
import cx from 'classnames';
import { connect } from 'react-redux';

// Components
import Link from '../../Link';
import HeaderModal from '../../HeaderModal/HeaderModal';

// Action
import { openHeaderModal } from '../../../actions/siteadmin/modalActions';
// Helper
import { formatLocale } from '../../../helpers/formatLocale';
import { validatePrivilege } from '../../../helpers/adminPrivileges';
import { adminLogout } from '../../../actions/siteadmin/logout';
import messages from '../../../locale/messages';

// Images
import DashboardIcon from '../../../../public/Icons/dashboard.svg';
import SiteSettingIcon from '../../../../public/Icons/siteSettings.svg';
import DriversIcon from '../../../../public/Icons/manage-drivers.svg';
import CategoriesIcon from '../../../../public/Icons/category.svg';
import BookingsIcon from '../../../../public/Icons/booking.svg';
import PromoCodeIcon from '../../../../public/Icons/promo-code.svg';
import CompletedBooking from '../../../../public/Icons/completedbookings.svg';
import ChangePasswordIcon from '../../../../public/Icons/changePassword.svg';
import CancalBookingIcon from '../../../../public/Icons/cancelledBookings.svg';
import ManageCurrencyIcon from '../../../../public/Icons/CurrencyManage.svg';
import NotificationIcon from '../../../../public/Icons/sendNotifications.svg';
import LogOutIcon from '../../../../public/Icons/logout.svg';
import MainSiteIcon from '../../../../public/Icons/mainSite.svg';
import HomeSettingIcon from '../../../../public/Icons/homePageSettings.svg';
import RightArrowIcon from '../../../../public/Icons/right-arrow.png';
import history from '../../../history';
import serviceFee from '../../../../public/Icons/serviceFee.svg';
import AdminEarningIcon from '../../../../public/Icons/adminEarnings.svg';
import CancelReason from '../../../../public/Icons/cancelReasonAdmin.svg';
import ContentPageManagementIcon from '../../../../public/Icons/contentManagementAdmin.svg';
import UserIcon from '../../../../public/Icons/manage-users.svg';
import ManageStoresIcon from '../../../../public/Icons/manage-store.svg';
import ManageStaticContentIcon from '../../../../public/Icons/mangeStaticContent.svg';
import ReviewManage from '../../../../public/Icons/reviewsAdmin.svg';
import ViceleType from '../../../../public/Icons/vehicleType.svg';
import languageIcon from '../../../../public/Icons/Language-white.svg';
import adminIcon from '../../../../public/Icons/Manageadmins.svg';
import homeIcon from '../../../../public/Icons/home-page-settings.svg';
import managePaymentGatewayIcon from '../../../../public/Icons/managePaymentGatewayIcon.svg';

import s from './SideMenu.css';

class SideMenu extends React.Component {
  static defaultProps = {
    isSuperAdmin: false,
    privileges: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: 0,
      location: '',
      homepageSettings: false,
      storeHomepageSettings: false,
      profileMenu: false,
    };
    this.openMenu = this.openMenu.bind(this);
    this.openClose = this.openClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const storeSettingsArray = [
      '/siteadmin/store-home/banner',
      '/siteadmin/store-home/info',
      '/siteadmin/store-home/slider',
      '/siteadmin/store-home/contact',
    ];

    this.setState({
      location: history.location.pathname,
    });

    if (
      history &&
      history.location &&
      storeSettingsArray.includes(history.location.pathname)
    ) {
      this.setState({
        profileMenu: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    if (prevState.location !== location) {
      this.setState({
        location,
      });
    }
  }

  async openMenu() {
    this.setState({
      isOpen: 1,
    });
  }

  async openClose() {
    this.setState({
      isOpen: 0,
    });
  }

  handleChange(e) {
    const { openHeaderModal } = this.props;
    openHeaderModal('languageModal');
  }

  render() {
    const { adminLogout, currentLocale, isSuperAdmin, privileges } = this.props;
    const { location, profileMenu } = this.state;
    let storeSettingsArray;
    let homeSettingsArray;
    let adminManagementArray;

    storeSettingsArray = [
      '/siteadmin/store-home/banner',
      '/siteadmin/store-home/info',
      '/siteadmin/store-home/slider',
      '/siteadmin/store-home/contact',
    ];
    homeSettingsArray = [
      '/siteadmin/homepage-banner',
      '/siteadmin/homepage-howitworks',
      '/siteadmin/homepage-user',
      '/siteadmin/homepage-driver-app',
      '/siteadmin/homepage-category',
      '/siteadmin/homepage-footersettings',
    ];
    adminManagementArray = ['/siteadmin/admin-users', '/siteadmin/admin-roles'];

    return (
      <div className={s.sideMenuBg}>
        <div className={s.siteAdminHeader}>
          <span>
            <FormattedMessage {...messages.siteAdmin} />
          </span>
        </div>
        <div className={cx(s.sideMenuList, 'sideMenu', 'sideMenuScroll')}>
          <div>
            <Link
              to=""
              onClick={() => adminLogout()}
              className={cx(
                s.logoutIconMobile,
                'd-block d-lg-none',
                'logoutIconMobileRTL',
              )}
            >
              <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                <img src={LogOutIcon} className={cx(s.sideMenuIcon)} />
              </span>
            </Link>
          </div>
          <Navbar expand="lg">
            <div onClick={() => this.openMenu()}>
              <div className="nav-container">
                <div
                  className={cx(
                    'button  d-block d-lg-none d-lg-none,',
                    'menuAdminMbRTL',
                  )}
                  tabIndex="0"
                >
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </div>
              </div>
            </div>
            <div
              className={cx(
                { [s.menuOpen]: this.state.isOpen == 1 },
                s.mobileMenu,
              )}
            >
              <div
                className={cx(
                  { [s.menuClose]: this.state.isOpen == 0 },
                  s.rightMenuClose,
                  'd-block d-lg-none',
                )}
              >
                <div
                  className={cx(s.closeColor, 'closeColorRTL')}
                  onClick={() => this.openClose()}
                >
                  ×
                </div>
              </div>
              <Nav className="mr-auto">
                <HeaderModal />
                <Link
                  onClick={e => this.handleChange(e)}
                  className={cx(
                    s.sideNavitem,
                    s.spaceTop2,
                    s.responsiveDasboradMargin,
                    'd-block d-lg-none',
                    'sideMenuIconRTL',
                  )}
                >
                  <span
                    className={cx(
                      s.displayInlineBlock,
                      s.vtrTop,
                      s.languageIcon,
                    )}
                  >
                    <img
                      src={languageIcon}
                      className={cx(s.sideMenuIcon, s.noFilter)}
                    />
                  </span>
                  <span
                    className={cx(
                      s.displayInlineBlock,
                      s.vtrMiddle,
                      s.iconTextPadding,
                      s.textPadding,
                    )}
                  >
                    {formatLocale(currentLocale)}
                  </span>
                </Link>
                <Link
                  to="/siteadmin"
                  className={cx(
                    s.sideNavitem,
                    { [s.activeMenu]: location == '/siteadmin' },
                    'sideMenuIconRTL',
                  )}
                  onClick={() => this.openClose()}
                >
                  <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                    <img
                      src={DashboardIcon}
                      className={cx(s.sideMenuIcon, s.noFilter)}
                    />
                  </span>
                  <span
                    className={cx(
                      s.displayInlineBlock,
                      s.vtrMiddle,
                      'textRightRTL',
                    )}
                  >
                    <FormattedMessage {...messages.manageDashboard} />
                  </span>
                </Link>

                {validatePrivilege(1, privileges) && (
                  <div>
                    <Link
                      to="/siteadmin/settings/site"
                      className={cx(
                        s.sideNavitem,
                        {
                          [s.activeMenu]:
                            location === '/siteadmin/settings/site',
                        },
                        'sideMenuIconRTL',
                      )}
                      onClick={() => this.openClose()}
                    >
                      <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                        <img
                          src={SiteSettingIcon}
                          className={cx(s.sideMenuIcon, s.noFilter)}
                        />
                      </span>
                      <span
                        className={cx(
                          s.displayInlineBlock,
                          s.vtrMiddle,
                          'textRightRTL',
                        )}
                      >
                        <FormattedMessage {...messages.siteSettings} />
                      </span>
                    </Link>
                    <Link
                      to="/siteadmin/settings/config"
                      className={cx(
                        s.sideNavitem,
                        {
                          [s.activeMenu]:
                            location === '/siteadmin/settings/config',
                        },
                        'sideMenuIconRTL',
                      )}
                      onClick={() => this.openClose()}
                    >
                      <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                        <img
                          src={SiteSettingIcon}
                          className={cx(s.sideMenuIcon, s.noFilter)}
                        />
                      </span>
                      <span
                        className={cx(
                          s.displayInlineBlock,
                          s.vtrMiddle,
                          'textRightRTL',
                        )}
                      >
                        <FormattedMessage {...messages.manageSiteConfig} />
                      </span>
                    </Link>
                  </div>
                )}

                {validatePrivilege(3, privileges) && (
                  <div>
                    <div
                      id="Homepage"
                      className={cx({
                        [s.activeMenu]: homeSettingsArray.includes(location),
                      })}
                    >
                      <Button
                        variant="link"
                        className={cx(
                          s.button,
                          s.noPadding,
                          s.noBorderBtn,
                          s.activeMenu,
                          s.btnWidth,
                          s.sideNavitem,
                          s.btnNoPadding,
                          'textRightRTL',
                          'sideMenuIconRTL',
                        )}
                        onClick={() => {
                          this.setState({
                            homepageSettings: !this.state.homepageSettings,
                          });
                        }}
                      >
                        <span
                          className={cx(
                            s.displayInlineBlock,
                            s.vtrTop,
                          )}
                        >
                          <img
                            src={homeIcon}
                            className={cx(s.sideMenuIcon, s.noFilter)}
                          />
                        </span>
                        <span
                          className={cx(
                            s.displayInlineBlock,
                            s.vtrMiddle,
                            'textRightRTL',
                          )}
                        >
                          <FormattedMessage {...messages.HomeSiteSettings} />
                        </span>
                        {this.state.homepageSettings && (
                          <div className={cx(s.displayInlineBlock, s.arrowCss)}>
                            <HiOutlineChevronUp className={s.navigationIcon} />
                          </div>
                        )}

                        {!this.state.homepageSettings && (
                          <div className={cx(s.displayInlineBlock, s.arrowCss)}>
                            <HiOutlineChevronDown
                              className={s.navigationIcon}
                            />
                          </div>
                        )}
                      </Button>
                    </div>
                    <Collapse in={this.state.homepageSettings}>
                      <div>
                        <Link
                          to="/siteadmin/homepage-banner"
                          className={cx(
                            s.sideNavitem,
                            s.sideNavitemCollapsePadding,
                            {
                              [s.activeMenu]: location.startsWith(
                                '/siteadmin/homepage-banner',
                              ),
                            },
                            'sideInnerPaddingRTL',
                          )}
                          onClick={() => this.openClose()}
                        >
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrTop,
                              s.sideNavitemCollapse,
                            )}
                          >
                            <img
                              src={RightArrowIcon}
                              className={cx(
                                s.sideMenuIcon,
                                s.sideNavitemCollapseIcon,
                              )}
                            />
                          </span>
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrMiddle,
                              s.sideNavitemCollapseText,
                              'textRightRTL',
                            )}
                          >
                            <FormattedMessage
                              {...messages.homeSectionSettings}
                            />
                          </span>
                        </Link>
                        <Link
                          to="/siteadmin/homepage-howitworks"
                          className={cx(
                            s.sideNavitem,
                            s.sideNavitemCollapsePadding,
                            {
                              [s.activeMenu]: location.startsWith(
                                '/siteadmin/homepage-howitworks',
                              ),
                            },
                            'sideInnerPaddingRTL',
                          )}
                          onClick={() => this.openClose()}
                        >
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrTop,
                              s.sideNavitemCollapse,
                            )}
                          >
                            <img
                              src={RightArrowIcon}
                              className={cx(
                                s.sideMenuIcon,
                                s.sideNavitemCollapseIcon,
                              )}
                            />
                          </span>
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrMiddle,
                              s.sideNavitemCollapseText,
                              'textRightRTL',
                            )}
                          >
                            <FormattedMessage {...messages.howItWorks} />
                          </span>
                        </Link>
                        <Link
                          to="/siteadmin/homepage-user"
                          className={cx(
                            s.sideNavitem,
                            s.sideNavitemCollapsePadding,
                            {
                              [s.activeMenu]: location.startsWith(
                                '/siteadmin/homepage-user',
                              ),
                            },
                            'sideInnerPaddingRTL',
                          )}
                          onClick={() => this.openClose()}
                        >
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrTop,
                              s.sideNavitemCollapse,
                            )}
                          >
                            <img
                              src={RightArrowIcon}
                              className={cx(
                                s.sideMenuIcon,
                                s.sideNavitemCollapseIcon,
                              )}
                            />
                          </span>
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrMiddle,
                              s.sideNavitemCollapseText,
                              'textRightRTL',
                            )}
                          >
                            <FormattedMessage
                              {...messages.safetySectionSettings}
                            />
                          </span>
                        </Link>
                        <Link
                          to="/siteadmin/homepage-driver-app"
                          className={cx(
                            s.sideNavitem,
                            s.sideNavitemCollapsePadding,
                            {
                              [s.activeMenu]: location.startsWith(
                                '/siteadmin/homepage-driver-app',
                              ),
                            },
                            'sideInnerPaddingRTL',
                          )}
                          onClick={() => this.openClose()}
                        >
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrTop,
                              s.sideNavitemCollapse,
                            )}
                          >
                            <img
                              src={RightArrowIcon}
                              className={cx(
                                s.sideMenuIcon,
                                s.sideNavitemCollapseIcon,
                              )}
                            />
                          </span>
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrMiddle,
                              s.sideNavitemCollapseText,
                              'textRightRTL',
                            )}
                          >
                            <FormattedMessage
                              {...messages.signupSectionSettings}
                            />
                          </span>
                        </Link>
                        <Link
                          to="/siteadmin/homepage-category"
                          className={cx(
                            s.sideNavitem,
                            s.sideNavitemCollapsePadding,
                            {
                              [s.activeMenu]: location.startsWith(
                                '/siteadmin/homepage-category',
                              ),
                            },
                            'sideInnerPaddingRTL',
                          )}
                          onClick={() => this.openClose()}
                        >
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrTop,
                              s.sideNavitemCollapse,
                            )}
                          >
                            <img
                              src={RightArrowIcon}
                              className={cx(
                                s.sideMenuIcon,
                                s.sideNavitemCollapseIcon,
                              )}
                            />
                          </span>
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrMiddle,
                              s.sideNavitemCollapseText,
                              'textRightRTL',
                            )}
                          >
                            <FormattedMessage {...messages.categories} />
                          </span>
                        </Link>
                        <Link
                          to="/siteadmin/homepage-footersettings"
                          className={cx(
                            s.sideNavitem,
                            s.sideNavitemCollapsePadding,
                            {
                              [s.activeMenu]: location.startsWith(
                                '/siteadmin/homepage-footersettings',
                              ),
                            },
                            'sideInnerPaddingRTL',
                          )}
                          onClick={() => this.openClose()}
                        >
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrTop,
                              s.sideNavitemCollapse,
                            )}
                          >
                            <img
                              src={RightArrowIcon}
                              className={cx(
                                s.sideMenuIcon,
                                s.sideNavitemCollapseIcon,
                              )}
                            />
                          </span>
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrMiddle,
                              s.sideNavitemCollapseText,
                              'textRightRTL',
                            )}
                          >
                            <FormattedMessage
                              {...messages.footerSectionSettings}
                            />
                          </span>
                        </Link>
                      </div>
                    </Collapse>
                  </div>
                )}
                {validatePrivilege(2, privileges) && (
                  <div>
                    <div
                      id="Homepage"
                      className={cx({
                        [s.activeMenu]: storeSettingsArray.includes(location),
                      })}
                    >
                      <Button
                        variant="link"
                        className={cx(
                          s.button,
                          s.noPadding,
                          s.noBorderBtn,
                          s.activeMenu,
                          s.btnWidth,
                          s.sideNavitem,
                          'textRightRTL',
                          'sideMenuIconRTL',
                        )}
                        onClick={() => {
                          this.setState({
                            restuarantHomepageSettings: !this.state
                              .restuarantHomepageSettings,
                          });
                        }}
                      >
                        <span
                          className={cx(
                            s.displayInlineBlock,
                            s.vtrTop,
                          )}
                        >
                          <img
                            src={HomeSettingIcon}
                            className={cx(s.sideMenuIcon, s.noFilter)}
                          />
                        </span>
                        <span
                          className={cx(
                            s.displayInlineBlock,
                            s.vtrMiddle,
                            'textRightRTL',
                          )}
                        >
                          <FormattedMessage
                            {...messages.restuarantHomepageSettings}
                          />
                        </span>
                        {this.state.restuarantHomepageSettings && (
                          <div className={cx(s.displayInlineBlock, s.arrowCss)}>
                            <HiOutlineChevronUp className={s.navigationIcon} />
                          </div>
                        )}

                        {!this.state.restuarantHomepageSettings && (
                          <div className={cx(s.displayInlineBlock, s.arrowCss)}>
                            <HiOutlineChevronDown
                              className={s.navigationIcon}
                            />
                          </div>
                        )}
                      </Button>
                    </div>
                    <Collapse in={this.state.restuarantHomepageSettings}>
                      <div>
                        <Link
                          to="/siteadmin/store-home/banner"
                          className={cx(
                            s.sideNavitem,
                            s.sideNavitemCollapsePadding,
                            {
                              [s.activeMenu]: location.startsWith(
                                '/siteadmin/store-home/banner',
                              ),
                            },
                            'sideInnerPaddingRTL',
                          )}
                          onClick={() => this.openClose()}
                        >
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrTop,
                              s.sideNavitemCollapse,
                            )}
                          >
                            <img
                              src={RightArrowIcon}
                              className={cx(
                                s.sideMenuIcon,
                                s.sideNavitemCollapseIcon,
                              )}
                            />
                          </span>
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrMiddle,
                              s.sideNavitemCollapseText,
                              'textRightRTL',
                            )}
                          >
                            <FormattedMessage {...messages.bannerSection} />
                          </span>
                        </Link>

                        <Link
                          to="/siteadmin/store-home/info"
                          className={cx(
                            s.sideNavitem,
                            s.sideNavitemCollapsePadding,
                            {
                              [s.activeMenu]: location.startsWith(
                                '/siteadmin/store-home/info',
                              ),
                            },
                            'sideInnerPaddingRTL',
                          )}
                          onClick={() => this.openClose()}
                        >
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrTop,
                              s.sideNavitemCollapse,
                            )}
                          >
                            <img
                              src={RightArrowIcon}
                              className={cx(
                                s.sideMenuIcon,
                                s.sideNavitemCollapseIcon,
                              )}
                            />
                          </span>
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrMiddle,
                              'textRightRTL',
                            )}
                          >
                            <FormattedMessage {...messages.infoSection} />
                          </span>
                        </Link>
                        <Link
                          to="/siteadmin/store-home/contact"
                          className={cx(
                            s.sideNavitem,
                            s.sideNavitemCollapsePadding,
                            {
                              [s.activeMenu]: location.startsWith(
                                '/siteadmin/store-home/contact',
                              ),
                            },
                            'sideInnerPaddingRTL',
                          )}
                          onClick={() => this.openClose()}
                        >
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrTop,
                              s.sideNavitemCollapse,
                            )}
                          >
                            <img
                              src={RightArrowIcon}
                              className={cx(
                                s.sideMenuIcon,
                                s.sideNavitemCollapseIcon,
                              )}
                            />
                          </span>
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrMiddle,
                              'textRightRTL',
                            )}
                          >
                            <FormattedMessage {...messages.sliderContact} />
                          </span>
                        </Link>
                        <Link
                          to="/siteadmin/store-home/slider"
                          className={cx(
                            s.sideNavitem,
                            s.sideNavitemCollapsePadding,
                            {
                              [s.activeMenu]: location.startsWith(
                                '/siteadmin/store-home/slider',
                              ),
                            },
                            'sideInnerPaddingRTL',
                          )}
                          onClick={() => this.openClose()}
                        >
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrTop,
                              s.sideNavitemCollapse,
                            )}
                          >
                            <img
                              src={RightArrowIcon}
                              className={cx(
                                s.sideMenuIcon,
                                s.sideNavitemCollapseIcon,
                              )}
                            />
                          </span>
                          <span
                            className={cx(
                              s.displayInlineBlock,
                              s.vtrMiddle,
                              'textRightRTL',
                            )}
                          >
                            <FormattedMessage {...messages.sliderSection} />
                          </span>
                        </Link>
                      </div>
                    </Collapse>
                  </div>
                )}

                {isSuperAdmin && (
                  <div>
                    <div
                      className={cx(
                        adminManagementArray.includes(location)
                          ? [s.active]
                          : '',
                      )}
                    >
                      <Button
                        bsStyle="link"
                        className={cx(
                          s.button,
                          s.noPadding,
                          s.noBorderBtn,
                          s.activeMenu,
                          s.btnWidth,
                          s.sideNavitem,
                          s.btnNoPadding,
                          'textRightRTL',
                          'sideMenuIconRTL',
                        )}
                        onClick={() =>
                          this.setState({
                            subAdmin: !this.state.subAdmin,
                          })
                        }
                      >
                        <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                          <img
                            src={adminIcon}
                            className={cx(s.sideMenuIcon, s.noFilter)}
                          />
                        </span>
                        <span
                          className={cx(s.displayInlineBlock, 'textRightRTL')}
                        >
                          <FormattedMessage {...messages.manageAdmin} />
                        </span>
                        {this.state.subAdmin && (
                          <div className={cx(s.displayInlineBlock, s.arrowCss)}>
                            <HiOutlineChevronUp className={s.navigationIcon} />
                          </div>
                        )}

                        {!this.state.subAdmin && (
                          <div className={cx(s.displayInlineBlock, s.arrowCss)}>
                            <HiOutlineChevronDown
                              className={s.navigationIcon}
                            />
                          </div>
                        )}
                      </Button>
                    </div>
                    <Collapse in={this.state.subAdmin} className={s.subMenu}>
                      <div>
                        <li
                          className={cx({
                            [s.active]: location === '/siteadmin/admin-users',
                          })}
                        >
                          <Link
                            to="/siteadmin/admin-users"
                            className={cx(
                              s.sideNavitem,
                              s.disPlayTable,
                              'sideInnerPaddingRTL',
                              'textRightRTL',
                            )}
                            onClick={() => this.openClose()}
                          >
                            <span
                              className={cx(
                                s.displayInlineBlock,
                                s.vtrTop,
                                s.sideNavitemCollapse,
                              )}
                            >
                              <img
                                src={RightArrowIcon}
                                className={cx(
                                  s.sideMenuIcon,
                                  s.sideNavitemCollapseIcon,
                                )}
                              />
                            </span>
                            <span
                              className={cx(s.disPlayTabelCell, 'textRightRTL')}
                            >
                              <FormattedMessage
                                {...messages.manageAdminUsers}
                              />
                            </span>
                          </Link>
                        </li>

                        <li
                          className={cx({
                            [s.active]: location === '/siteadmin/admin-roles',
                          })}
                        >
                          <Link
                            to="/siteadmin/admin-roles"
                            className={cx(
                              s.sideNavitem,
                              s.disPlayTable,
                              'sideInnerPaddingRTL',
                              'textRightRTL',
                            )}
                            onClick={() => this.openClose()}
                          >
                            <span
                              className={cx(
                                s.displayInlineBlock,
                                s.vtrTop,
                                s.sideNavitemCollapse,
                              )}
                            >
                              <img
                                src={RightArrowIcon}
                                className={cx(
                                  s.sideMenuIcon,
                                  s.sideNavitemCollapseIcon,
                                )}
                              />
                            </span>
                            <span
                              className={cx(s.disPlayTabelCell, 'textRightRTL')}
                            >
                              <FormattedMessage
                                {...messages.manageAdminRoles}
                              />
                            </span>
                          </Link>
                        </li>
                      </div>
                    </Collapse>
                  </div>
                )}
                {validatePrivilege(4, privileges) && (
                  <Link
                    to="/siteadmin/users"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location.startsWith('/siteadmin/users'),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={UserIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.ridersMenu} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(5, privileges) && (
                  <Link
                    to="/siteadmin/drivers"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location.startsWith(
                          '/siteadmin/drivers',
                        ),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={DriversIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.driverMenu} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(6, privileges) && (
                  <Link
                    to="/siteadmin/store/list"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]:
                          location.startsWith('/siteadmin/store/list') ||
                          location.startsWith('/siteadmin/store/edit'),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={ManageStoresIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.manageStores} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(7, privileges) && (
                  <Link
                    to="/siteadmin/manage-service-fee"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location.startsWith(
                          '/siteadmin/manage-service-fee',
                        ),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img src={serviceFee} className={s.sideMenuIcon} />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.manageServiceFee} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(8, privileges) && (
                  <Link
                    to="/siteadmin/category"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location.startsWith(
                          '/siteadmin/category',
                        ),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img src={CategoriesIcon} className={s.sideMenuIcon} />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.categoryMenu} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(22, privileges) && (
                  <Link
                    to="/siteadmin/payment-gateway"
                    className={cx(
                      s.sideNavitem,
                      s.displayTable,
                      'sideNavitemRTL',
                      {
                        [s.activeMenu]: location.startsWith(
                          '/siteadmin/payment-gateway',
                        ),
                      },
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span
                      className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}
                    >
                      <img
                        src={managePaymentGatewayIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.vtrMiddle,
                        s.displayTableCell,
                        'textAlignRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.managePaymentGateWay} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(9, privileges) && (
                  <Link
                    to="/siteadmin/vehicle-type/list"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location.startsWith(
                          '/siteadmin/vehicle-type',
                        ),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={ViceleType}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.vehicleTypes} />
                    </span>
                  </Link>
                )}

                {validatePrivilege(11, privileges) && (
                  <Link
                    to="/siteadmin/admin-earnings"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]:
                          location.startsWith('/siteadmin/admin-earnings') ||
                          location.startsWith('/siteadmin/view-earning'),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={AdminEarningIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.earningsMenu} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(12, privileges) && (
                  <Link
                    to="/siteadmin/bookings"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]:
                          location.startsWith('/siteadmin/bookings') ||
                          location.startsWith('/siteadmin/view-booking'),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img src={BookingsIcon} className={s.sideMenuIcon} />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.bookingsMenu} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(13, privileges) && (
                  <Link
                    to="/siteadmin/completed-bookings"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location.startsWith(
                          '/siteadmin/completed-bookings',
                        ),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={CompletedBooking}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.manageCompletedTrips} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(14, privileges) && (
                  <Link
                    to="/siteadmin/failed-bookings"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location.startsWith(
                          '/siteadmin/failed-bookings',
                        ),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={CancalBookingIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.manageCancelledTrips} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(15, privileges) && (
                  <Link
                    to="/siteadmin/reviews"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location.startsWith(
                          '/siteadmin/reviews',
                        ),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={ReviewManage}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.ratings} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(16, privileges) && (
                  <Link
                    to="/siteadmin/promo-code/list"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location.startsWith(
                          '/siteadmin/promo-code',
                        ),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img src={PromoCodeIcon} className={s.sideMenuIcon} />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.managePromoCode} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(17, privileges) && (
                  <Link
                    to="/siteadmin/change/admin"
                    className={cx(
                      s.sideNavitem,
                      { [s.activeMenu]: location == '/siteadmin/change/admin' },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={ChangePasswordIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.changePassword} />
                    </span>
                  </Link>
                )}
                {isSuperAdmin && (
                  <Link
                    to="/siteadmin/notifications"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location == '/siteadmin/notifications',
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={NotificationIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.manageNotifications} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(18, privileges) && (
                  <Link
                    to="/siteadmin/currency"
                    className={cx(
                      s.sideNavitem,
                      { [s.activeMenu]: location == '/siteadmin/currency' },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={ManageCurrencyIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.manageCurrency} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(19, privileges) && (
                  <Link
                    to="/siteadmin/cancel-reasons"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]: location.startsWith(
                          '/siteadmin/cancel-reasons',
                        ),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={CancelReason}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.cancellationReasons} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(20, privileges) && (
                  <Link
                    to="/siteadmin/staticpage/manage"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]:
                          location.startsWith('/siteadmin/staticpage/manage') ||
                          location.startsWith('/siteadmin/staticpage/edit/'),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={ManageStaticContentIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.staticpageManagement} />
                    </span>
                  </Link>
                )}
                {validatePrivilege(21, privileges) && (
                  <Link
                    to="/siteadmin/contentpage/manage"
                    className={cx(
                      s.sideNavitem,
                      {
                        [s.activeMenu]:
                          location.startsWith(
                            '/siteadmin/contentpage/manage',
                          ) ||
                          location.startsWith('/siteadmin/contentpage/edit/') ||
                          location.startsWith('/siteadmin/contentpage/add'),
                      },
                      'sideMenuIconRTL',
                    )}
                    onClick={() => this.openClose()}
                  >
                    <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                      <img
                        src={ContentPageManagementIcon}
                        className={cx(s.sideMenuIcon, s.noFilter)}
                      />
                    </span>
                    <span
                      className={cx(
                        s.displayInlineBlock,
                        s.vtrMiddle,
                        'textRightRTL',
                      )}
                    >
                      <FormattedMessage {...messages.contentPageManagement} />
                    </span>
                  </Link>
                )}
                <Link
                  to="/"
                  className={cx(
                    s.sideNavitem,
                    s.logOutSection,
                    'sideMenuIconRTL',
                  )}
                  onClick={() => this.openClose()}
                >
                  <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                    <img
                      src={MainSiteIcon}
                      className={cx(s.sideMenuIcon, s.noFilter)}
                    />
                  </span>
                  <span
                    className={cx(
                      s.displayInlineBlock,
                      s.vtrMiddle,
                      s.textPadding,
                    )}
                  >
                    <FormattedMessage {...messages.goToMainSite} />
                  </span>
                </Link>
                <Link
                  to=""
                  onClick={() => adminLogout()}
                  className={cx(
                    s.sideNavitem,
                    s.logOutSection,
                    'sideMenuIconRTL',
                  )}
                >
                  <span className={cx(s.displayInlineBlock, s.vtrTop)}>
                    <img
                      src={LogOutIcon}
                      className={cx(s.sideMenuIcon, s.noFilter)}
                    />
                  </span>
                  <span
                    className={cx(
                      s.displayInlineBlock,
                      s.vtrMiddle,
                      s.textPadding,
                    )}
                  >
                    <FormattedMessage {...messages.logout} />
                  </span>
                </Link>
              </Nav>
            </div>
          </Navbar>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  currentLocale: state.intl.locale,
  isSuperAdmin: state.runtime.isSuperAdmin,
  privileges:
    state.account.privileges &&
    state.account.privileges.privileges &&
    state.account.privileges.privileges,
});

const mapDispatch = {
  adminLogout,
  openHeaderModal,
};

export default withStyles(s)(connect(mapState, mapDispatch)(SideMenu));
