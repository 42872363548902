import showToaster from '../../../helpers/showToaster';
import history from '../../../history';
import { updateForgotPassword as mutation } from '../../../lib/graphql';

export const updatePassword = ({ email, newPassword, token }) => {
  return async (dispatch, getState, { client }) => {
    try {
      const { data } = await client.mutate({
        mutation,
        variables: {
          email,
          token,
          newPassword
        }
      });

      if (data?.updateForgotPassword) {
        if (data?.updateForgotPassword?.status === 200) {
          showToaster({ messageId: 'updateForgotPassword', toasterType: 'success' });
          history.push('/store');
        } else {
          showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateForgotPassword?.errorMessage });
          return false;
        }
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error' });
        return false;
      }

    } catch (error) {
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
      return false;
    }
    return true;
  };
}