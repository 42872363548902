exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".d-LbT {\n    font-size: 16px;\n    color: #767676;\n    margin-bottom: 30px;\n}\n\n._3s03m {\n    display: inline-block;\n    padding-left: 10px;\n    vertical-align: middle;\n}\n\n.pBE2q {\n    color: #018E4D !important;\n    text-decoration: none !important;\n}\n\n._1HAvG {\n    color: #E83A30 !important;\n    text-decoration: none !important;\n}\n\n.T8zop {\n    color: red\n}\n\n.Orh_A {\n    margin-left: 8px;\n    padding-right: 6px\n}\n\n.xLVQw {\n    color: #018E4D !important;\n    text-decoration: none !important;\n    display: inline-block;\n}\n\n._15bSn {\n    text-decoration: line-through;\n}\n\n.nYyRm {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: end;\n        justify-content: flex-end;\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n    grid-gap: 10px;\n    gap: 10px;\n}\n\n._3I3c5 {\n    margin-bottom: 16px;\n}\n\n@media screen and (max-width: 767px) {\n    ._3s03m {\n        display: block;\n        padding-left: 0px;\n    }\n\n    ._3kM5z {\n        width: 100%;\n    }\n\n    ._3fBY6 {\n        -ms-flex: 1 1;\n            flex: 1 1;\n        width: 100%;\n    }\n}\n\n.ycHeF {\n    width: 100%;\n    max-width: 24px;\n    vertical-align: middle;\n    margin-right: 6px;\n}", ""]);

// exports
exports.locals = {
	"infoBox": "d-LbT",
	"displayInlineBtn": "_3s03m",
	"veriyText": "pBE2q",
	"removeText": "_1HAvG",
	"errorMessage": "T8zop",
	"removeImg": "Orh_A",
	"linkTextColor": "xLVQw",
	"lineThrough": "_15bSn",
	"btnDisplayFlex": "nYyRm",
	"mb16": "_3I3c5",
	"btnWidthMobile": "_3kM5z",
	"btnFlexItems": "_3fBY6",
	"iconsWidth": "ycHeF"
};