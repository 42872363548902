import showToaster from '../../../helpers/showToaster';
import { loadAccount } from '../userAccount/userAccount';
import { getAllSubMenu as query, deleteItem as mutation } from '../../../lib/graphql';
import {
	SHOP_MENU_UPDATE_START,
	SHOP_MENU_UPDATE_SUCCESS,
	SHOP_MENU_UPDATE_ERROR
} from '../../../constants/index';

const deleteItem = (id, currentPage, menuId) => {
	return async (dispatch, getState, { client }) => {

		try {
			dispatch({
				type: SHOP_MENU_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});

			const { data } = await client.mutate({
				mutation,
				variables: {
					id
				},
				refetchQueries: [{
					query,
					variables: {
						currentPage,
						searchList: '',
						menuId
					}
				}]
			});

			if (data?.deleteItem?.status === 200) {
				dispatch({
					type: SHOP_MENU_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'deleteItem', toasterType: 'success' });
				dispatch(loadAccount());
			} else {
				dispatch({
					type: SHOP_MENU_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.deleteItem?.errorMessage });
			}

		} catch (error) {
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
			dispatch({
				type: SHOP_MENU_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};

export default deleteItem;