import showToaster from '../../../helpers/showToaster';
import history from '../../../history';
import { updateMenu, addMenuMutation } from '../../../lib/graphql';

const addMenu = (values) => {
	return async (dispatch, getState, { client }) => {

		try {
			let operationHours = values.menuAvailability ? JSON.stringify(values.menuAvailability) : JSON.stringify([]);

			if (values?.id) {
				const { data } = await client.mutate({
					mutation: updateMenu,
					variables: {
						operationHours: operationHours,
						menuName: values?.menuName,
						id: values?.id,
						isEnable: values?.isEnable == 'false' ? 0 : 1,
					}
				});

				if (data?.updateMenu?.status == 200) {
					showToaster({ messageId: 'updateItem', toasterType: 'success' });
					history.push('/store/menu');
				} else {
					showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateMenu?.errorMessage });
				}
			} else {
				const { data } = await client.mutate({
					mutation: addMenuMutation,
					variables: {
						operationHours: operationHours,
						menuName: values?.menuName,
						isEnable: values?.isEnable == 'false' ? 0 : 1,
					}
				});

				if (data?.addMenu?.status == 200) {
					showToaster({ messageId: 'addMenu', toasterType: 'success' });
					history.push('/store/menu');
				} else {
					showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.addMenu?.errorMessage });
				}
			}

		} catch (error) {
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
		}
	}
};

export default addMenu;