import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { uploadDocument as mutation } from '../../../lib/graphql/store';
import {
    SHOP_DOCUMENT_UPDATE_ERROR,
    SHOP_DOCUMENT_UPDATE_START,
    SHOP_DOCUMENT_UPDATE_SUCCESS
} from '../../../constants';

const uploadDocument = (documentList) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_DOCUMENT_UPDATE_START
            });

            dispatch(setLoaderStart('AdminDocumentUpdate'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    documentList
                }
            });

            if (data?.uploadDocument?.status == 200) {
                showToaster({ messageId: 'uploadDocument', toasterType: 'success' });
                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('AdminDocumentUpdate'));
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.uploadDocument?.errorMessage });
                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('AdminDocumentUpdate'));
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
            await dispatch({
                type: SHOP_DOCUMENT_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('AdminDocumentUpdate'));
        }
    }
};

export default uploadDocument;