import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { getPayouts } from './getPayouts';
import { setDefaultPayout as mutation } from '../../../lib/graphql';
import {
  SET_DEFAULT_PAYOUT_START,
  SET_DEFAULT_PAYOUT_SUCCESS,
  SET_DEFAULT_PAYOUT_ERROR,
} from '../../../constants';

export function setDefaultPayout(id) {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: SET_DEFAULT_PAYOUT_START,
      });
      dispatch(setLoaderStart('payoutDefault'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id
        }
      });

      if (data?.setDefaultPayout?.status == 200) {
        await dispatch({
          type: SET_DEFAULT_PAYOUT_SUCCESS
        });
        await dispatch(getPayouts());
      }

      showToaster({ messageId: 'setDefaultPayout', toasterType: 'success' })
      await dispatch(setLoaderComplete('payoutDefault'));

    } catch (error) {
      dispatch({
        type: SET_DEFAULT_PAYOUT_ERROR
      });
      showToaster({ messageId: 'commonError', toasterType: 'error' })
      dispatch(setLoaderComplete('payoutDefault'));
      return false;
    };
    return true;
  };
};
