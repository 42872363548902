import showToaster from '../../../helpers/showToaster';
import history from '../../../history';
import { updateDeliveryVehicle } from '../../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import {
  ADD_UPDATE_DELIVERY_VEHICLE_START,
  ADD_UPDATE_DELIVERY_VEHICLE_SUCCESS,
  ADD_UPDATE_DELIVERY_VEHICLE_ERROR
} from '../../../constants';

export const addUpdateDeliveryVehicle = ({
  id,
  vehicleName,
  vehicleImage,
  description,
  isActive
}) => {
  return async (dispatch, getState, { client }) => {

    try {
      let variables, requestContent;

      dispatch({
        type: ADD_UPDATE_DELIVERY_VEHICLE_START,
        payload: {
          pricingLoading: true
        }
      });
      dispatch(setLoaderStart('DeliveryVehicleForm'));

      variables = {
        id,
        vehicleName,
        vehicleImage,
        description,
        isActive: (isActive == true || 'true') ? 1 : 0
      };

      const { data } = await client.mutate({
        mutation: updateDeliveryVehicle,
        variables
      });

      const response = data?.updateDeliveryVehicle;

      if (response?.status === 200) {
        requestContent = id ? 'updated' : 'added';

        history.push('/siteadmin/vehicle-type/list');

        showToaster({ messageId: 'addVehicle', toasterType: 'success', requestContent });
        await dispatch({
          type: ADD_UPDATE_DELIVERY_VEHICLE_SUCCESS,
          payload: {
            pricingLoading: false
          }
        });
        dispatch(setLoaderComplete('DeliveryVehicleForm'));
        return {
          status: 200
        };
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: response?.errorMessage });
        await dispatch({
          type: ADD_UPDATE_DELIVERY_VEHICLE_ERROR,
          payload: {
            pricingLoading: false,
            error: response?.errorMessage
          }
        });
        dispatch(setLoaderComplete('DeliveryVehicleForm'));
        return {
          status: 400
        };
      }

    } catch (error) {
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
      await dispatch({
        type: ADD_UPDATE_DELIVERY_VEHICLE_ERROR,
        payload: {
          pricingLoading: false,
          error: "Something went wrong! " + error
        }
      });
      dispatch(setLoaderComplete('DeliveryVehicleForm'));
      return {
        status: 400
      };
    }
  }
};