import gql from 'graphql-tag';
import showToaster from '../../helpers/showToaster';
import setOperationHours from './setOperationHours';

function updateOperationHours(values) {
    let query = gql`query getOperationHours {
        getOperationHours{
            results{
                id
                shopId
                operationDay,
                isOpen,
                isAllHours
                startTime,
                endTime
            }
        }
    }`;

    const mutation = gql`mutation ($operationHours: String){
        updatePartnerOperatingHours(operationHours: $operationHours){
            status
            errorMessage
        }
    }`;

    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation,
                variables: {
                    operationHours: values
                },
                refetchQueries: [{ query }]
            });

            if (data && data.updatePartnerOperatingHours && data.updatePartnerOperatingHours.status == 200) {
                let operationHours = JSON.parse(values);
                dispatch(setOperationHours(operationHours));
                showToaster({ messageId: 'updateOperationHour', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updatePartnerOperatingHours?.errorMessage })
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
        }
    }
}

export default updateOperationHours;