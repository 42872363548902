
import React from 'react';
import Layout from '../../../components/Shop/StoreLayout/Layout/Layout';
import StoreHome from './StoreHome';
import messages from '../../../locale/messages';

function action({ store, intl }) {

  let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;
  const title = intl.formatMessage(messages.store);

  if (isStoreAuthenticated) {
    return { redirect: '/store/dashboard' };
  };

  return {
    title,
    component: (
      <Layout>
        <StoreHome title={title} />
      </Layout>
    ),
  };
}

export default action;
