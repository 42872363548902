import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { updatePreparationTimeMutation as mutation } from '../../../lib/graphql';
import {
    SHOP_PREPARATION_TIME_UPDATE_ERROR,
    SHOP_PREPARATION_TIME_UPDATE_START,
    SHOP_PREPARATION_TIME_UPDATE_SUCCESS
} from '../../../constants';

const updatePreparationTime = (shopEstimatedTime, preparationTime) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_PREPARATION_TIME_UPDATE_START
            });

            dispatch(setLoaderStart('StorePreparationTime'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    shopEstimatedTime,
                    preparationTime,
                }
            });

            if (data?.updatePreparationTime?.status == 200) {
                showToaster({ messageId: 'updatePreparationTime', toasterType: 'success' })
                await dispatch({
                    type: SHOP_PREPARATION_TIME_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('StorePreparationTime'));
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updatePreparationTime?.errorMessage });
                await dispatch({
                    type: SHOP_PREPARATION_TIME_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('StorePreparationTime'));
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
            await dispatch({
                type: SHOP_PREPARATION_TIME_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('StorePreparationTime'));
        }
    }
};

export default updatePreparationTime;