import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';

import ViewChatDetails from '../../../components/SiteAdmin/ViewChatDetails';
import Loader from '../../../components/Common/Loader/Loader';
import NotFound from '../../not-found/NotFound';

import getAllThreadItemsQuery from './getAllThreadItems.graphql';
import getMoreThreads from './getMoreThreads.graphql';

import s from './ViewChatting.css';

class ViewChatting extends Component {
    static propTypes = {
        bookingId: PropTypes.number.isRequired,
        from: PropTypes.string.isRequired,
    }

    loadMore = () => {
        const { threadItems: { getAllThreadItems, fetchMore }, bookingId } = this.props;
        fetchMore({
            query: getMoreThreads,
            variables: {
                bookingId,
                offset: getAllThreadItems.threadItems.length,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) { return previousResult; }
                return {
                    getAllThreadItems: {
                        ...previousResult.getAllThreadItems,
                        threadItems: [...previousResult.getAllThreadItems.threadItems, ...fetchMoreResult.getMoreThreads.threadItems],
                    },
                };
            },
        });
    }

    render() {

        const { threadItems, from, threadItems: { loading, getAllThreadItems }, title } = this.props;
        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    {!loading && getAllThreadItems.threadItems.length > 0 && ['completed-bookings', 'failed-bookings', 'bookings'].includes(from) ?
                        <div className={s.container}>
                            <div className={s.paddingRoutesSection}>
                                <ViewChatDetails threadItems={threadItems} from={from} loadMore={this.loadMore} />
                            </div>
                        </div> : !loading && <NotFound title='Page Not Found' />}
                </div>
            </Loader>
        );
    }
}

export default compose(
    injectIntl,
    withStyles(s),
    graphql(getAllThreadItemsQuery, {
        name: 'threadItems',
        options: (props) => ({
            variables: {
                offset: 0,
                bookingId: props.bookingId
            },
            fetchPolicy: 'network-only',
            ssr: false
        })
    })
)(ViewChatting);