import showToaster from '../../../helpers/showToaster';
import { loadAccount } from '../userAccount/userAccount';
import { getAllSubMenu as query, updateSubMenuStatus as mutation } from '../../../lib/graphql';

export default function updateSubMenuStatus(id, status, currentPage, menuId) {
  return async (dispatch, getState, { client }) => {

    try {
      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          status: status == 'false' ? 0 : 1
        },
        refetchQueries: [{
          query,
          variables: {
            currentPage,
            menuId
          }
        }]
      });

      if (data?.updateSubMenuStatus?.status === 200) {
        showToaster({ messageId: 'updateSubMenu', toasterType: 'success' });
        dispatch(loadAccount());
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateSubMenuStatus?.errorMessage });
      }

    } catch (error) {
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
    }
  }
};
