import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import ReactGoogleMapLoader from "react-google-maps-loader";
import { injectIntl, FormattedMessage } from 'react-intl';
import Geosuggest from '@ubilabs/react-geosuggest';
import withStyles from 'isomorphic-style-loader/withStyles';

import { googleMapAPI } from '../../../config';
import messages from '../../../locale/messages';

import s from '@ubilabs/react-geosuggest/module/geosuggest.css';
import t from './PlacesSuggest.css'

class PlacesSuggest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      noResult: false,
      noInput: false
    };

    this.handleSelectSuggest = this.handleSelectSuggest.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  };

  async handleSelectSuggest(data) {
    const { handleData } = this.props;
    this.setState({ noResult: false });
    handleData(data);
  };

  async handleChange(input) {
    this.setState({ noInput: !input })
  }

  async handleBlur(input) {
    this.setState({ noInput: !input })
  }

  render() {
    const { label, className, containerClassName, defaultValue, form } = this.props;
    const { noResult, noInput } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <ReactGoogleMapLoader
          params={{
            key: googleMapAPI,
            libraries: "places"
          }}
          render={googleMaps =>
            googleMaps && (
              <Geosuggest
                ref={el => this._geoSuggest = el}
                placeholder={label}
                inputClassName={className}
                initialValue={defaultValue}
                className={containerClassName}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onSuggestSelect={this.handleSelectSuggest}
                onSuggestNoResults={(value) => {
                  value && this.setState({ noResult: true })
                }}
              />
            )}
        />

        {form == 'signup' ?
          noResult && <span className={'arrow_box'}>{formatMessage(messages.invalidAddress)}</span>
          :
          noResult && <span className={'placeError'}>{formatMessage(messages.invalidAddress)}</span>
        }

        {form == 'signup' ?
          noInput && <span className={'arrow_box'}>{formatMessage(messages.required)}</span>
          :
          noInput && <span className={'placeError'}>{formatMessage(messages.required)}</span>
        }
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  change
};

export default injectIntl(withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(PlacesSuggest)));
