import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import NavLink from '../../../NavLink/NavLink';
import cx from 'classnames';

// Style
import s from './HeaderBeforeLogin.css';

// Component
import LoginModal from '../../LoginModal/LoginModal';
import ForgotPasswordModal from '../../ForgotPassword/ForgotPasswordModal';
import HeaderModal from '../../../HeaderModal/HeaderModal';
import Link from '../../../Link/Link';

// Action
import { openLoginModal } from "../../../../actions/modalActions";
import { openHeaderModal } from '../../../../actions/siteadmin/modalActions';

// Local
import messages from '../../../../locale/messages'
import languageIcon from '../../../../../public/Icons/Language-black.svg';

// helper
import { formatLocale } from '../../../../helpers/formatLocale';

export class HeaderBeforeLogin extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const { openHeaderModal } = this.props;
        openHeaderModal('languageModal');
    }

    render() {
        const { openLoginModal, currentLocale } = this.props;
        return (
            <div>
                <LoginModal />
                <ForgotPasswordModal />
                <HeaderModal />
                <Nav className={cx(s.navigationLink)}>
                    <NavLink noLink
                        onClick={(e) => this.handleChange(e)}
                        className={cx(s.siteColor, s.cursurPointer, 'homeHeaderMenuRightAlign')}>
                        <span className={cx('languageIcon', 'languageIconRTL')}><img src={languageIcon} /></span>
                        <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding)}>
                            {formatLocale(currentLocale)}
                        </span>
                    </NavLink>
                    <NavLink to="/" className={cx('homeHeaderMenuRightAlign')}><FormattedMessage {...messages.home} /></NavLink>
                    <NavLink to="/contact" className={cx('homeHeaderMenuRightAlign')}><FormattedMessage {...messages.contact} /></NavLink>
                    <NavLink to="#" onClick={openLoginModal} className={cx('homeHeaderMenuRightAlign')}><FormattedMessage {...messages.signin} /></NavLink>
                </Nav>
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    currentLocale: state.intl.locale
});

const mapDispatchToProps = {
    openLoginModal,
    openHeaderModal
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBeforeLogin);