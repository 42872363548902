import { addCategory } from '../../../../actions/siteadmin/Category/addCategory';
import showToaster from '../../../../helpers/showToaster';

async function submit(values, dispatch) {
    if (!values.categoryImage) {
        showToaster({ messageId: 'addCategoryImg', toasterType: 'error' })
        return;
    }

    await dispatch(
        addCategory(
            values.categoryName,
            values.isActive,
            values.categoryImage,
            values.description
        )
    )
}

export default submit;