import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements'
import Stripe from './Stripe';
import { formValueSelector } from 'redux-form';
import PayPal from './PayPal';

class PayoutConfirm extends Component {

  render() {
    const { previousPage, paymentMethodId } = this.props;

    return (
      <>
        {
          paymentMethodId == 2 ? <Elements>
            <Stripe
              previousPage={previousPage}
            />
          </Elements> : <PayPal previousPage={previousPage} />

        }
      </>
    );
  }
};

const selector = formValueSelector('PayoutForm');


const mapState = (state) => ({
  paymentMethodId: selector(state, 'methodId')

});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(PayoutConfirm);