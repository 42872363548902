
import React from 'react';
import PayoutContainer from './PayoutContainer';
import { getPayouts } from '../../../../actions/shop/payout/getPayouts';
import { setLoaderComplete } from '../../../../actions/loader/loader';
import DashboardLayout from '../../../../components/Shop/StoreLayout/Layout/DashboardLayout';
import messages from '../../../../locale/messages';

async function action({ store, query, intl }) {
  const title = intl.formatMessage(messages.payoutPreferences);

  let isStoreAuthenticated = store.getState().runtime.isStoreAuthenticated;
  let currentAccountId = query && query.account;
  let country = query && query.country;

  if (!isStoreAuthenticated) {
    return { redirect: '/store' };
  }

  await store.dispatch(getPayouts(currentAccountId, country));
  await store.dispatch(setLoaderComplete('addPayoutForm'));

  return {
    title,
    component: (
      <DashboardLayout>
        <PayoutContainer title={title} />
      </DashboardLayout>
    )
  };

};

export default action;
