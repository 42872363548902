import {
	SHOP_ITEM_UPDATE_START,
	SHOP_ITEM_UPDATE_SUCCESS,
	SHOP_ITEM_UPDATE_ERROR
} from '../../../constants/index';
import { initialize } from 'redux-form';
import getItemQuery from './getItem.graphql';
import showToaster from '../../../helpers/showToaster';

export default function editItem(id) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: SHOP_ITEM_UPDATE_START,
			payload: {
				updateLoading: true
			}
		});

		try {

			const { data: { getItem } } = await client.query({
				query: getItemQuery,
				variables: {
					id
				},
				fetchPolicy: 'network-only',
			});

			if (getItem) {
				dispatch(initialize('AddItemForm', getItem));
				dispatch({
					type: SHOP_ITEM_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
			} else {
				dispatch({
					type: SHOP_ITEM_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: getItem?.errorMessage })
			}
		} catch (err) {
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err })
			dispatch({
				type: SHOP_ITEM_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};
