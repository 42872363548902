import showToaster from '../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { updateHomePageHome as mutation } from '../../lib/graphql';
import {
    HOMEPAGE_SETTINGS_UPLOAD_START,
    HOMEPAGE_SETTINGS_UPLOAD_ERROR,
    HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index';

const updateHomepageSettingsHome = (values) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_START
            });

            dispatch(setLoaderStart('HomeSettingsForm'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    homeSectionImage1: values?.homeSectionImage1,
                    homeSectionImage2: values?.homeSectionImage2,
                    homeSectionImage3: values?.homeSectionImage3,
                    homeSectionImage4: values?.homeSectionImage4,
                    homeSectionImage5: values?.homeSectionImage5,
                    homeSectionImage6: values?.homeSectionImage6,
                    homeSectionDescription: values?.homeSectionDescription,
                    homeSectionTitle1: values?.homeSectionTitle1,
                }
            });

            dispatch(setLoaderComplete('HomeSettingsForm'));

            if (data?.updateHomePageHome?.status == 200) {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
                });
                showToaster({ messageId: 'updateHomePage', toasterType: 'success' });
            } else {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
                });
                showToaster({ messageId: 'commonError', toasterType: 'error' });
            }

        } catch (err) {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
            });
            showToaster({ messageId: 'commonError', toasterType: 'error' });
        }
    }
};

export default updateHomepageSettingsHome;