import showToaster from '../../helpers/showToaster';
import { removeImage } from '../../helpers/removeImage';
import { uploadLicenceFrontImage as mutation } from '../../lib/graphql';
import {
  LICENCE_IMAGE_UPLOAD_ERROR,
  LICENCE_IMAGE_UPLOAD_START,
  LICENCE_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index';

const uploadLicenceFrontImage = (id, licenceFront, oldImageFront) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: LICENCE_IMAGE_UPLOAD_START
      })

      const { data } = await client.mutate({
        mutation,
        variables: {
          userId: id,
          licenceFront
        }
      })

      let url = "/deleteLicenceImage";

      if (oldImageFront !== undefined) {
        removeImage({ url, fileName: oldImageFront });
      };

      if (data?.uploadLicenceFrontImage?.status == 200) {
        dispatch({
          type: LICENCE_IMAGE_UPLOAD_SUCCESS
        });
        showToaster({ messageId: 'uploadLicenseImage', toasterType: 'success' });
      } else {
        dispatch({
          type: LICENCE_IMAGE_UPLOAD_ERROR
        });
      }

    } catch (err) {
      dispatch({
        type: LICENCE_IMAGE_UPLOAD_ERROR
      });
    }
  }
};

export default uploadLicenceFrontImage;