import gql from "graphql-tag";

export const createAdminRoleMutation = gql`mutation($id: Int, $name: String!, $description: String, $privileges: [Int]!) {
    createAdminRole(id: $id, name: $name, description: $description, privileges: $privileges) {
        status
        errorMessage
    }
}`;

export const deleteAdminRoleMutation = gql`mutation($id: Int!) {
    deleteAdminRole(id: $id) {
        status
        errorMessage
    }
}`;

export const getPrivilegesQuery = gql`query {
    getPrivileges {
        results{
            id
            privilege
            permittedUrls
        }
        status
        errorMessage
    }
}`;

export const getAllAdminRoles = gql`query($currentPage: Int, $searchList: String) {
    getAllAdminRoles(currentPage: $currentPage, searchList: $searchList) {
        count
        status
        errorMessage
      results{
            id
            name
            description
            createdAt
            updatedAt
            privileges
        }
    }
}`;