import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { deletePromoCode as mutation, getPromoCodes as query } from '../../../lib/graphql';
import {
  DELETE_PROMOCODE_START,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_ERROR
} from '../../../constants';

export const deletePromoCode = (id) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: DELETE_PROMOCODE_START,
        payload: {
          promoCodeLoading: true
        }
      });
      dispatch(setLoaderStart('deletePromoCode'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id
        },
        refetchQueries: [
          {
            query,
            variables: {
              currentPage: 1
            }
          }
        ]
      });

      if (data?.deletePromoCode?.status === 200) {
        showToaster({ messageId: 'deletePromoCode', toasterType: 'success' });
        await dispatch({
          type: DELETE_PROMOCODE_SUCCESS,
          payload: {
            promoCodeLoading: false
          }
        });
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.deletePromoCode?.errorMessage });
        await dispatch({
          type: DELETE_PROMOCODE_ERROR,
          payload: {
            promoCodeLoading: false,
            error: data?.deletePromoCode?.errorMessage
          }
        });
      }

      dispatch(setLoaderComplete('deletePromoCode'));

    } catch (error) {
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
      await dispatch({
        type: DELETE_PROMOCODE_ERROR,
        payload: {
          promoCodeLoading: false,
          error: "Something went wrong! " + error
        }
      });
      dispatch(setLoaderComplete('deletePromoCode'));
    }
  }
};