import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import Container from 'react-bootstrap/Container';

import Loader from '../../../Common/Loader/Loader';
import ImageUploadComponent from '../../../ImageUploadComponent/ImageUploadComponent';

import validate from './validate';
import submit from './submit';
import messages from '../../../../locale/messages';
import { api, partnerHomepageUploadDir } from '../../../../config';

import s from './InfoForm.css';

export class InfoForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }

    render() {
        const { handleSubmit, infoImage1, infoImage2, infoImage3, loading, submitting } = this.props;
        const { infoImage1Loader, infoImage2Loader, infoImage3Loader } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <h1 className={cx(s.titleTextAdmin, 'textRightRTL')}>{formatMessage(messages.infoSection)}</h1>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="infoTitle4" type="text" component={this.renderField} label={formatMessage(messages.title1Label)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="infoContent4" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description1Label)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <div>
                                                            <ImageUploadComponent
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                loaderName={'infoImage1Loader'}
                                                                postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                                                loader={infoImage1Loader}
                                                                fieldName={'infoImage1'}
                                                                formName={'InfoForm'}
                                                                imageSrc={infoImage1 ? api.apiEndpoint + partnerHomepageUploadDir + infoImage1 : null}
                                                                inputContainer={'.dzInputContainerStoreImage'}
                                                                inputContainerClass={'dzInputContainerStoreImage'}
                                                                label={formatMessage(messages.image1Label)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <Field name="infoTitle1" type="text" component={this.renderField} label={formatMessage(messages.imageTitle1Label)} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <div>
                                                            <ImageUploadComponent
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                loaderName={'infoImage2Loader'}
                                                                postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                                                loader={infoImage2Loader}
                                                                fieldName={'infoImage2'}
                                                                formName={'InfoForm'}
                                                                imageSrc={infoImage2 ? api.apiEndpoint + partnerHomepageUploadDir + infoImage2 : null}
                                                                inputContainer={'.dzInputContainerStoreImage'}
                                                                inputContainerClass={'dzInputContainerStoreImage'}
                                                                label={formatMessage(messages.image2Label)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="infoTitle2" type="text" component={this.renderField} label={formatMessage(messages.imageTitle2Label)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <div>
                                                            <ImageUploadComponent
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                loaderName={'infoImage3Loader'}
                                                                postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                                                loader={infoImage3Loader}
                                                                fieldName={'infoImage3'}
                                                                formName={'InfoForm'}
                                                                imageSrc={infoImage3 ? api.apiEndpoint + partnerHomepageUploadDir + infoImage3 : null}
                                                                inputContainer={'.dzInputContainerStoreImage'}
                                                                inputContainerClass={'dzInputContainerStoreImage'}
                                                                label={formatMessage(messages.image3Label)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="infoTitle3" type="text" component={this.renderField} label={formatMessage(messages.imageTitle3Label)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textLeftRTL', 'borderBottomRTL')}>
                                                <Form.Group className={s.noMargin}>
                                                    <div className={s.displayInlineBlock}>
                                                        <Loader
                                                            type={"button"}
                                                            label={formatMessage(messages.submitButton)}
                                                            show={loading}
                                                            buttonType={'submit'}
                                                            className={cx(s.button, s.btnPrimary)}
                                                            disabled={submitting || loading}
                                                            isSuffix={true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

InfoForm = reduxForm({
    form: 'InfoForm',
    onSubmit: submit,
    validate
})(InfoForm);

const selector = formValueSelector('InfoForm')

const mapState = (state) => ({
    infoImage1: selector(state, 'infoImage1'),
    infoImage2: selector(state, 'infoImage2'),
    infoImage3: selector(state, 'infoImage3'),
    loading: state.loader.UpdatePartnerHomepage,
    infoImage1Loader: state.loader.infoImage1Loader,
    infoImage2Loader: state.loader.infoImage2Loader,
    infoImage3Loader: state.loader.infoImage3Loader
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(InfoForm)));