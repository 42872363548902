import showToaster from '../../../helpers/showToaster';
import { updateConfigSettingsMutation } from '../../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { siteSettings } from '../siteSettings';
import {
    UPDATE_CONFIG_SETTINGS_START,
    UPDATE_CONFIG_SETTINGS_SUCCESS,
    UPDATE_CONFIG_SETTINGS_ERROR,
} from '../../../constants';

export const updateConfigSettings = (values) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: UPDATE_CONFIG_SETTINGS_START,
            });

            dispatch(setLoaderStart('configSettings'));

            const { data } = await client.mutate({
                mutation: updateConfigSettingsMutation,
                variables: values,
            })

            dispatch(setLoaderComplete('configSettings'));

            if (data?.updateConfigSettings?.status == 200) {
                dispatch({ type: UPDATE_CONFIG_SETTINGS_SUCCESS });
                showToaster({ messageId: 'configSettings', toasterType: 'success' });
                await dispatch(siteSettings());
            } else {
                dispatch({ type: UPDATE_CONFIG_SETTINGS_ERROR });
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateConfigSettings?.errorMessage });
            }
        }

        catch (error) {
            dispatch({ type: UPDATE_CONFIG_SETTINGS_ERROR });
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
        }
    }
};
