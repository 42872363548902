import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl } from 'react-bootstrap';
import s from './AutoPayout.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import messages from '../../../locale/messages';
import CustomPagination from '../../CustomPagination';
import PropTypes from 'prop-types';
import CurrencyConverter from '../../CurrencyConverter'
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirst';
import { tripStatus } from '../../../helpers/tripStatus';
import { updatePayoutStatus } from '../../../actions/siteadmin/Payout/updatePayout';
import { connect } from 'react-redux';
import Link from '../../Link/Link';

export class AutoPayoutList extends Component {

    static propTypes = {
        getPayoutData: PropTypes.object,
        updatePayoutStatus: PropTypes.any.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            currentPage: 1,
        }
        this.paginationData = this.paginationData.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.holdPayout = this.holdPayout.bind(this);
    }

    handleSearchClick(searchList) {
        const { getPayoutData: { refetch }} = this.props
        let variables = {
          currentPage: 1,
          searchList: searchList
        }
        this.setState({ currentPage: 1 })
        refetch(variables)
    }
    
    handleSearchChange(e) {
        let self = this
        if(self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout)
        }
        self.setState({
            searchList: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function() {
            self.handleSearchClick(self.state.searchList)
            }, 450)
        })
    }

    paginationData(currentPage) {
        const { getPayoutData: { refetch } } = this.props;
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }

    holdPayout(e,id, currentPage) {
        
        const { updatePayoutStatus, getPayoutData: { refetch } } = this.props;
        let isBanStatus = (e.target.value);
        
        if(isBanStatus == "true" || isBanStatus == "false"){    
            isBanStatus = (isBanStatus =="true")? true:false;
            updatePayoutStatus(id,isBanStatus);
        }

        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
        
    }

    render() {
        const { getPayoutData, getPayoutData: { getPayoutList } } = this.props;
        const { currentPage } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <div className={s.widthInner}>
                <div className={s.searchInput}>
                <FormControl type='text' placeholder='Search' onChange={(e) => this.handleSearchChange(e)}  className={s.formControlInput} />
                </div>
                <div className={cx(s.tableCss, 'tableCss', 'tableSticky')}>
                    <Table className="table">
                        <thead>
                            <tr>
                                <th><FormattedMessage {...messages.id} /></th>
                                <th><FormattedMessage {...messages.driverName} /></th>
                                <th><FormattedMessage {...messages.email} /></th>
                                <th><FormattedMessage {...messages.amount} /></th>
                                <th><FormattedMessage {...messages.payoutStatus} /></th>
                                {/* <th><FormattedMessage {...messages.action} /></th> */}
                                <th><FormattedMessage {...messages.holdPayout} /></th>
                                <th><FormattedMessage {...messages.details} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length > 0 && getPayoutData.getPayoutList.bookingData.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item && item.driverDetails && capitalizeFirstLetter(item.driverDetails.firstName)}</td>
                                            <td>{item && item.driverDetails && item.driverDetails.userData && item.driverDetails.userData.email}</td>
                                            <td><CurrencyConverter from={item.currency} amount={item.totalFare}/></td>
                                            <td>{(item.paymentType===1)?'Payout processed by cash': (item.isPayoutPaid && item.isPayoutPaid == true) ? 'Payout processed' : 'Payout pending'}</td>
                                            {/* <td>{(item.paymentType===1)?'cash':'Not Required'}</td> */}
                                            {
                                            <td>
                                                <select value={item.isBanStatus} onChange={(e) => {this.holdPayout(e,item.id, currentPage)}} className={s.selectInput}>
                                                    <option value={true}>{formatMessage(messages.yes)}</option>
                                                    <option value={false}>{formatMessage(messages.no)}</option>
                                                </select>
                                            </td>
                                            }
                                            <td><Link to={'/siteadmin/view-booking/' + item.id + '?' + 'from=autopayout'}><FormattedMessage {...messages.view} /></Link></td>
                                        </tr>
                                    )
                                })
                            }
                            {
                               getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length == 0 && (   
                                <tr>
                                    <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
                {
                        getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length > 0
                        && <div className={cx(s.space5, s.spaceTop5)}>
                            <CustomPagination
                                total={getPayoutData.getPayoutList.count}
                                currentPage={currentPage}
                                defaultCurrent={1}
                                defaultPageSize={10}
                                change={this.paginationData}
                                paginationLabel={'Driver Payout'}
                            />
                        </div>
                    }
            </div>
        )
    }
}

const mapDispatch = {
    updatePayoutStatus
};
const mapState = (state) => ({});

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AutoPayoutList)));

