import { userAccountQuery as query } from '../../../lib/graphql';
import {
    SET_USER_DATA_START,
    SET_USER_DATA_SUCCESS,
    SET_USER_DATA_ERROR
} from '../../../constants';

export const loadAccount = () => {

    return async (dispatch, getState, { client }) => {

        try {
            let userAccountState, updatedProfileData;

            dispatch({
                type: SET_USER_DATA_START,
            });

            const { data } = await client.query({
                query,
                fetchPolicy: 'network-only'
            });

            if (data?.userAccount) {
                userAccountState = getState().account?.data
                updatedProfileData = { ...userAccountState, ...data?.userAccount }
                dispatch({
                    type: SET_USER_DATA_SUCCESS,
                    updatedProfileData
                });
            };

        } catch (error) {
            dispatch({
                type: SET_USER_DATA_ERROR,
            });
        }
    }
};
