import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import Container from 'react-bootstrap/Container';

import Loader from '../../../Common/Loader/Loader';
import ImageUploadComponent from '../../../ImageUploadComponent/ImageUploadComponent';

import validate from './validate';
import submit from './submit';
import messages from '../../../../locale/messages';
import { api, partnerHomepageUploadDir } from '../../../../config';

import s from './ContactForm.css';

export class ContactForm extends Component {

	constructor(props) {
		super(props)

		this.handleDropzone = this.handleDropzone.bind(this);
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<div>
				<FormGroup className={s.space3}>
					<div>
						<label className={s.labelText} >{label}</label>
					</div>
					<div>
						<FormControl
							{...input}
							className={className}
							placeholder={label}
							as="textarea"
							rows="4"
						>
							{children}
						</FormControl>
						{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
					</div>
				</FormGroup>
			</div>
		);
	}



	handleDropzone(fileName, fieldName) {
		const { change } = this.props

		change(fieldName, fileName)
	};

	render() {
		const { handleSubmit, sliderImage1, sliderImage2, sliderImage3, sliderImage5, loading, submitting, sliderImage5Loader } = this.props;
		const { formatMessage } = this.props.intl;
		return (

			<div className={cx(s.space5, s.spaceTop5)}>
				<Container fluid>
					<Row>
						<Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
							<div className={s.blackCenterSection}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<Col lg={12} md={12} sm={12} xs={12}>
										<h1 className={cx(s.titleTextAdmin, 'textRightRTL')}>{formatMessage(messages.sliderContact)}</h1>
									</Col>
									<Form.Group className={cx(s.space3, s.imageSection)}>
										<div className={cx(s.profileImgSection, s.profileImgWidth)}>
											<div>
												<ImageUploadComponent
													className={cx(s.btnSecondary, s.profileNoPadding)}
													subTextClass={s.subText}
													subText={formatMessage(messages.maximumUploadSizeLabel)}
													defaultMessage={formatMessage(messages.chooseFile)}
													loaderName={'sliderImage5Loader'}
													postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
													loader={sliderImage5Loader}
													fieldName={'sliderImage5'}
													formName={'ContactForm'}
													imageSrc={sliderImage5 ? api.apiEndpoint + partnerHomepageUploadDir + 'medium_' + sliderImage5 : null}
													inputContainer={'.dzInputContainerStoreImage'}
													inputContainerClass={'dzInputContainerStoreImage'}
													label={formatMessage(messages.sliderContactImage)}
												/>
											</div>
										</div>
									</Form.Group>
									<Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop6}>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<Field name="sliderTitle5" type="text" component={this.renderField} label={formatMessage(messages.contactUsTitle)} />
												</Form.Group>
											</Col>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<Field name="buttonName" type="text" component={this.renderField} label={formatMessage(messages.buttonName)} />
												</Form.Group>
											</Col>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<Field name="buttonLink" type="text" component={this.renderField} label={formatMessage(messages.buttonLink)} />
												</Form.Group>
											</Col>
										</Row>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textLeftRTL', 'borderBottomRTL')}>
										<Form.Group className={s.noMargin}>
											<div className={s.displayInlineBlock}>
												<Loader
													type={"button"}
													label={formatMessage(messages.submitButton)}
													show={loading}
													buttonType={'submit'}
													className={cx(s.button, s.btnPrimary)}
													disabled={submitting || loading}
													isSuffix={true}
												/>
											</div>
										</Form.Group>
									</Col>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

ContactForm = reduxForm({
	form: 'ContactForm',
	onSubmit: submit,
	validate
})(ContactForm);

const selector = formValueSelector('ContactForm')

const mapState = (state) => ({
	sliderImage1: selector(state, 'sliderImage1'),
	sliderImage2: selector(state, 'sliderImage2'),
	sliderImage3: selector(state, 'sliderImage3'),
	sliderImage5: selector(state, 'sliderImage5'),
	loading: state.loader.UpdatePartnerHomepage,
	sliderImage5Loader: state.loader.sliderImage5Loader
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ContactForm)));