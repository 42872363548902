import axios from 'axios';
import { change } from 'redux-form';
import { openSmsVerificationModal } from './smsVerificationModaAction';
import { updatePhoneNumber as mutation } from '../../../lib/graphql';
import {
    SEND_VERIFICATION_CODE_START,
    SEND_VERIFICATION_CODE_SUCCESS,
    SEND_VERIFICATION_CODE_ERROR
} from '../../../constants';

const sendVerification = (phoneNumber, phoneDialCode, userId) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SEND_VERIFICATION_CODE_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    phoneNumber,
                    phoneDialCode
                }
            });

            if (data?.updatePhoneNumber?.status == 200) {
                dispatch(change("EditProfileForm", 'phoneNumber', phoneNumber));
                let variables = {
                    dialCode: phoneDialCode,
                    id: userId,
                    phoneNumber
                }

                const response = await axios.post('/send-verification-code', variables, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                })

                if (response?.data?.status == 200) {
                    await dispatch(openSmsVerificationModal("verifyPhoneNumberForm"));
                    dispatch({
                        type: SEND_VERIFICATION_CODE_SUCCESS
                    });
                    return {
                        status: 200
                    };
                } else {
                    dispatch({
                        type: SEND_VERIFICATION_CODE_ERROR
                    });
                    return {
                        status: 400,
                        errorMessage: response?.data?.errorMessage
                    };
                }

            } else {
                dispatch({
                    type: SEND_VERIFICATION_CODE_ERROR
                });
                return {
                    status: data?.updatePhoneNumber?.status,
                    errorMessage: data?.updatePhoneNumber?.errorMessage
                };
            }

        } catch (error) {
            return false;
        }
    }
};

export default sendVerification;
