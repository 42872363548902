import { change } from 'redux-form';
import showToaster from '../../../helpers/showToaster';
import { getModifierQuery } from '../../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import {
    ADDITEM_MODIFIER_START,
    ADDITEM_MODIFIER_SUCCESS,
    ADDITEM_MODIFIER_ERROR
} from '../../../constants/index';

const getExistingModifier = (id) => {
    return async (dispatch, getState, { client }) => {

        try {
            let updatedModifierData = [], existingModifierData = [], modifiers = {}, arrayData = [];

            dispatch({
                type: ADDITEM_MODIFIER_START,
            });
            dispatch(setLoaderStart('updateLoading'));

            const { data: { getModifier } } = await client.query({
                query: getModifierQuery,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });

            if (getModifier?.status == 200) {
                if (getModifier?.result?.isActive === "true") {
                    arrayData.push(getModifier?.result);
                    modifiers['modifierGroups'] = arrayData;
                } else {
                    await dispatch(setLoaderComplete('updateLoading'));
                    showToaster({ messageId: 'getModifier', toasterType: 'error' });
                    return;
                }
            } else {
                await dispatch(setLoaderComplete('updateLoading'));
                showToaster({ messageId: 'getModifier', toasterType: 'error' });
                return;
            }

            if (getState().form?.AddItemForm?.values) {
                existingModifierData = getState().form?.AddItemForm?.values?.modifiers;
            }

            if (existingModifierData?.length > 0) {
                existingModifierData?.map((item, key) => {
                    updatedModifierData?.push(item);
                });
            }

            updatedModifierData?.push(modifiers);
            dispatch({
                type: ADDITEM_MODIFIER_SUCCESS,
            });
            await dispatch(change('AddItemForm', 'modifiers', updatedModifierData));
            await dispatch(setLoaderComplete('updateLoading'));

        } catch (error) {
            dispatch({
                type: ADDITEM_MODIFIER_ERROR,
            });
            await dispatch(setLoaderComplete('updateLoading'));
        }
    }
};

export default getExistingModifier;