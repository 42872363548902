import socketNotification from '../../../core/socket/socketNotification';
import showToaster from '../../../helpers/showToaster';
import { deleteAdminUserMutation, getAllAdminUsers as query } from '../../../lib/graphql';
import { closeAdminUserModal } from '../modalActions';
import {
    DELETE_ADMIN_USER_START,
    DELETE_ADMIN_USER_SUCCESS,
    DELETE_ADMIN_USER_ERROR,
} from '../../../constants';

export const deleteAdminUser = (id) => {
    return async (dispatch, getState, { client }) => {

        try {
            let requestData = { id };

            await dispatch({
                type: DELETE_ADMIN_USER_START,
                payload: {
                    deleteAdminUserLoading: true
                }
            });

            const { data } = await client.mutate({
                mutation: deleteAdminUserMutation,
                variables: {
                    id
                },
                refetchQueries: [{ query }]
            });

            if (data?.deleteAdminUser?.status === 200) {
                await dispatch({
                    type: DELETE_ADMIN_USER_SUCCESS,
                    payload: {
                        deleteAdminUserLoading: false
                    }
                });
                dispatch(closeAdminUserModal());
                showToaster({ messageId: 'deleteAdminUser', toasterType: 'success' })
                socketNotification('webAdminUserLogout', JSON.stringify(requestData));
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.deleteAdminUser?.errorMessage })
                await dispatch({
                    type: DELETE_ADMIN_USER_ERROR,
                    payload: {
                        deleteAdminUserLoading: false,
                        error: data?.deleteAdminUser?.errorMessage
                    }
                });
            }

        } catch (error) {
            await dispatch({
                type: DELETE_ADMIN_USER_ERROR,
                payload: {
                    deleteAdminUserLoading: false,
                    error
                }
            });
        }
    }
};
