import { getCartModifierItemQuery } from '../../../lib/graphql';
import {
    SHOP_MODIFIER_ITEM_UPDATE_START,
    SHOP_MODIFIER_ITEM_UPDATE_SUCCESS,
    SHOP_MODIFIER_ITEM_UPDATE_ERROR
} from '../../../constants/index';

const getBookedModifierItem = (modifierItemId) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_MODIFIER_ITEM_UPDATE_START,
            });

            const { data: { getCartModifierItem } } = await client.query({
                query: getCartModifierItemQuery,
                variables: {
                    modifierItemId
                }
            });

            if (getCartModifierItem?.status === 200) {
                dispatch({
                    type: SHOP_MODIFIER_ITEM_UPDATE_SUCCESS,
                });

                return {
                    data: getCartModifierItem?.result?.removeModifierItemId
                };
            }

        } catch (error) {
            dispatch({
                type: SHOP_MODIFIER_ITEM_UPDATE_ERROR,
            });
        }
    }
};

export default getBookedModifierItem;