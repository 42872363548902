import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import messages from '../../../locale/messages';

import validate from './validate';
import submit from './submit';

import ShowPassword from '../../../../public/Icons/pswVisible.svg';
import HidePassword from '../../../../public/Icons/pwdHidden.svg';

import s from './Password.css';
import rs from '../../storeCommon.css';

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    };
  }

  handleChange(fieldName) {
    this.setState({ [fieldName]: !this.state[fieldName] });
  }

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    autocomplete,
    showPassword,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup
        className={cx(
          s.formGroup,
          rs.displayGridPrifile,
          rs.alignItemCenter,
          'textRightRTL',
        )}
      >
        <div className={s.changePassPadding}>
          <Form.Label className={rs.storeLabelText}>{label}</Form.Label>
        </div>
        <div className="positionRelative">
          <FormControl
            {...input}
            placeholder={label}
            type={showPassword ? 'input' : type}
            className={className}
            autocomplete={autocomplete}
          />
          {type == 'password' && (
            <span
              onClick={() => this.handleChange(input.name)}
              className={cx(rs.pwdShowHideIcon, 'pwdShowHideIconRTL')}
            >
              {showPassword ? (
                <img src={ShowPassword} />
              ) : (
                <img src={HidePassword} />
              )}
            </span>
          )}
          {touched && error && (
            <span className={rs.errorMessage}>{formatMessage(error)}</span>
          )}
        </div>
      </FormGroup>
    );
  };

  render() {
    const { error, handleSubmit, submitting } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPassword, newPassword, confirmNewPassword } = this.state;
    return (
      <div className="mainContainer">
        <Container fluid>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
              <div>
                <h1
                  className={cx(
                    s.titleBottom,
                    rs.storeTitleText,
                    'textRightRTL',
                  )}
                >
                  {formatMessage(messages.changePassword)}
                </h1>
              </div>
              <Form onSubmit={handleSubmit(submit)}>
                {error && <span className={rs.errorMessage}>{error}</span>}
                <FormGroup className={s.formGroup}>
                  <Field
                    name="currentPassword"
                    type="password"
                    label={formatMessage(messages.currentPassword)}
                    component={this.renderFormControl}
                    placeholder="Password"
                    className={rs.formControlInputStore}
                    showPassword={currentPassword}
                    autocomplete="current-password"
                  />
                </FormGroup>
                <FormGroup className={s.formGroup}>
                  <Field
                    name="newPassword"
                    type="password"
                    label={formatMessage(messages.newPassword)}
                    component={this.renderFormControl}
                    placeholder="Password"
                    className={rs.formControlInputStore}
                    showPassword={newPassword}
                    autocomplete="new-password"
                  />
                </FormGroup>
                <FormGroup className={s.formGroup}>
                  <Field
                    name="confirmNewPassword"
                    type="password"
                    label={formatMessage(messages.confirmNewPassword)}
                    component={this.renderFormControl}
                    placeholder="Password"
                    className={rs.formControlInputStore}
                    showPassword={confirmNewPassword}
                    autocomplete="new-password"
                  />
                </FormGroup>
                <FormGroup className={s.formGroup}>
                  <Button
                    className={cx(
                      rs.button,
                      rs.btnPrimary,
                      s.btnWidth,
                      'floatLeftRTL',
                    )}
                    block
                    type="submit"
                    disabled={submitting}
                  >
                    {formatMessage(messages.updatePassword)}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Password = reduxForm({
  form: 'ChangePassword',
  validate,
})(Password);

const mapState = state => ({});

const mapDispatch = {};

export default injectIntl(
  withStyles(s, rs)(connect(mapState, mapDispatch)(Password)),
);
