import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './HomeBanner.css';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';

import { injectIntl } from 'react-intl';
//Locale 
import Link from '../../Link';
import { api } from '../../../config';
import arrow from '../../../../public/Icons/right.svg'
import messages from '../../../locale/messages';

class StoreBanner extends React.Component {
    constructor(props) {
        super(props);
        this.scrollTop = this.scrollTop.bind(this);
    }

    scrollTop() {
        window.scrollTo({
            top: screen.height,
            behavior: 'smooth'
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { data } = this.props;
        let homeSettings = {};

        data && data.homePageData.map((item) => {
            homeSettings[item.name] = item.value
        });

        return (
            <div className={cx(s.layout4Container)}>
                <div className={cx(s.bannerBackgroundImage)}
                    style={{ backgroundImage: `url(${api.apiEndpoint + "/images/homepage/" + homeSettings.homeSectionImage1})` }} />
                <Container className={cx(s.bannerLayoutContainer)}>
                    <div className={s.bannerDisplayGrid}>
                        <div className={s.searchFormContainer}>
                            <div className={cx(s.searchFormWrap, 'textRightRTL')}>
                                <h1>
                                    {homeSettings.homeSectionTitle1}
                                </h1>
                                <p>
                                    {homeSettings.homeSectionDescription}
                                </p>
                                <Link to='/store' className={cx(s.storeLink, 'storeLinkRTL')}>
                                    <span>
                                        {formatMessage(messages.store)}
                                     </span>
                                    <span><img src={arrow} /></span>
                                </Link>
                            </div>
                        </div>
                        <div className={s.mobileTop}>
                            <img src={api.apiEndpoint + "/images/homepage/" + homeSettings.homeSectionImage2} class="img-fluid"/>
                        </div>

                    </div>
                </Container>
            </div>
        );
    }
}

const mapState = (state) => ({});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(StoreBanner)));