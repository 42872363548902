import history from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { updatePromoCode as updateMutation } from '../../../lib/graphql';
import {
  ADD_PROMOCODE_START,
  ADD_PROMOCODE_SUCCESS,
  ADD_PROMOCODE_ERROR
} from '../../../constants';

export const addPromoCode = (values) => {
  return async (dispatch, getState, { client }) => {
    let status, requestContent, errorMessage = 'Oops! something went wrong! Please try again.';

    try {
      dispatch({
        type: ADD_PROMOCODE_START,
        payload: {
          promoCodeLoading: true
        }
      });

      dispatch(setLoaderStart('AddPromoCode'));

      const { data } = await client.mutate({
        mutation: updateMutation,
        variables: {
          id: values?.id,
          title: values?.title,
          description: values?.description,
          code: values?.code,
          type: values?.type || 1,
          promoValue: values?.promoValue,
          currency: values?.currency,
          expiryDate: values?.expiryDate !== '' ? values?.expiryDate : null,
          isEnable: values?.isEnable,
          promoCodeImage: values?.promoCodeImage
        }
      });

      status = data?.updatePromoCode?.status;
      errorMessage = data?.updatePromoCode?.errorMessage;

      requestContent = values?.id ? 'updated' : 'added';

      if (status && status === 200) {
        history.push('/siteadmin/promo-code/list');
        showToaster({ messageId: 'updatePromoCode', toasterType: 'success', requestContent });
        await dispatch({
          type: ADD_PROMOCODE_SUCCESS,
          payload: {
            promoCodeLoading: false
          }
        });
        dispatch(setLoaderComplete('AddPromoCode'));
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error' });
        await dispatch({
          type: ADD_PROMOCODE_ERROR,
          payload: {
            promoCodeLoading: false,
            error: errorMessage
          }
        });
        dispatch(setLoaderComplete('AddPromoCode'));
      }

    } catch (error) {
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
      dispatch(setLoaderComplete('AddPromoCode'));
      await dispatch({
        type: ADD_PROMOCODE_ERROR,
        payload: {
          promoCodeLoading: false,
          error: errorMessage
        }
      });
    }
  }
};