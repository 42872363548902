import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import normalizeCss from 'normalize.css';
import s from './Layout.css';
import Footer from '../../../Footer/Footer';
import Toaster from '../../../Toaster/Toaster';
import { connect } from 'react-redux';
import StoreHeader  from '../StoreHeader/StoreHeader';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { isStore } = this.props
    return (
      <div className={'store'}>
        <Toaster />
         {/* { isStore ? <HeaderAfterLogin /> : <HeaderBeforeLogin /> } */}
         <StoreHeader />
        {this.props.children}
        <Footer /> 
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isStore: state.user.store
});

const mapDispatchToProps = {

};

export default withStyles(normalizeCss, s)(connect(mapStateToProps, mapDispatchToProps)(Layout));