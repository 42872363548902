import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { updatePartnerHomepage as mutation } from '../../../lib/graphql';
import {
    PARTNER_HOMEPAGE_UPDATE_SUCCESS,
    PARTNER_HOMEPAGE_UPDATE_START,
    PARTNER_HOMEPAGE_UPDATE_ERROR
} from '../../../constants/index';

const updatePartnerHomepage = (values) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: PARTNER_HOMEPAGE_UPDATE_START
            });

            dispatch(setLoaderStart('UpdatePartnerHomepage'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    bannerTitle1: values?.bannerTitle1,
                    bannerTitle2: values?.bannerTitle2,
                    bannerContent1: values?.bannerContent1,
                    bannerImage1: values?.bannerImage1,
                    sliderTitle1: values?.sliderTitle1,
                    sliderContent1: values?.sliderContent1,
                    sliderImage1: values?.sliderImage1,
                    sliderTitle2: values?.sliderTitle2,
                    sliderContent2: values?.sliderContent2,
                    sliderImage2: values?.sliderImage2,
                    sliderTitle3: values?.sliderTitle3,
                    sliderContent3: values?.sliderContent3,
                    sliderImage3: values?.sliderImage3,
                    sliderTitle4: values?.sliderTitle4,
                    sliderContent4: values?.sliderContent4,
                    sliderTitle5: values?.sliderTitle5,
                    infoTitle1: values?.infoTitle1,
                    infoImage1: values?.infoImage1,
                    infoTitle2: values?.infoTitle2,
                    infoImage2: values?.infoImage2,
                    infoTitle3: values?.infoTitle3,
                    infoImage3: values?.infoImage3,
                    infoTitle4: values?.infoTitle4,
                    infoContent4: values?.infoContent4,
                    buttonName: values?.buttonName,
                    buttonLink: values?.buttonLink,
                    sliderImage5: values?.sliderImage5
                }
            });

            if (data?.updatePartnerHomepage?.status == "200") {
                dispatch({
                    type: PARTNER_HOMEPAGE_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('UpdatePartnerHomepage'));
                showToaster({ messageId: 'updateHomepage', toasterType: 'success' });
            } else {
                dispatch({
                    type: PARTNER_HOMEPAGE_UPDATE_ERROR
                });
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updatePartnerHomepage?.errorMessage });
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
            dispatch({
                type: PARTNER_HOMEPAGE_UPDATE_ERROR
            });
        }
    }
};

export default updatePartnerHomepage;