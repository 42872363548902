import gql from 'graphql-tag';
import {
	ADMIN_UPDATE_STATIC_START,
	ADMIN_UPDATE_STATIC_SUCCESS,
	ADMIN_UPDATE_STATIC_ERROR
} from '../../constants';
import { api } from '../../config';
import showToaster from '../../helpers/showToaster';

export function deleteStaticPageImage(id, fileName) {

	return async (dispatch, getState, { client }) => {

		dispatch({
			type: ADMIN_UPDATE_STATIC_START,
			payload: {
				loading: true
			}
		});

		try {

			let mutation = gql`
            mutation removeStaticPage(
                $id: Int,
              ) {
                removeStaticPage(
                  id: $id,
                ) {
                    status
                }
              }
        `;

			// Send Request to create a record for logo
			const { data } = await client.mutate({
				mutation,
				variables: {
					id
				},
			});

			if (data) {
				dispatch({
					type: ADMIN_UPDATE_STATIC_SUCCESS,
					payload: {
						loading: true
					}
				});
				await removeImage(fileName);
			}

		} catch (error) {
			dispatch({
				type: ADMIN_UPDATE_STATIC_ERROR,
				payload: {
					loading: true
				}
			});

			return false;
		}

		return true;
	};
}

export default function updateStaticPageImage(id, pageBanner, oldPicture) {

	return async (dispatch, getState, { client }) => {
		try {

			dispatch({
				type: ADMIN_UPDATE_STATIC_START,
				payload: {
					loading: true
				}
			})

			const mutation = gql`
        mutation($id: Int, $pageBanner: String) {
          updateStaticPageImage(id: $id, pageBanner: $pageBanner) {
              status
          }
        }`;

			const { data } = await client.mutate({
				mutation,
				variables: {
					id,
					pageBanner
				}
			});

			if (data.updateStaticPageImage && data.updateStaticPageImage.status === "200") {
				dispatch({
					type: ADMIN_UPDATE_STATIC_SUCCESS,
					payload: {
						loading: false
					}
				});
				showToaster({ messageId: 'uploadImage', toasterType: 'success' })

				if (oldPicture !== null) {
					removeImage(oldPicture);
				};
			} else {
				dispatch({
					type: ADMIN_UPDATE_STATIC_ERROR,
					payload: {
						loading: false
					}
				})
			}


		} catch (err) {
			dispatch({
				type: ADMIN_UPDATE_STATIC_ERROR,
				payload: {
					loading: false
				}
			})
		}
	}
}

async function removeImage(fileName) {
	try {
		const url = api.apiEndpoint + "/deleteStaticBannerImage";
		const resp = await fetch(url, {
			method: 'post',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ fileName }),
		});

		const { status } = await resp.json();

		if (status) {
			return {
				status
			};
		}

	} catch (err) {
		console.log(err);
	}
}