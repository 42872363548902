import histroy from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { updateCategory as mutation } from '../../../lib/graphql';

export const editCategory = (id, categoryName, isActive, description) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch(setLoaderStart('EditCategory'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          categoryName,
          description,
          isActive: (isActive === true || 'true') ? 1 : 0
        }
      });

      dispatch(setLoaderComplete('EditCategory'));

      if (data?.updateCategory?.status === 200) {
        showToaster({ messageId: 'updateCategory', toasterType: 'success' })
        histroy.push('/siteadmin/category');
      } else if (data?.updateCategory?.status !== 200) {
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateCategory?.errorMessage })
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error' })
      }

    } catch (error) {
      return false;
    }
  }
};