import React, { Component } from 'react'
import ManageNotificationsForm from '../../../components/SiteAdmin/ManageNotificationsForm'
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ManageNotifications.css';
import getSiteSettings from './getSiteSettings.graphql'
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import Loader from '../../../components/Common/Loader/Loader';

export class ManageNotifications extends Component {
  render() {
    const { title, getSiteSettings: { loading, getSiteSettings } } = this.props
    return (
      <div>
        <Loader type={"page"} show={loading}>
            <div className={s.root}>
              {!loading && <div className={s.container}>
                <div className={s.heading}>
                  <FormattedMessage {...messages.manageNotifications} />
                </div>
                <div>
                  <ManageNotificationsForm getSiteSettings={getSiteSettings} />
                </div>
              </div>}
            </div>
          </Loader>
      </div>
    )
  }
}


export default compose(
  withStyles(s),
  graphql(getSiteSettings, {
    name: 'getSiteSettings',
    options: {
      fetchPolicy: 'network-only',
    }
  })
)(ManageNotifications)
