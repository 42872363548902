import showToaster from '../../../helpers/showToaster';
import { updateCategoryStatus as mutation } from '../../../lib/graphql';
import {
    CATEGORY_UPDATE_SUCCESS,
    CATEGORY_UPDATE_START,
    CATEGORY_UPDATE_ERROR
} from '../../../constants';

const updateCategoryStatus = (id, isActive) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: CATEGORY_UPDATE_START
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    isActive: isActive == '1' ? 1 : 0
                }
            });

            if (data?.updateCategoryStatus?.status === 200) {
                dispatch({
                    type: CATEGORY_UPDATE_SUCCESS
                });
                showToaster({ messageId: 'updateCategoryStatus', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: CATEGORY_UPDATE_ERROR
                });
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateCategoryStatus?.errorMessage })
                return {
                    status: 400
                };
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            dispatch({
                type: CATEGORY_UPDATE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};

export default updateCategoryStatus;