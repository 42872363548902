import { getCartModifierGroupQuery } from '../../../lib/graphql';
import {
    SHOP_MODIFIER_ITEM_UPDATE_START,
    SHOP_MODIFIER_ITEM_UPDATE_SUCCESS,
    SHOP_MODIFIER_ITEM_UPDATE_ERROR
} from '../../../constants/index';

const getBookedModifierGroup = (modifierGroupId) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_MODIFIER_ITEM_UPDATE_START,
            });

            const { data: { getCartModifierGroup } } = await client.query({
                query: getCartModifierGroupQuery,
                variables: {
                    modifierGroupId
                }
            });

            if (getCartModifierGroup?.status === 200) {
                dispatch({
                    type: SHOP_MODIFIER_ITEM_UPDATE_SUCCESS,
                });

                return {
                    data: getCartModifierGroup?.result?.removeModifierGroupId
                };
            }

        } catch (error) {
            dispatch({
                type: SHOP_MODIFIER_ITEM_UPDATE_ERROR,
            });
        }
    }
};

export default getBookedModifierGroup;