import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditContentPage.css';
import { graphql } from 'react-apollo';
import editContentPage from './editContentPage.graphql';
import Loader from '../../../../components/Common/Loader/Loader';
import EditContentPageForm from '../../../../components/SiteAdmin/ContentPage/EditContentPageForm';
//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';

export class EditContentPage extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }

  render() {
    const { title, editContentPageData: { loading, editContentPage } } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
        {!loading && <div className={s.container}>
            <div className={s.heading}>
              <FormattedMessage {...messages.EditContentDetails} />
            </div>
            <div>
              <EditContentPageForm
                title={title} initialValues={editContentPage}
              />
            </div>
          </div>}
        </div>
      </Loader>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default compose(
  withStyles(s),
  graphql(editContentPage, {
    name: 'editContentPageData',
    options: (props) => ({
      variables: {
        id: props.id,
      },
      fetchPolicy: 'network-only'
    })
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(EditContentPage)