import history from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { updateRider as mutation } from '../../../lib/graphql';

export const editRider = ({ id, firstName, lastName, email, password, phoneDialCode, phoneNumber, userStatus, isBan, phoneCountryCode }) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch(setLoaderStart('EditRider'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          firstName,
          lastName,
          email,
          password,
          phoneDialCode,
          phoneNumber,
          userStatus,
          isBan,
          phoneCountryCode
        }
      });

      dispatch(setLoaderComplete('EditRider'));

      if (data?.updateRider?.status === 200) {
        history.push('/siteadmin/users');
        showToaster({ messageId: 'updateRider', toasterType: 'success' });
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateRider?.errorMessage });
      }

    } catch (error) {
      showToaster({ messageId: 'commonError', toasterType: 'error', requestMessage: error });
    }
  }
};