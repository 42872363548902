import gql from "graphql-tag";

export const addCategory = gql`
    mutation addCategory(
        $categoryName: String,
        $isActive: Boolean,
        $categoryImage: String,
        $description: String
    ) {
        addCategory(
            categoryName: $categoryName,
            isActive: $isActive,
            categoryImage: $categoryImage,
            description: $description
        ) {
            status
            errorMessage
        }
    }`;

export const deleteCategory = gql`
mutation deleteCategory($id: Int) {
    deleteCategory(id: $id) {
      status
      errorMessage
    }
  }
`;

export const updateCategory = gql`
mutation updateCategory(
  $id: Int, 
  $categoryName: String,
  $description: String,
  $isActive: Boolean
) {
  updateCategory(
    id: $id,
    categoryName: $categoryName,
    description: $description,
    isActive: $isActive
  ){
    status
    errorMessage
  }
}
`;

export const updateCategoryImage = gql`
mutation updateCategoryImage($id: Int, 
     $fileName: String
     ) {
    updateCategoryImage(
      id: $id
      fileName: $fileName
    ){
      status
      errorMessage
    }
  }
`;

export const updateCategoryPrivilege = gql`
mutation($id: Int!, $isActive: Boolean, $privilegeType: String!) {
    updateCategoryPrivilege(id: $id, isActive: $isActive, privilegeType: $privilegeType) {
        status
        errorMessage
    }
}
`;

export const updateCategoryStatus = gql`
mutation updateCategoryStatus($id: Int, $isActive: Boolean) {
    updateCategoryStatus(id: $id, isActive: $isActive) {
      status
      errorMessage
    }
}
`;