import history from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { removePayout as mutation } from '../../../lib/graphql';
import { getPayouts } from './getPayouts';
import {
  REMOVE_PAYOUT_START,
  REMOVE_PAYOUT_SUCCESS,
  REMOVE_PAYOUT_ERROR,
} from '../../../constants';

export const removePayout = (id) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: REMOVE_PAYOUT_START,
      });
      dispatch(setLoaderStart('payoutRemove'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id
        }
      });

      if (data?.removePayout?.status == 200) {
        await dispatch({
          type: REMOVE_PAYOUT_SUCCESS
        });
        showToaster({ messageId: 'removePayout', toasterType: 'success' })
        await dispatch(setLoaderComplete('payoutRemove'));
        await dispatch(getPayouts());
        history.push('/store/payout');
      }
      await dispatch(setLoaderComplete('payoutRemove'));

    } catch (error) {
      showToaster({ messageId: 'commonError', toasterType: 'error' })
      dispatch({
        type: REMOVE_PAYOUT_ERROR,
        payload: {
          error
        }
      });
      dispatch(setLoaderComplete('payoutRemove'));
      return false;
    };
    return true;
  };
};
