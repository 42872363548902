import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ViewChatting from './ViewChatting'
import messages from '../../../locale/messages';

function action({ store, params, query, intl }) {
    const title = intl.formatMessage(messages.viewChat);

    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let isSuperAdmin = store.getState().runtime.isSuperAdmin;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    // Admin restriction
    if (!isSuperAdmin) {
        return { redirect: '/siteadmin' };
    }

    let bookingId = Number(params.id), from = params.from;

    return {
        title,
        component: (<AdminLayout><ViewChatting bookingId ={bookingId} from={from} title={title}/></AdminLayout>)
    }
}

export default action;