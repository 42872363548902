import history from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { updateDriver as mutation, getAllDrivers as query } from '../../../lib/graphql';

export const editDriver = ({ id, firstName, lastName, email, password, phoneDialCode, phoneNumber, vehicleName, vehicleNumber, vehicleType, userStatus, isBan, phoneCountryCode }) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch(setLoaderStart('EditDriver'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          firstName,
          lastName,
          email,
          password,
          phoneDialCode,
          phoneNumber,
          vehicleName,
          vehicleNumber,
          vehicleType: Number(vehicleType),
          userStatus,
          isBan,
          phoneCountryCode
        },
        refetchQueries: [{
          query,
          variables: {
            currentPage: 1
          }
        }]
      });

      dispatch(setLoaderComplete('EditDriver'));

      if (data?.updateDriver?.status === 200) {
        history.push('/siteadmin/drivers');
        showToaster({ messageId: 'updateDriver', toasterType: 'success' });
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateDriver?.errorMessage });
      }

    } catch (error) {
      showToaster({ messageId: 'commonError', toasterType: 'error', requestMessage: error })
    }
  }
};