import history from '../../history';
import showToaster from '../../helpers/showToaster';
import { updateStaticPage as mutation, getEditStaticPage as query } from '../../lib/graphql';
import {
    ADMIN_UPDATE_STATIC_START,
    ADMIN_UPDATE_STATIC_SUCCESS,
    ADMIN_UPDATE_STATIC_ERROR
} from '../../constants';

export const updateStaticPage = (values) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({ type: ADMIN_UPDATE_STATIC_START });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    content: values?.content,
                    metaTitle: values?.metaTitle,
                    metaDescription: values?.metaDescription,
                    pageBanner: values?.pageBanner,
                    id: values?.id
                },
                refetchQueries: [{
                    query,
                    variables: {
                        id: values?.id
                    }
                }]
            });

            if (data?.updateStaticPage?.status == 200) {
                showToaster({ messageId: 'updateStaticPage', toasterType: 'success' });
                history.push('/siteadmin/staticpage/manage');
                dispatch({
                    type: ADMIN_UPDATE_STATIC_SUCCESS
                });
            } else {
                showToaster({ messageId: 'updateStaticFail', toasterType: 'error' });
                dispatch({
                    type: ADMIN_UPDATE_STATIC_ERROR
                });
            }

        } catch (error) {
            showToaster({ messageId: 'commonError', toasterType: 'error' });
            dispatch({
                type: ADMIN_UPDATE_STATIC_ERROR
            });
            return false;
        }
        return true;
    };
};