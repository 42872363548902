import showToaster from '../../../helpers/showToaster';
import { closeForgotPasswordModal } from '../../modalActions';
import { sendForgotPasswordLink as mutation } from '../../../lib/graphql';

export const sendForgotPasswordLink = (email) => {
    return async (dispatch, getState, { client }) => {
        try {
            dispatch(closeForgotPasswordModal());

            const { data } = await client.mutate({
                mutation,
                variables: {
                    email
                }
            });

            if (data?.sendForgotPasswordLink) {
                if (data?.sendForgotPasswordLink?.status === 200) {
                    showToaster({ messageId: 'sendForgotPasswordLink', toasterType: 'success' })
                    return true;
                } else {
                    showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.sendForgotPasswordLink?.errorMessage })
                    return false;
                }
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error' })
                return false;
            }

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            return false;
        }
    };
}
