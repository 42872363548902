import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import s from './StoreHome.css';
import {
  Container
} from 'react-bootstrap';
import NewsBox from '../../../components/Shop/StoreHome/NewsBox/NewsBox';
import StorePartners from '../../../components/Shop/StoreHome/StorePartners/StorePartners';
import StoreSlider from '../../../components/Shop/StoreHome/StoreSlider/StoreSlider';
import StoreBanner from '../../../components/Shop/StoreHome/StoreBanner/StoreBanner';
import getCategoryCategories from './getCategoryCategories.graphql';
import getAllPartnerHomepage from './getAllPartnerHomepage.graphql';
import Loader from '../../../components/Common/Loader/Loader';

class StoreHome extends React.Component {

  render() {

    const { getCategory: { getActiveCategories }, getAllPartnerHomepage: { loading, getAllPartnerHomepage } } = this.props
    
    let homepage = {};
    if (!loading) {

      getAllPartnerHomepage && getAllPartnerHomepage.homePageData.map((item) => {
        homepage[item.name] = item.value
      });

    }
    
    return (
      <div className={s.root}>
        { !loading &&
          <div>
            <StoreBanner getCategory={getActiveCategories} homepage={homepage} />
            <Container className={s.container}>
              <StorePartners homepage={homepage} />
              <NewsBox homepage={homepage} />
              <StoreSlider homepage={homepage} />
            </Container>
          </div>
        }

      </div>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getCategoryCategories, {
    name: 'getCategory',
    options: {
      ssr: true
    }
  }),
  graphql(getAllPartnerHomepage, {
    name: 'getAllPartnerHomepage',
    options: {
      ssr: true
    }
  })
)(StoreHome);
