exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._36cDa {\n    padding: 7px 10px !important;\n}\n\n._1HNG2 {\n    width: 100%;\n    max-width: 20px;\n    margin-right: 3px;\n}\n\n._3VBLT {\n    color: #018E4D !important;\n    text-decoration: none !important;\n    display: inline-block;\n    vertical-align: middle;\n}\n\n._3lpsf {\n    opacity: inherit;\n    cursor: not-allowed;\n}\n\n._19067 {\n    background-color: #D8D8D8 !important;\n    border: 1px solid #D8D8D8 !important;\n    opacity: inherit;\n}\n\n._19067:hover,\n._19067:focus,\n._19067:active,\n._19067:active:hover,\n._19067:active:focus {\n    background-color: #D8D8D8 !important;\n    border: 1px solid #D8D8D8 !important;\n}\n\n._3RuBc {\n    text-align: center !important;\n}\n\n.p-54y {\n    font-size: 16px;\n    font-weight: 500;\n    text-align: left;\n    color: #212121;\n}\n\n._2CzWA {\n    font-size: 22px;\n    font-weight: 600;\n    text-align: left;\n    color: #212121;\n    margin-top: 12px;\n}", ""]);

// exports
exports.locals = {
	"btnPadding": "_36cDa",
	"iconWidth": "_1HNG2",
	"linkColor": "_3VBLT",
	"inactive": "_3lpsf",
	"btnInactive": "_19067",
	"alignCenter": "_3RuBc",
	"completeText": "p-54y",
	"confirmCode": "_2CzWA"
};