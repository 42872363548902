import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { uploadShopDocumentMutation as mutation } from '../../../lib/graphql';
import {
    SHOP_DOCUMENT_UPDATE_ERROR,
    SHOP_DOCUMENT_UPDATE_START,
    SHOP_DOCUMENT_UPDATE_SUCCESS
} from '../../../constants';

export default function uploadShopDocument(documentList) {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_DOCUMENT_UPDATE_START
            });

            dispatch(setLoaderStart('StoreDocumentUpdate'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    documentList
                }
            });

            if (data?.uploadShopDocument?.status == 200) {
                showToaster({ messageId: 'uploadShopDoc', toasterType: 'success' });
                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('StoreDocumentUpdate'));

            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.uploadShopDocument?.errorMessage })
                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('StoreDocumentUpdate'));
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
            await dispatch({
                type: SHOP_DOCUMENT_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('StoreDocumentUpdate'));
        }
    }
};