exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n  --btn-primary-bg: #141446;\n  --btn-boxShadow-bg: #141446;\n  --btn-bordercolor-bg: #141446;\n  --btn-primary-hover-bg: #141446;\n  --btn-text-color: #fff;\n  --btn-primary-color: #fff;\n  --common-color: #141446;\n  --btn-secondary-bg: #141446;\n  --title-color: #39483a;\n  --content-color: #39483a;\n  --border-color: #ced4da;\n  --chat-background-color: #f9f7f5;\n  --common-tooltip-color: #151515;\n  --common-darkModeTooltip-color: #ffffff;\n  --background-primary: #fff;\n  --viewChat-bg: #F5F5F5;\n  --viewChat-sender-bg: #F5F5F5;\n  --viewChat-receiver-bg: #E7F2FE;\n}\n\n._35KG- {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n._3yVMp {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 1170px;\n  max-width: var(--max-content-width);\n}\n", ""]);

// exports
exports.locals = {
	"root": "_35KG-",
	"container": "_3yVMp"
};