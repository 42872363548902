import React, { Component } from 'react';
import s from './AddSubMenuForm.css';
import rs from '../../../../storeCommon.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import {
	Button,
	Form,
	Col,
	Card,
	Row,
	InputGroup,
	Container
} from 'react-bootstrap';
import validate from './validate';
import submit from './submit';
import Loader from '../../../../Common/Loader/Loader';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../../../locale/messages';
import { closeSubMenuModal } from '../../../../../actions/siteadmin/modalActions';

class AddSubMenuForm extends React.Component {


	static defaultProps = {
		loading: false
	};

	constructor(props) {
		super(props);
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxLength }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} maxLength={maxLength} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className='store'>
				<Form.Control as="select" {...input} placeholder={placeholder} className={fieldClass}>
					{children}
				</Form.Control>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	render() {
		const { handleSubmit, submitting, loading, closeSubMenuModal, id } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<div className={cx(s.space5, s.responsiveNoPadding)}>
				<Container fluid>
							<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
								<Row>
									<Col lg={12} md={12} sm={12} xs={12} className={rs.spaceTop2}>
										<Form.Group className={s.formGroup}>
											<div>
												<Row>
													<Col lg={12} md={12} sm={12} xs={12}>
														<Field
															name="name"
															type="text"
															placeholder={formatMessage(messages.subMenuName)}
															component={this.renderField}
															labelClass={s.labelText}
															fieldClass={rs.formControlInputStore}
															maxLength={100}
														/>
													</Col>
													<Col lg={12} md={12} sm={12} xs={12}>
														<Field name="isEnable" component={this.renderSelectField} fieldClass={rs.formControlInputStore}>
															<option value={true}>{formatMessage(messages.active)}</option>
															<option value={false}>{formatMessage(messages.inactive)}</option>
														</Field>
													</Col>
												</Row>
											</div>
										</Form.Group>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12} className={rs.alignRightText}>
										<Form.Group className={cx(s.rightSide, rs.space2)}>
											<div className={rs.secondryBtnSection}>
												<Loader
													type={"button"}
													label={id ? formatMessage(messages.update) : formatMessage(messages.addLabel)}
													show={loading}
													buttonType={'submit'}
													className={cx(rs.button, rs.btnPrimary, rs.btnFullWidth)}
													disabled={submitting || loading}
													isSuffix={true}
												/>
											</div>
											<div className={rs.secondryBtnSection}>
												<a
													href={'javascript:void(0)'}
													onClick={closeSubMenuModal}
													className={cx(rs.button, rs.btnPrimaryBorder, rs.displayBlock)}
												>
													<FormattedMessage {...messages.cancel} />
												</a>
											</div>
										</Form.Group>
									</Col>
								</Row>
							</Form>
				</Container>
			</div>
		)
	}

}

AddSubMenuForm = reduxForm({
	form: 'AddSubMenuForm',
	onSubmit: submit,
	validate
})(AddSubMenuForm);

const selector = formValueSelector('AddSubMenuForm');


const mapState = (state) => ({
	loading: state.loader.CommonSettings,
	id: selector(state, 'id')
});

const mapDispatch = {
	closeSubMenuModal
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(AddSubMenuForm)));