import messages from '../../../../locale/messages';
import { inputTextLimit } from '../../../../helpers/fieldRestriction';

const validate = values => {
    const errors = {};

    if (!values.sliderTitle5) {
        errors.sliderTitle5 = messages.required;
    } else if (values.sliderTitle5.trim() == "") {
        errors.sliderTitle5 = messages.required;
    } else if (values.sliderTitle5.length < 2) {
        errors.sliderTitle5 = messages.required
    } else if (values.sliderTitle5.length > inputTextLimit) {
        errors.sliderTitle5 = messages.textAreaError
    }

    if (!values.buttonLink) {
        errors.buttonLink = messages.required;
    } else if (values.buttonLink.trim() == "") {
        errors.buttonLink = messages.required;
    } else if (values.buttonLink) {
        if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.buttonLink)) {
            errors.buttonLink = messages.validUrl;
        }
    }

    if (!values.buttonName) {
        errors.buttonName = messages.required;
    } else if (values.buttonName.trim() == "") {
        errors.buttonName = messages.required;
    } else if (values.buttonName.length < 2) {
        errors.buttonName = messages.required
    } else if (values.buttonName.length > inputTextLimit) {
        errors.buttonName = messages.textAreaError
    }


    return errors;
};

export default validate;