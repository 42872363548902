import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col, Container } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import { Button, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
// style
import s from './MenuList.css';
import rs from '../../../storeCommon.css';
// helpers
import messages from '../../../../locale/messages';
import debounce from '../../../../helpers/debounce';
// components
import CustomPagination from '../../../CustomPagination/CustomPagination';
import Link from '../../../Link/Link';
import ConfirmationPopup from '../../../Common/ConfirmationPopup';
// images
import EditIcon from '../../../../../public/StoreIcon/edit.png';
import TrashIcon from '../../../../../public/StoreIcon/dlty.png';
import ManageItemIcon from '../../../../../public/StoreIcon/manageItems.svg';
// redux action
import updateMenuStatus from '../../../../actions/shop/menu/updateMenuStatus';
import deleteMenu from '../../../../actions/shop/menu/deleteMenu';
import { openModal, closeModal } from '../../../../actions/siteadmin/modalActions';



class MenuList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0
    }
    this.paginationData = this.paginationData.bind(this);
    this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
    this.handleStatus = this.handleStatus.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleKeywordSearch(searchList) { // Keyword search
    const { menuDetails: { refetch } } = this.props;
    this.setState({ searchList, currentPage: 1 });
    refetch({ currentPage: 1, searchList });
  }

  async handleDelete(id, currentPage) {
    const { deleteMenu, menuDetails: { refetch }, closeModal } = this.props;
    let variables = { currentPage };
    await deleteMenu(id, currentPage);
    await closeModal('menuDeleteModal')
    refetch(variables);
  };

  paginationData(currentPage) {

    const { menuDetails: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);

  };

  async handleStatus(id, status, currentPage) {
    const { updateMenuStatus, menuDetails: { refetch } } = this.props;
    let variables = { currentPage };
    await updateMenuStatus(id, status, currentPage);
    refetch(variables);
  };


  render() {
    const { menuDetails, openModal, closeModal, menuDeleteModal, modalData, isEnableAddon } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage } = this.state;

    return (
      <div className={'mainContainer'}>
        <div>
          <ConfirmationPopup
            modalStatus={menuDeleteModal}
            title={formatMessage(messages.deleteMenuTitle)}
            body={formatMessage(messages.deleteConfirmation)}
            closeModal={() => closeModal('menuDeleteModal')}
            popupButtonLeftName={formatMessage(messages.cancelButton)}
            popupButtonRightName={formatMessage(messages.deleteAction)}
            popupButtonLeftFunction={() => closeModal('menuDeleteModal')}
            popupButtonRightFunction={() => this.handleDelete(modalData.id, modalData.currentPage)}
          />
        </div>
        <Container fluid>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <h1 className={cx(rs.storeTitleText, 'textRightRTL')}>{formatMessage(messages.manageMenu)}</h1>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className={cx(rs.searchInputStore, s.verticalTopPad, s.floatLeft, 'floatRightRTL')}>
                <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleKeywordSearch(e.target && e.target.value)} className={cx(rs.formControlInputStore, rs.searchInputWidth)} />
              </div>
              <div className={cx(s.displayInlineBlock, rs.searchTopBtn, s.floatRight, 'floatLeftRTL')}>
                {isEnableAddon == '1' && <Link to={"/store/menu/modifier-groups"} className={cx(rs.button, rs.btnPrimaryBorder, s.displayInlineBlock, s.modifierGroupBtn)}>{formatMessage(messages.modifierGroup)}</Link>}
                <Link to={"/store/menu/add"} className={cx(rs.button, rs.btnPrimary, s.displayInlineBlock, s.mbBtn, 'backBtnRTL')}>{formatMessage(messages.addMenu)}</Link>
              </div>
            </Col>
          </Row>
          {
            menuDetails && menuDetails.getAllMenu && menuDetails.getAllMenu.results && menuDetails.getAllMenu.results.length > 0 && menuDetails.getAllMenu.results.map((item, index) => {
              let getOpenDay = item.menuAvailability.filter(i => (i.isOpen == true));
              return (
                <Row key={index}>
                  <Col xs={12} lg={12} md={12} sm={12}>
                    <div className={rs.menuItemGrid}>
                      <div className={cx(rs.menuFirstSection, 'textRightRTL')}>
                        <div className={cx(rs.alignLeft, rs.tabSection, 'textRightRTL')}>
                          <p className={cx(rs.idTitle, rs.textOverFlow)}>
                            {item.menuName}
                          </p>
                        </div>
                        <div className={'storeActiveSelect'}>
                          <select value={item.isEnable} onChange={(e) => { this.handleStatus(item.id, e.target.value, currentPage) }} className={cx(rs.activeSelect, rs.storeSelectInput)}>
                            <option value={true}>{formatMessage(messages.active)}</option>
                            <option value={false}>{formatMessage(messages.inactive)}</option>
                          </select>
                        </div>
                        <div>
                          <Link to={'/store/menu/edit/' + item.id} className={cx(rs.linkColor, s.displayInlineBlock)}>
                            <img src={EditIcon} className={cx(s.iconsWidth, 'iconsWidthRTL')} />
                            <span className={cx(rs.vtrMiddle, s.editSection)}>{formatMessage(messages.editItem)}</span>
                          </Link>
                        </div>
                        <div>
                          <Button className={cx(rs.deleteBtn, s.editBtn, s.IconLeft, rs.textOverFlow)} onClick={() => openModal('menuDeleteModal', { id: item.id, modalName: 'menuDeleteModal', currentPage })}>
                            <img src={TrashIcon} className={cx(s.iconsWidth, 'iconsWidthRTL')} />
                            <span className={rs.vtrMiddle}>{formatMessage(messages.deleteAction)}</span>
                          </Button>

                        </div>
                        <div className={cx(rs.alignRightText, rs.manageItemBtn)}>
                          <Link to={'/store/menu/' + item.id + '/item'} className={cx(rs.button, rs.btnPrimary, rs.manageBtnItem, rs.manageBtn)}>
                            <img src={ManageItemIcon} className={cx(s.iconsWidth, 'iconsWidthRTL')} />
                            <span className={rs.vtrMiddle}>{formatMessage(messages.manageItem)}</span>
                          </Link>
                        </div>
                      </div>
                      <div className={cx(rs.spaceTop5, rs.space2, rs.alignCenter, rs.activeDays)}>
                        <span className={s.activeDaysText}>
                          {formatMessage(messages.activeDays)}
                        </span>: {' '}
                        <span>
                          {
                            getOpenDay && getOpenDay.length > 0 ? getOpenDay.map(function (elem) {
                              return formatMessage(messages[elem.operationDay]);
                            }).join(", ")
                              + '.' : formatMessage(messages.noActiveDays) + '.'
                          }
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              )
            })
          }
          {
            menuDetails && menuDetails.getAllMenu && menuDetails.getAllMenu.results && menuDetails.getAllMenu.results.length == 0 &&
            <span className={rs.notFoundText}><FormattedMessage {...messages.noMenu} />.</span>
          }
          {
            menuDetails && menuDetails.getAllMenu && menuDetails.getAllMenu.results && menuDetails.getAllMenu.results.length > 0
            && <div className={cx(s.space5, s.spaceTop5, 'store')}>
              <CustomPagination
                total={menuDetails.getAllMenu.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={menuDetails.getAllMenu.count > 1 ? formatMessage(messages.menus) : formatMessage(messages.menu)}
              />
            </div>
          }
        </Container>
      </div>
    );
  }
}

const mapDispatch = {
  deleteMenu,
  updateMenuStatus,
  openModal,
  closeModal
};

const mapState = (state) => ({
  menuDeleteModal: state.modalStatus.menuDeleteModal,
  modalData: state.modalStatus.data && state.modalStatus.data[0],
  isEnableAddon: state.siteSettings.data.modifiers,
});

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(MenuList)));