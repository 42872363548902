import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { flowRight as compose } from 'lodash';
import cx from 'classnames';

import DefaultIcon from '../../../../../public/Icons/defaultIcon.svg';
import defaultMessageIcon from '../../../../../public/Icons/defaultMessageIcon.svg';
import defaultVehicleIcon from '../../../../../public/Icons/defaultVehicleIcon.svg';

import { api, profilePhotouploadDir } from '../../../../config';

import s from './UserDetails.css';
import bt from '../../../commonStyle.css';

class UserDetails extends Component {
  static propTypes = {};

  render() {
    const { userDetails, deliveryPartnerDetails } = this.props;
    const userImage = userDetails?.profile?.picture
      ? `${api.apiEndpoint + profilePhotouploadDir}small_${userDetails?.profile?.picture
      }`
      : DefaultIcon;
    const driverImage = deliveryPartnerDetails?.profile?.picture
      ? `${api.apiEndpoint + profilePhotouploadDir}small_${deliveryPartnerDetails?.profile?.picture
      }`
      : DefaultIcon;
    return (
      <div className={cx(s.containerWidth, s.hiddenXs, 'bgBlack')}>
        <div className={cx(s.displayGrid)}>
          <div className={cx(s.textAlignRight, "userImgBoxTextLeftRtl")}>
            {userDetails?.profile?.firstName}
          </div>
          <div className={cx(s.dFlex, s.viewChatBox)}>
            <img src={userImage} className={s.icon} />
            <div className={s.viewChatMessageIconBox}>
              <img
                src={defaultMessageIcon}
                responsive
                className={cx(s.viewChatMessageIcon)}
              />
            </div>
            <div className={s.positionRelative}>
              <img src={driverImage} className={s.icon} />
              <span className={cx(s.batchIcon, 'driverBatchIconRTL')}>
                <img
                  src={defaultVehicleIcon}
                />
              </span>

            </div>
          </div>
          <div className={cx(s.textAlignLeft, "userImgBoxTextRightRtl")}>
            {deliveryPartnerDetails?.profile?.firstName}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withStyles(s, bt))(UserDetails);
