import {
    STRIPE_PUBLISHABLE_KEY_START,
    STRIPE_PUBLISHABLE_KEY_SUCCESS,
    STRIPE_PUBLISHABLE_KEY_ERROR
} from '../constants';

export default function stripeSettings(state = {}, action) {
    switch (action.type) {

        case STRIPE_PUBLISHABLE_KEY_START:
            return {
                ...state,
            };

        case STRIPE_PUBLISHABLE_KEY_SUCCESS:
            return {
                ...state,
                ...action?.stripeSettings,
            };

        case STRIPE_PUBLISHABLE_KEY_ERROR:
            return {
                ...state,
            };

        default:
            return {
                ...state,
            };
    }
}
