exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n  --btn-primary-bg: #141446;\n  --btn-boxShadow-bg: #141446;\n  --btn-bordercolor-bg: #141446;\n  --btn-primary-hover-bg: #141446;\n  --btn-text-color: #fff;\n  --btn-primary-color: #fff;\n  --common-color: #141446;\n  --btn-secondary-bg: #141446;\n  --title-color: #39483a;\n  --content-color: #39483a;\n  --border-color: #ced4da;\n  --chat-background-color: #f9f7f5;\n  --common-tooltip-color: #151515;\n  --common-darkModeTooltip-color: #ffffff;\n  --background-primary: #fff;\n  --viewChat-bg: #F5F5F5;\n  --viewChat-sender-bg: #F5F5F5;\n  --viewChat-receiver-bg: #E7F2FE;\n}\n._1LQ5- {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n._1s7Xi {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 1170px;\n  max-width: var(--max-content-width);\n}\n._1sfRe {\n  font-size: 44px;\n  font-weight: 600;\n}\n.v9395 {\n  font-size: 30px;\n  margin-bottom: 24px;\n}\n._3Gsto {\n  margin-right: 10px;\n}\n@media screen and (max-width: 991px) {\n  .I88BA {\n    width: 100%;\n  }\n  ._3Gsto {\n    margin-right: 0px;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "_1LQ5-",
	"container": "_1s7Xi",
	"textJumbo": "_1sfRe",
	"subText": "v9395",
	"firstBtn": "_3Gsto",
	"bntWidth": "I88BA"
};