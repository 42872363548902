import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

import CurrencyConverter from '../../CurrencyConverter';
import Link from '../../Link';

import messages from '../../../locale/messages';

import OrderImage from '../../../../public/StoreIcon/total-orders.svg';
import OrderEarning from '../../../../public/StoreIcon/earnings.svg';

import s from './Dashboard.css'
import rs from '../../storeCommon.css';

class Dashboard extends Component {
  static defaultProps = {
    orders: {
      total: 0,
      today: 0,
      week: 0,
      month: 0
    },
    earnings: {
      total: 0,
      today: 0,
      week: 0,
      month: 0,
      currency: 'USD'
    },
    historyLoading: true,
    history: {
      ordersHistory: {
        count: 0,
        start: null,
        end: null,
        ordersLabels: [],
        ordersData: []
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      requestDays: 30
    };
  }

  handleOrderHistory = (requestDays) => {
    const { historyRefetch } = this.props;
    this.setState({ requestDays });
    historyRefetch({ requestDays });
  }

  renderAmount = (amount, from) => {
    const { toCurrency } = this.props;
    if (amount >= 0 && from && toCurrency) {
      return <CurrencyConverter from={from} amount={amount} to={toCurrency} />
    }

    return <div />;
  }

  renderOrdersCard = (label, orders) => {
    return (
      <Col xl={3} md={12} lg={6} sm={12} xs={12} className={rs.space4}>
        <Link className={s.mainContainerLink} to={'/store/order-management/upcoming'}>
          <div className={s.mainContainer}>
            <span className={s.orderImg}><img src={OrderImage} /></span>
            <span className={cx(s.subtext, rs.space2, rs.spaceTop3)}>{label}</span>
            <p className={cx(s.numberText, rs.noMargin)}>{orders}</p>
          </div>
        </Link>
      </Col>
    );
  }

  renderEarningsCard = (label, amount, currency) => {
    return (
      <Col xl={3} md={12} lg={6} sm={12} xs={12} className={rs.space4}>
        <Link className={s.mainContainerLink} to={'/store/transaction/completed'}>
          <div className={cx(s.mainContainer, s.earnSection)}>
            <span className={s.earnImg}><img src={OrderEarning} /></span>
            <p className={cx(s.numberText, rs.spaceTop3)}>{this.renderAmount(amount, currency)}</p>
            <span className={cx(s.subtext, rs.space1, rs.spaceTop1)}>{label}</span>
          </div>
        </Link>
      </Col>
    );
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { orders, earnings, historyLoading, history, history: { ordersHistory } } = this.props;
    const { requestDays } = this.state;

    const options = {
      responsive: true,
      labels: !historyLoading && history && ordersHistory && ordersHistory?.ordersLabels || [],
      datasets: [
        {
          label: formatMessage(messages.ordersLabel),
          data: !historyLoading && history && ordersHistory && ordersHistory?.ordersData || [],
          backgroundColor: "#018E4D",
          borderColor: "#018E4D",
          fill: false,
          tension: 0.3
        }
      ],
      maintainAspectRatio: false,
      scales: {
        yAxes: {
          scaleLabel: {
            display: true,
            labelString: formatMessage(messages.ordersLabel)
          }
        },
        xAxes: {
          scaleLabel: {
            display: true,
            labelString: formatMessage(messages.tripDate)
          }
        }

      },
      legend: {
        onClick: (e) => e.stopPropagation()
      }
    };

    return (
      <div className={'mainContainer'}>
        <Container fluid>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
              <div>
                <h1 className={cx(s.titleBottom, rs.storeTitleText, 'textRightRTL')}>{formatMessage(messages.dashboard)}</h1>
              </div>
            </Col>
            {/* Orders */}
            {this.renderOrdersCard(formatMessage(messages.totalOrder), orders?.total)}
            {this.renderOrdersCard(formatMessage(messages.todayOrder), orders?.today)}
            {this.renderOrdersCard(formatMessage(messages.thisWeekOrder), orders?.week)}
            {this.renderOrdersCard(formatMessage(messages.thisMonthOrder), orders?.month)}
            {/* Earnings */}
            {this.renderEarningsCard(formatMessage(messages.totalEranings), earnings?.total, earnings?.currency)}
            {this.renderEarningsCard(formatMessage(messages.todayEarnings), earnings?.today, earnings?.currency)}
            {this.renderEarningsCard(formatMessage(messages.thisWeekEarnings), earnings?.week, earnings?.currency)}
            {this.renderEarningsCard(formatMessage(messages.thisMonthEarnings), earnings?.month, earnings?.currency)}
            {
              !historyLoading && history && ordersHistory && <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.spaceTop1)}>
                <Row>
                  <Col lg={6} md={12} sm={12} xs={12} className={cx(rs.spaceTop2, rs.space2, 'textRightRTL')}>
                    <h4><FormattedMessage {...messages.orderHistory} /></h4>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12} className={cx(rs.spaceTop2, rs.space2, 'text-right', s.mobileTextLeft, 'textLeftDasboardRTL')}>
                    <select
                      className={cx(rs.activeSelect, rs.storeSelectInput, rs.orderSlectWidth, s.selectWidth)}
                      value={requestDays} disabled={historyLoading}
                      onChange={(e) => this.handleOrderHistory(e.target && e.target.value)}
                    >
                      <option value={7}>{formatMessage(messages.last7Days)}</option>
                      <option value={15}>{formatMessage(messages.last15Days)}</option>
                      <option value={30}>{formatMessage(messages.last30Days)}</option>
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12} className={'chart-container'}>
                    <Line
                      data={options}
                      width={100}
                      height={50}
                      options={options}
                    />
                  </Col>
                </Row>
              </Col>
            }
          </Row>
        </Container>
      </div >
    );
  }
};

const mapStateToProps = (state) => ({
  userId: state?.user?.id,
  toCurrency: state?.siteSettings?.data?.currency
});

const mapDispatchToProps = {
};

export default injectIntl(withStyles(s, rs)(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));