import gql from "graphql-tag";

export const uploadShopDocumentMutation = gql`mutation($documentList: String) {
        uploadShopDocument (
            documentList:$documentList
        ) {
            status
            errorMessage
        }
    }`;

export const updateShopProfile = gql`
mutation (
  $shopName: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $phoneDialCode: String,
  $phoneNumber: String,
  $phoneCountryCode: String,
  $address: String,
  $lat: Float,
  $lng: Float,
  $city: String,
  $state:String,
  $zipcode: String,
  $country: String,
  $categoryType: [Int],
  $description: String,
  $priceRangeId: Int,
  $picture: String,
  $preferredCurrency: String,
  $shopEstimatedTime: Int,
  $isTakeAway: Boolean,
  $isDoorDelivery: Boolean
) {
  updateShopProfile (
      shopName: $shopName,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      phoneDialCode: $phoneDialCode,
      phoneNumber: $phoneNumber,
      phoneCountryCode: $phoneCountryCode,
      address: $address,
      lat: $lat,
      lng: $lng,
      city: $city,
      state: $state,
      zipcode: $zipcode,
      country: $country,
      categoryType: $categoryType,
      description: $description,
      priceRangeId: $priceRangeId,
      picture: $picture,
      preferredCurrency: $preferredCurrency,
      shopEstimatedTime: $shopEstimatedTime,
      isTakeAway: $isTakeAway,
      isDoorDelivery: $isDoorDelivery
  ) {
      status
      errorMessage                     
  }
}`;

export const checkShopUser = gql`query(
    $email: String!
){
    checkShopUser(email: $email){
        status
        errorMessage
    }
}`;

export const shopUserLogin = gql`
    query (
        $email: String!,
        $password: String!,
        $deviceId: String
    ) {
        shopUserLogin (
            email: $email,
            password: $password,
            deviceId: $deviceId
        ) {
            status
            errorMessage
            result {
                id
                email
            }
        }
    }`;

export const shopUserLogout = gql`
query {
    shopUserLogout {
        status
    }
  }
`;

export const updatePreparationTimeMutation = gql`
mutation (
  $shopEstimatedTime: Int,
  $preparationTime: String
) {
  updatePreparationTime (
    shopEstimatedTime: $shopEstimatedTime,
    preparationTime: $preparationTime
  ) {
      status
      errorMessage        
  }
}`;

export const shopUserActivityChange = gql`
mutation ($isActive: Boolean) {
    shopUserActivityChange (isActive: $isActive) {
        status
        errorMessage
    }
  }
`;

export const createShopUserMutation = gql`
mutation (
  $shopName: String!
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $password: String!,
  $phoneDialCode: String!,
  $phoneNumber: String!,
  $phoneCountryCode: String!,
  $address: String!,
    $lat: Float!,
    $lng: Float!,
    $city: String,
    $state:String,
    $zipcode: String,
  $country: String,
  $categoryType: Int!,
  $deviceId: String
) {
  createShopUser (
      shopName: $shopName,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      password: $password,
      phoneDialCode: $phoneDialCode,
      phoneNumber: $phoneNumber,
      phoneCountryCode: $phoneCountryCode,
      address: $address,
      lat: $lat,
      lng: $lng,
      city: $city,
      state: $state,
      zipcode: $zipcode,
      country: $country,
      categoryType: $categoryType,
      deviceId: $deviceId
  ) {
      status
      errorMessage
      result {
          id
          email
      }        
  }
}`;

export const deleteShop = gql`
mutation deleteShop($id: String!) {
    deleteShop(id: $id) {
      status
      errorMessage
    }
  }
`;

export const updateShopMutation = gql`
    mutation(
        $id: ID,
        $shopName: String
        $firstName: String,
        $lastName: String,
        $email: String,
        $phoneDialCode: String,
        $phoneNumber: String,
        $phoneCountryCode: String,
        $address: String,
        $lat: Float,
        $lng: Float,
        $city: String,
        $state: String,
        $zipcode: String,
        $country: String,
        $categoryType: [Int],
        $description: String,
        $priceRangeId: Int,
        $picture: String,
        $preferredCurrency: String,
        $shopEstimatedTime: Int,
        $password: String,
        $isBan: String,
        $userStatus: String,
        $isTakeAway: Boolean,
        $isDoorDelivery: Boolean
    ) {
        updateShop (
            id: $id,
            shopName: $shopName,
            firstName: $firstName,
            lastName: $lastName,
            email: $email,
            phoneDialCode: $phoneDialCode,
            phoneNumber: $phoneNumber,
            phoneCountryCode: $phoneCountryCode,
            address: $address,
            lat: $lat,
            lng: $lng,
            city: $city,
            state: $state,
            zipcode: $zipcode,
            country: $country,
            categoryType: $categoryType,
            description: $description,
            priceRangeId: $priceRangeId,
            picture: $picture,
            preferredCurrency: $preferredCurrency,
            shopEstimatedTime: $shopEstimatedTime,
            password: $password,
            isBan: $isBan,
            userStatus: $userStatus,
            isTakeAway: $isTakeAway,
            isDoorDelivery: $isDoorDelivery
        ) {
            status
            errorMessage
        }
            }`;

export const updateShopStatus = gql`
mutation updateShopStatus($id: ID, $updateField: String, $status: String) {
    updateShopStatus(id: $id, updateField:$updateField, status:$status) {
      status
      errorMessage
    }
  }
`;

export const uploadDocument = gql`
mutation (
  $documentList: String
) {
    uploadDocument (
        documentList:$documentList
    ) {
        status
        errorMessage                     
    }
}`;