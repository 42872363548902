import showToaster from '../../../helpers/showToaster';
import { closeModal } from '../../siteadmin/modalActions';
import { updateDeliveryStatusMutation, getOrders } from '../../../lib/graphql';

const updateDeliveryStatus = (id, isOrderDetailPage, status) => {
    return async (dispatch, getState, { client }) => {
        try {
            let refetchQueries = [];

            if (isOrderDetailPage) {
                refetchQueries = [{
                    query: getOrders,
                    variables: {
                        id
                    }
                }];
            }

            const { data } = await client.mutate({
                mutation: updateDeliveryStatusMutation,
                variables: {
                    id,
                    status
                },
                refetchQueries
            });

            if (data?.updateDeliveryStatus?.status === 200) {
                showToaster({ messageId: 'commonError', toasterType: 'success', requestContent: data?.updateDeliveryStatus?.successMessage });
                dispatch(closeModal('completeConfirmModal'));
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateDeliveryStatus?.errorMessage });
            }
            return true;

        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
        }
    }
};

export default updateDeliveryStatus;