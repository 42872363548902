import showToaster from '../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { updateHomePageFooter as mutation } from '../../lib/graphql';
import {
    HOMEPAGE_SETTINGS_UPLOAD_START,
    HOMEPAGE_SETTINGS_UPLOAD_ERROR,
    HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index';

const updateHomepageSettingsFooter = (values) => {
    return async (dispatch, getState, { client }) => {

        try {

            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_START
            })

            dispatch(setLoaderStart('FooterSettingsForm'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    footerLogo1: values?.footerLogo1,
                    footerLogo2: values?.footerLogo2,
                    footerLogo3: values?.footerLogo3,
                    footerLogo4: values?.footerLogo4,
                    footerTitle1: values?.footerTitle1,
                    footerContent1: values?.footerContent1,
                    footerLink1: values?.footerLink1,
                    footerLink2: values?.footerLink2,
                    footerLink3: values?.footerLink3,
                    footerLink4: values?.footerLink4,
                    footerLinkName1: values?.footerLinkName1,
                    footerLinkName2: values?.footerLinkName2,
                    footerLinkName3: values?.footerLinkName3,
                    footerLinkName4: values?.footerLinkName4,
                    footerLinkTitle: values?.footerLinkTitle,
                    footerBottom: values?.footerBottom,
                }
            });

            dispatch(setLoaderComplete('FooterSettingsForm'));

            if (data?.updateHomePageFooter?.status == 200) {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
                });
                showToaster({ messageId: 'updateHomePage', toasterType: 'success' });
            } else {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
                });
                showToaster({ messageId: 'commonError', toasterType: 'error' });
            }

        } catch (err) {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
            });
            showToaster({ messageId: 'commonError', toasterType: 'error' });
        }
    }
};

export default updateHomepageSettingsFooter;