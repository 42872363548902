import gql from "graphql-tag";

export const userAccountQuery = gql`
query userAccount{
    userAccount {
      id,
      email,
      phoneNumber,
      phoneDialCode,
      phoneCountryCode,
      userStatus,
      isActive,
      isBan,
      userType,
      createdAt,
      activeStatus,
      overallRating,
      deletedAt,
      profile {
        userId,
        firstName,
        lastName,
        preferredLanguage,
        preferredCurrency,
        country,
        city,
        state,
        zipcode,
        profileId,
        lat,
        lng,
        picture,
        shopName,
        description,
        priceRangeId,
        address
      }
      shopCategory {
        categoryId
        categoryName
      }
    }
  }
`;